import { GroupBase, MultiValue, SingleValue } from "react-select";

export interface continentCountries {
	label: string;
	options: {
		label: string;
		value: string;
		continent: string;
	}[];
};

export interface menuItems {
	[key: string]: {
		name: string;
		icon: string;
		id: string;
		hasSubMenu: boolean;
		subMenuItems?: {
			name: string;
			icon?: string;
			id: string;
			hasSubMenu?: boolean;
			subMenuItems?: {
				name: string;
				id: string;
			}[];
		}[];
	};
}[];

export interface newMenuItems {
	name: string;
	icon: string;
	id: string;
	hasSubMenu: boolean;
	subMenuItems: {
		name: string;
		id: string
	}[]
}[]

export interface ProductManagementDataLinks {
	languages?: { label: string; value: string }[];
	isSuperAdmin: boolean;
	loggedUserId: number;
	userRolePermissions: {
		id: number;
		permission: string;
		resource: string;
	}[];
	userRoles: {};
	userLocale: string;
	menuItems: {
		name: string;
		icon: string;
		id: string;
		hasSubMenu: boolean;
		subMenuItems: {
			name: string;
			icon: string;
			id: string;
			hasSubMenu: boolean;
			subMenuItems: {
				name: string;
				id: string;
			}[];
		}[];
	}[];
	continentCountries: continentCountries;
	countriesList: { value: string; label: string; }[];
	adminManagement: {
		loadUsersDataLink: string;
		deleteUserLink: string;
		addUpdateUserLink: string;
		addUpdateRoleLink: string;
		getPermissionsByRole: string;
		assignPermissionsToRole: string;
		deleteRoleLink: string;
		uploadProfilePhoto: string;
		allRoles: {
			id: number;
			name: string;
		}[];
		rolePermissionTree: {}[];
		permissionTree: {
			label: string;
			permission: {
				id: number;
				permission: string;
			}[];
		}[];
	};
	poleManagement: {
		loadPolesDataLink: string;
		deletePoleLink: string;
		addUpdatePoleLink: string;
		getClubListLink: string;
		poleSelectBoxLink: string;
		assignPoleLink: string;
		unAssignPoleLink: string;
		getTimezoneLink: string;
		syncPoleLink: string;
	};
	subscriptionManagement: {
		loadSubscriptionsDataLink: string;
		getProClubNames: string;
		getSubscriptionFilterData: string;
		addUpdateSubscription: string;
		assignSubscriptionToClub: string;
		deleteSubscription: string;
		loadGameOwnerShips: string;
	};
	nonPartnerClubManagement: {
		addUpdateNonPartnerClubLink: string;
		getNonPartnerCountries: string;
		deleteNonPartnerClubLink: string;
		loadNonPartnerClubLink: string;
		uploadClubLogo: string;
		getGeographicalAreaListLink: string;
	};
	nonPartnerPlayersManagement: {
		loadNonPartnerPlayersLink: string;
		getNonPartnerCountries: string;
		uploadProfilePicture: string;
		getClubNonpartnerListLink: string;
		getNationalityListLink: string;
		getPositionListLink: string;
		addUpdateNonPartnerPlayerLink: string;
		deleteNonPartnerPlayerLink: string;
	};
	migrationTool: {
		getTeamsByTargetClub: string;
		getNonpartnerClubPlayers: string;
		executeNonpartnerClubMigration: string;
		nonpartnerPlayerExistsTargetClub: string;
		nonpartnerMigrationStatus: string;
		getPartnerClubPlayers: string;
		executePartnerClubMigration: string;
		partnerPlayerExistsTargetClub: string;
		partnerMigrationStatus: string;
		createPartnerPlayerFromNonpartner: string;
		migrateSourceClubNoPlayers: string;
		gameTeamUpdateLink: string;
	};
	notificationManagement: {
		loadSpecificGenericNotifications: string;
		addSpecificGenericNotifications: string;
		testSpecificGenericNotification: string;
		getUserNames: string;
		getClubProNames: string;
		getClubAmateurNames: string;
		getNotificationInstants: string;
		retryFailed: string;
		refreshJob: string;
	};

	countryManagement: {
		addUpdateCountry: string;
		deleteCountry: string;
		updateCountryEnable: string;
		loadCountryTooltipLists: string;
	};
}
export interface AdminManagementTableColumns {
	id: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	email: string;
	isActive: string;
	roles: string;
	roleId: string;
	photo: string;
}
export interface PoleManagementTableData {
	oldId?: number;
	poleId?: number;
	timezone?: number;
	location?: string;
	currentBattery?: number;
	assignedClub?: {
		id: number;
		name: string;
	}[];
	assignedClubCount?: number;
	serialNumber?: string;
	sync?: null;
	action?: null;
	id: string;
}

export interface PoleManagementTableColumns {
	column?: {
		action: string;
		assignedClub: String;
		assignedClubCount: string;
		currentBattery: string;
		id: string;
		location: string;
		sync: string;
		timezone: string;
	};
}
export interface AssignPoleData {
	assign_to?: string;
	club_id?: number;
	pole_id: number;
	role_id: number;
}

export interface CountryExpansionTableColumns {
	countryCode?: string;
	countryName?: string;
	enabled?: boolean | string;
	id?: number;
	tooltip?: string;
	continent?: string;
}
export interface CountryExpansionTableColumnsData {
	countries?: { label?: string; value?: string }[];
	enabled?: string | boolean;
	id?: number | null;
	tooltip?: string;
}
export interface SubscriptionManagementTableColumns {
	id?: number;
	comment?: string;
	product?: string;
	active?: boolean;
	countries?: {};
	countriesCount?: number;
	gameOptions?: {};
	proClubId?: string;
	startDate?: {};
	expirationDate?: {};
	maxAccounts?: number;
	expiresIn?: number;
	proClubName?: string;
	ownerships?: { value: number; label: string }[];
}

export enum GameOptions {
	AI = "ai",
	STATISTICS_RATING = "statistics_rating",
	STATISTICS_PLAYERS = "statistics_players",
	STATISTICS_TEAMS = "statistics_teams",
	EVENTS = "events",
	DOWNLOADABLE = "downloadable",
	CLIPS = "clips",
	DOWNLOADABLE_GAMETAGS = "downloadable_gametags",
	PLAYER_STATS_SEARCH = "player_stats_search",
}
export interface NonPartnerClubData {
	id: number;
	name: string;
	country: string | null;
	logo: string | null;
	description: string | null;
	contactName: string | null;
	contactPhoneNumber: number | null;
	contactEmail: string | null;
	city: string | null;
	zipcode: number | null;
	address: string | null;
	region: string | null;
	geographicalArea: string | null;
	showCase: boolean | null;
	gamesCount: number;
	playersCount: number;
}
export interface NonPartnerPlayerData {
	clubNonpartnerId: number;
	firstName: string;
	id: number;
	lastName: string;
	playedInNationalTeam: boolean;
	npClubCountry: string;
	npClubLogo: null | string;
	npClubName: string;
	picture: null | string;
	birthday: string;
	position: string;
	nationality: string;
	nationality2: string;
	strongestFoot: string;
	playersCount?: number;
	gamesCount?: number;
}

export interface nonPartnerClubAndPlayerData {
	clubNonpartnerId?: number;
	firstName?: string;
	id?: number;
	lastName?: string;
	playedInNationalTeam?: boolean;
	npClubCountry?: string;
	npClubLogo?: null | string;
	npClubName?: string;
	picture?: null | string;
	birthday?: string;
	position?: string;
	nationality?: string;
	strongestFoot?: string;
	name?: string;
	country?: string | null;
	logo?: string | null;
	description?: string | null;
	contactName?: string | null;
	contactPhoneNumber?: number | null;
	contactEmail?: string | null;
	city?: string | null;
	zipcode?: number | null;
	address?: string | null;
	region?: string | null;
	geographicalArea?: string | null;
	showCase?: boolean | null;
	gamesCount?: number;
	playersCount?: number;
}

export interface ClubData {
	address?: null;
	city?: null;
	contactEmail?: null;
	contactName?: null;
	contactPhoneNumber?: null;
	country?: string;
	description?: null;
	gamesCount?: number;
	geographicalArea?: null;
	id: string;
	logo?: null;
	name?: string;
	playersCount?: number;
	region?: null;
	showCase?: null;
	zipcode?: null;
}

export interface PlayerData {
	birthday?: null;
	clubNonpartnerId: string;
	firstName?: string;
	id?: number;
	lastName?: string;
	nationality?: null;
	npClubCountry?: string;
	npClubLogo?: null;
	npClubName?: string;
	picture?: null;
	position?: null;
	strongestFoot?: null;
}
export interface NonPartnerMigrationPlayer {
	club: string;
	clubCountry: null | string;
	teamCategory: null | string;
	teamName: null | string;
	firstName: string;
	id: number;
	lastName: string;
	picture: string;
	percentage: number;
	migrationId: number;
	targetPlayerId: number;
	status: string;
	selected: boolean;
	selectedTeam: SingleValue<{
		value: string;
		label: string;
	}>;
}
export interface CheckMigrationExists {
	count: {
		gamesCount: number;
		playersCount: number;
	};
	deleteClub: boolean;
	status: boolean;
	message: string;
}
export enum MigrationType {
	PARTNER = "partner",
	NON_PARTNER = "non-partner",
	ANY = "any"
}

export enum ClubMigrationType {
	PARTNER = "partner",
	NON_PARTNER = "nonpartner",
}

export interface MigrationStatusPlayersTable {
	firstName: string;
	lastName: string;
	team: string;
	status: string;
}
export interface MigrationStatusGamesTable {
	id: string;
	category: string;
	homeTeam: { label: string; value: number | null };
	homeClub: string;
	awayTeam: { label: string; value: number | null };
	awayClub: string;
	date: string;
	migratedClub?: string;
}
export interface MigrationStatusData {
	failed: {
		skippedPlayers: MigrationStatusPlayersTable[];
		skippedPlayersCount: number;
		skippedGames: any[];
		skippedGamesCount: number;
	};
	success: {
		migratedPlayers: MigrationStatusPlayersTable[];
		migratedPlayersCount: number;
		migratedGames: any[];
		migratedGamesCount: number;
	};
}

export interface NotificationManagementTableColumns {
	date?: string;
	email?: string;
	failed?: number;
	id?: number;
	title?: string;
	authorName?: string;
	language?: string;
	methods?: string;
	phoneNumber?: number;
	successful?: number;
	status?: string;
	userCount?: number;
	retry?: string;
	detail?: string;
	percentage?: number;
	refresh?: string;
	messages?: string;
	authorId?: number;
}
export interface NotificationManagementData {
	authorName?: string;
	date?: string;
	failed?: number;
	id?: number;
	messages?: { fullLanguage?: string; language?: string; title?: string; body?: string }[];
	methods?: string[];
	percentage?: number;
	receivers?: {
		users?: MultiValue<{ value?: number | undefined; label?: string | undefined }> | null | [];
		amateurClubs?: MultiValue<{ value?: number | undefined; label?: string | undefined }> | null | [];
		proClubs?: MultiValue<{ value?: number | undefined; label?: string | undefined }> | null | [];
	};
	authorId?: number;
	title?: string;
	successful?: number;
	userCount?: number;
}

export interface JobDetailTableColumns {
	id?: number;
	email?: string;
	method?: string;
	locale?: string;
	sentDateTime?: string;
	delivered?: boolean;
	exception?: string;
	firstName?: string;
	lastName?: string;
	phoneNumber?: string;
}

export interface ProductManagementOptions{
	name: string;
	icon?: string;
	hasSubMenu?: boolean;
	id:string;
	subMenuItems: {
		name: string;
		id: string;
	}[]
}

export enum TableColumns {
	LAST_NAME = "lastName",
	EXCEPTION = "exception",
	PHONE_NUMBER = "phoneNumber",
	SMS = "SMS",
	EMAIL = "Email",
}

export enum Continents {
	SOUTH_AMERICA = "southAmerica",
	NORTH_AMERICA = "northAmerica",
	OCEANIA = "oceania",
	EUROPE = "europe",
	ASIA = "asia",
	AFRICA = "africa",
}

export enum SubscriptionProduct {
	NEWSFEED = "Newsfeed",
}

export enum Status {
	NOT_AVAILABLE = "N/A",
}

export enum ClubType {
	PARTNER = "partner",
	NON_PARTNER = "nonpartner",
}

export interface TableData{
	columns: {
		[K in keyof SubscriptionManagementTableColumns]: string;
	};
	data: [];
}

export enum Resource {
  PRODUCT_MANAGEMENT = "product_management",
  CLUBS_AMATEUR = 'clubs_amateur',
  GAME_UPLOADS = 'game_uploads',
  CLUBS_PRO = 'clubs_pro',
}

export enum Permission {
  READ = 'read',
  LIST = 'list',
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
}
