import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import { useEffect, useState } from "react";
import "../style/videoTestPage.css";
import client from "libs/client";
import VidstackPlayer from "components/PlayerTestPage/VidstackPlayer";
import PlyrPlayer from "components/PlayerTestPage/PlyrPlayer";
import { setScrollBehavior } from "features/scrollPageBehaviorProvider/scrollPageBehaviorProvider";
import Select, { type SingleValue } from "react-select";

const backupVideos = [
	{ value: "AS Monaco/Veo/2024-01-21T14:30/", label: "AS Monaco/Veo/2024-01-21T14:30/" },
	{ value: "Burnley-FC/Veo/2024-03-16T10:00/", label: "Burnley-FC/Veo/2024-03-16T10:00/" },
	{ value: "CF-Alhendin-Balompie/Veo/2024-01-14T12:40/", label: "CF-Alhendin-Balompie/Veo/2024-01-14T12:40/" },
	{ value: "FC-Montrouge/Veo/2023-01-01T15:00/", label: "FC-Montrouge/Veo/2023-01-01T15:00/" },
	{ value: "Rosenborg BK/Hudl/2024-03-02T14:00/", label: "Rosenborg BK/Hudl/2024-03-02T14:00/" },
	{ value: "Swansea-City/Hudl/2024-03-23T10:00/", label: "Swansea-City/Hudl/2024-03-23T10:00/" },
];

export default function VideoTestPage() {
	useEffect(() => {
		setScrollBehavior();
	}, []);

	const { data, refetch } = client.getTestVideos.useQuery();

	const videos =
		data?.data.map((video) => ({
			value: video,
			label: video,
		})) ?? backupVideos;

	const [activeVideo, setActiveVideo] = useState<SingleValue<{ value: string; label: string }>>(videos[0]);

	return (
		<div className="videoApp-container">
			<div className="videoApp-controls">
				<button className="videoApp-loadButton" type="button" onClick={() => refetch()}>
					load videos
				</button>
				<Select className="videoApp-select" value={activeVideo} options={videos} onChange={(video) => setActiveVideo(video)} />
			</div>
			<div className="videoApp-players">
				<>
					<VidstackPlayer key={activeVideo?.value} video={activeVideo} />
				</>
				<>
					<PlyrPlayer key={activeVideo?.value} video={activeVideo} />
				</>
			</div>
		</div>
	);
}
