import { useEffect, useRef } from "react";
import Plyr from "plyr";
import Hls from "hls.js";
import "plyr/dist/plyr.css";
import type { SingleValue } from "react-select";

interface PlyrPlayerProps {
	video: SingleValue<{ value: string; label: string }>;
}

export default function PlyrPlayer({ video }: PlyrPlayerProps) {
	const videoRef = useRef(null);
	const apiUrl = "https://d2iheamiwdkkor.cloudfront.net/";

	const videoUrl = new URL(`${apiUrl}${video?.value}Stream/stream.m3u8`);
	const thumbUrl = new URL(`${apiUrl}${video?.value}Thumbnails/thumbnails.vtt`);
	const posterUrl = new URL(`${apiUrl}${video?.value}Metadata/poster.png`);

	let plyrInstance: Plyr;

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		const setupPlayer = () => {
			if (!videoRef.current) return;

			if (Hls.isSupported()) {
				const hls = new Hls();
				hls.loadSource(videoUrl.toString());
				hls.attachMedia(videoRef.current);
			}
			plyrInstance = new Plyr(videoRef.current, {
				controls: ["play", "progress", "current-time", "mute", "volume", "fullscreen"],
				captions: { active: true, update: true, language: "en" },
				autoplay: false,
				previewThumbnails: {
					enabled: true,
					src: thumbUrl.toString(),
				},
			});

			return () => {
				if (plyrInstance) {
					plyrInstance.destroy();
				}
			};
		};

		setupPlayer();
	}, [video]);

	return (
		<div className="videoApp-playerContainer">
			<video ref={videoRef} controls playsInline crossOrigin="anonymous" poster={posterUrl.toString()} />
		</div>
	);
}
