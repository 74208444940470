import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, createContext, useContext } from "react";
import qc from "libs/queryClient";
import { UserResponse } from "libs/apiClient";


interface PublicUserDataContextValues {
	user: UserResponse;
}

interface UserDataContextValues extends PublicUserDataContextValues {
	invalidateUser: () => void;
}

const UserDataContext = createContext(null as unknown as UserDataContextValues);

export const useUserData = () => useContext(UserDataContext).user;
export const useUserActions = () => {
	const { invalidateUser } = useContext(UserDataContext);
	return { invalidateUser };
};
interface UserDataProviderProps extends PublicUserDataContextValues {
	children: ReactElement | ReactElement[];
}

export default function UserDataProvider({ children, user }: UserDataProviderProps) {
	const invalidateUser = () => qc.invalidateQueries({ queryKey: ["currentUser"] });

	return <UserDataContext.Provider value={{ user, invalidateUser }}>{children}</UserDataContext.Provider>;
}
