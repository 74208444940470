import * as Sentry from "@sentry/react";
import LoginPage from "pages/login";
import { createBrowserRouter, RouterProvider, useLocation } from "react-router-dom";
import RootPage, { loader as rootLoader } from "pages/root";
import queryClient from "libs/queryClient";
import client from "libs/client";
import ErrorPage from "pages/errorPages/errorPage";
import Iframe, { iframeLoader } from "components/iframe";
import ClubsIFramePage from "pages/clubsIframe";
import ForgottenPasswordPage from "pages/ForgottenPasswordPage";
import ProductManagement from "pages/productManagement/Index";
import NotFoundPage from "pages/errorPages/NotFoundPage";
import { UserPage } from "pages/userPage";
import VideoTestPage from "pages/VideoTestPage";
import ResetPasswordPage from "pages/ResetPasswordPage";
import TeamsPage from "pages/TeamsPage";
import RedirectPage, { loader as redirectLoader } from "pages/RedirectPage";

function IFramePage() {
	const location = useLocation();
	return <Iframe url={location.search ? `${location.pathname}${location.search}` : location.pathname} />;
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
	//REDIRECTS
	{
		path: "/admin/club-amateur/:id/teams",
		loader: redirectLoader,
		element: <RedirectPage />,
	},
	{
		path: "/",
		element: <RootPage />,
		loader: rootLoader(queryClient, client),
		errorElement: <ErrorPage />,
		children: [
			{
				index: true,
				loader: iframeLoader,
				element: <ClubsIFramePage />,
			},
			{
				path: "/product-management",
				element: <ProductManagement />,
			},
			{
				path: "/video-test",
				element: <VideoTestPage />,
			},
			{
				path: "/admin/clubs-amateur",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/videos",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/clubs-pro",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-amateur/:id",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/clubs-amateur/add",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-amateur/:id/users",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-amateur/:id/users/:id/new-password",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/club/:id/teams",
				element: <TeamsPage />,
			},
			{
				path: "/admin/club-amateur/:id/match/:id",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-amateur/:id/planification-match",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/videos/match/:id",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-amateur-nonpartner/:cid/match/:mid",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-amateur-nonpartner/:id",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-amateur-nonpartner/:id/games",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-pro/:id",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-pro/:id/users",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/club-pro/:id/users/:id/new-password",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/clubs-pro/add",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/admin/match/:id/edit/:id",
				loader: iframeLoader,
				element: <IFramePage />,
			},
			{
				path: "/users/:id",
				element: <UserPage />,
			},
		],
	},
	{
		path: "/forgotten-password",
		element: <ForgottenPasswordPage />,
	},
	{
		path: "/new-password/:token",
		element: <ResetPasswordPage />,
	},
	{
		path: "/login",
		element: <LoginPage />,
	},
	{
		path: "/admin",
		element: <LoginPage />,
	},
	{
		path: "*",
		element: <NotFoundPage />,
	},
]);

export function navigate(dest: string) {
	router.navigate(dest);
}

export default () => <RouterProvider router={router} />;
