import type React from "react";
import "./Datagrid.css";
import { type ReactNode, useMemo } from "react";
import {
	flexRender,
	getCoreRowModel,
	useReactTable,
	type ColumnDef,
	type RowData,
	type PaginationState,
	type HeaderContext,
} from "@tanstack/react-table";
import TablePageControls from "./TablePageControls";
import Loader from "../Loader/Loader";

export interface SimpleColumn<T> {
	key: keyof T;
	id?: string;
	header: string | ((info: HeaderContext<T, unknown>) => ReactNode);
	cell?: (value: T[keyof T], row: T) => ReactNode;
}

type TableProps<T extends RowData> = {
	noResults: string;
	data: T[];
	simpleColumns: SimpleColumn<T>[];
	pagination: PaginationState;
	setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
	pageCount: number;
	isLoading?: boolean;
};

export default function DatagridV2<T extends RowData>({
	noResults,
	data,
	simpleColumns,
	pagination,
	pageCount,
	setPagination,
	isLoading = false,
}: TableProps<T>) {
	interface CustomColumnProperties<T> {
		filterOptions?: Array<{ value: string; label: string }>;
	}

	type ExtendedColumnDef<T> = ColumnDef<T> & CustomColumnProperties<T>;

	const columns: ExtendedColumnDef<T>[] = useMemo(
		() =>
			simpleColumns.map((col) => ({
				accessorKey: col.key as keyof T,
				header: typeof col.header === "string" ? col.header : (info) => (col.header as (info: HeaderContext<T, unknown>) => ReactNode)(info),
				cell: col.cell ? (info) => col.cell?.(info.getValue() as T[keyof T], info.row.original) : (info) => info.getValue(),
			})),
		[simpleColumns],
	);

	const table = useReactTable({
		data: data,
		pageCount: pageCount,
		state: {
			pagination,
		},
		onPaginationChange: setPagination,
		columns,
		getCoreRowModel: getCoreRowModel(),
		manualPagination: true,
	});

	return (
		<>
			<table className="datagrifd2Table">
				<thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<th className="datagrifd2Table-headerCell" key={header.id}>
									{flexRender(header.column.columnDef.header, header.getContext())}
								</th>
							))}
						</tr>
					))}
				</thead>
				{isLoading ? (
					<tbody className="datagrifd2Table-body">
						<tr>
							<td colSpan={table.getVisibleFlatColumns().length}>
								<Loader />
							</td>
						</tr>
					</tbody>
				) : (
					<tbody className="datagrifd2Table-body">
						{table.getRowModel().rows.map((row) => (
							<tr className="datagrifd2Table-row" key={row.id}>
								{row.getVisibleCells().map((cell) => (
									<td className="datagrifd2Table-cell" key={cell.id}>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								))}
							</tr>
						))}
					</tbody>
				)}
			</table>
			{!isLoading && data.length === 0 && <div className="datagrifd2Table-noData">{noResults}</div>}
			<TablePageControls table={table} />
		</>
	);
}
