import { useUserData } from "features/userData/context";
import { JobDetailTableColumns, nonPartnerClubAndPlayerData, NonPartnerPlayerData, TableColumns, continentCountries } from "./types";

export function parseRoleName(input: string): string {
	return input.replace("ROLE_", "").replaceAll("_", " ");
}

export function formatSelectValues(response: string[]): { label?: string; value: string }[] {
	return response.map((timezone: string) => {
		return { value: timezone, label: timezone };
	});
}

export function handleIntersectionObserver(
	entries: IntersectionObserverEntry[],
	start: React.MutableRefObject<number>,
	totalRecords: React.MutableRefObject<number>,
	fetchData: (loadMore?: boolean) => Promise<void>,
	setHasMoreData: React.Dispatch<React.SetStateAction<boolean>>,
): void {
	const [entry] = entries;
	if (entry.isIntersecting) {
		if (start.current <= totalRecords.current) {
			fetchData(true);
			start.current = start.current + 10;
			//setHasMoreData(true);
		} else {
			setHasMoreData(false);
		}
	}
}
export function numFormatter(num: number): string {
	const formatter = new Intl.NumberFormat("en", {
		notation: "compact",
	});
	return formatter.format(num);
}

export function methodFormatter(data: string[]): string {
	return data
		.map((element) => {
			if (element === "sms") {
				return "SMS";
			} else return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
		})
		.join(", ");
}

export function destinationFinder(data: JobDetailTableColumns) {
	if (data.method == TableColumns.SMS) {
		return data.phoneNumber;
	} else if (data.method == TableColumns.EMAIL) {
		return data.email;
	}
}

export function addMigrationIdInLocalStorage(id: number): void {
	const migrationIds = JSON.parse(localStorage.getItem("migrationIds") as string);
	migrationIds.push(id);
	localStorage.setItem("migrationIds", JSON.stringify(migrationIds));
}

export function getContinentByCountry(country: string, continentCountries: continentCountries[]): string {
	let continent = "";

	for (const countries of continentCountries) {
		if (countries.options) {
			continent = countries.options.find((c) => c.value === country)?.continent!;
		}
		if (continent) {
			break;
		}
	}

	return continent;
}
export const getBase64 = (file: Blob) => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result);
	reader.onerror = reject;
});

export const isGranted = (resource: string, access: string): boolean => {
	const userData = useUserData();
	return userData.isSuperAdmin || !!userData.permissions?.find(permission => permission.resource === resource && permission.permission === access);
};
