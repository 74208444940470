import { Link, NavLink, useNavigate } from "react-router-dom";
import { Header, SelectedCountry, useToast } from "ui-kit";
import Shield from "ui-kit/src/icons/Shield";
import Person from "ui-kit/src/icons/Person";
import Planing from "ui-kit/src/icons/Planing";
import Play from "ui-kit/src/icons/Play";
import LangSwitch from "ui-kit/src/Header/LangSwitch/LangSwitch";
import AccountOptions from "ui-kit/src/Header/AccountOptions/AccountOptions";
import { useI18n } from "features/translation/context";
import { _ } from "i18n";
import { useActions, useCountriesStore } from "app/store";
import client from "libs/client";
import { Permission, type ProductManagementOptions, Resource } from "pages/productManagement/types";
import { isGranted } from "pages/productManagement/functions";
import { useUserData } from "features/userData/context";
import { MenuLink } from "types";

interface AdminHeaderProps {
	user: { name: string; email: string; photo: string | null };
}

export default function AdminHeader({ user }: AdminHeaderProps) {
	const { changeLocale, locale } = useI18n();
	const navigate = useNavigate();
	const { addSuccessToast, addErrorToast } = useToast();
	const { selection, countryData } = useCountriesStore();
	const { openCountrySelect } = useActions();
	const { productManagementOptions }: { productManagementOptions :ProductManagementOptions[]} = useUserData();

	const subMenuOptions = {
		subMenuName: _("resource.product_management"),
		options: Object.entries(productManagementOptions ?? [])
			.map(([key, value]) => ({
				label: value.name,
				id: value.hasSubMenu
					? Object.values(value?.subMenuItems)[0].id
					: value?.id,
				link: MenuLink.PRODUCT_MANAGEMENT,
			})),
	};


	const getNavItems = () => {
		const navItems = [
			{ resource: Resource.CLUBS_AMATEUR, permission: Permission.LIST, label: _("header.admin.club_amat"), link: "/admin/clubs-amateur", icon: <Shield /> },
			{ resource: Resource.GAME_UPLOADS, permission: Permission.READ, label: _("header.admin.upload_video"), link: "/admin/videos", icon: <Play /> },
			{ resource: Resource.CLUBS_PRO, permission: Permission.LIST, label: _("header.admin.club_pro"), link: "/admin/clubs-pro", icon: <Person /> },
			{ resource: Resource.PRODUCT_MANAGEMENT, permission: Permission.READ, label: _("header.admin.product_management"), link: "product-management", icon: <Planing /> },
			{ label:  _("header.admin.video_test"), link: "/video-test", icon: <Play /> },
		];

		return navItems.filter(config => isGranted(config.resource, config.permission));
	}

	const mutation = client.adminJsonLogout.useMutation({
		onSuccess: () => {
			addSuccessToast(_("logoff.success"));
			navigate("/login");
		},
		onError: () => {
			addErrorToast(_("logoff.fail"));
		},
	});

	const onLogout = () => {
		mutation.mutate();
		navigate("/login");
	};

	return (
		<>
			<Header
				logo={(logo) => (
					<Link to="/">
						<img className="header__logo-img" src={logo} alt="logo" />
					</Link>
				)}
				mainItems={getNavItems().map((item) => (
					<NavLink to={item.link} className="header__link" key={item.label}>
						{/* <svg className="header__link-icon icon icon-shield">
						<use xlinkHref={item.icon} />
					</svg> */}
						<>
							{item.icon}
							{item.label}
						</>
					</NavLink>
				))}
				rightItems={[
					<SelectedCountry onClick={openCountrySelect} selection={selection} countryData={countryData} key="country-selection" />,
					<LangSwitch onChange={changeLocale} selectedLocale={locale} key="lang-switch" />,
					<AccountOptions
						navItems={getNavItems()}
						subMenuOptions={subMenuOptions}
						user={user}
						onLogout={onLogout}
						key="account-options"
						apiUrl=""
					/>,
				]}
			/>
		</>
	);
}
