import { useRef, type FC, useEffect, useState } from "react";
import type {ProductManagementOptions, menuItems, newMenuItems} from "./types";
import client from "libs/client";
import Icon from "components/Icon";
interface SidebarProps {
	onMenuClick: (id: string) => void;
	activeMenu: string | null;
}

const Sidebar: FC<SidebarProps> = ({ onMenuClick, activeMenu }) => {

	const [menuItems, setMenuItems] = useState<newMenuItems[]>();
	const navLinks = useRef<HTMLUListElement>(null);

	const closeAllSubMenu = () => {
		navLinks.current?.childNodes.forEach((value: ChildNode, key: number) => {
			if (navLinks.current?.children.item(key)?.classList.contains("showMenu")) {
				navLinks.current?.children.item(key)?.classList.toggle("showMenu");
			}

			if (navLinks.current?.children.item(key)?.children[0].classList.contains("active")) {
				navLinks.current?.children.item(key)?.children[0].classList.toggle("active");
			}
		});
	};

	const getMenuItems = async () => {
		const newMenuItems: newMenuItems[] = [];
		const response = await client.getCurrentUser.query().then((res) => { return res?.data as unknown as { productManagementOptions: ProductManagementOptions } });
		if(response){
			Object.entries(response.productManagementOptions ?? []).map((menuItem) => {
				const [menuKey, menuValue]: [string, ProductManagementOptions] = menuItem;
				menuValue.id = menuKey;
		
				if (menuValue.hasSubMenu && menuValue.subMenuItems) {
					menuValue.subMenuItems = Object.entries(menuValue.subMenuItems).map(([key, value]) => {
						return { ...value };
					});
				}

				newMenuItems.push(menuValue as unknown as newMenuItems);
			});
			setMenuItems(newMenuItems);
		}
	}

	useEffect(()=>{
		getMenuItems();
	},[])

	return (
		<div
			className="sidebar"
			onClick={(e) => {
				e.preventDefault();
				if (e.target === e.currentTarget) {
					e.currentTarget.classList.toggle("close");
				}
			}}
		>
			<div className="logo-details hide">
				<a href="#">
					<i className="bx-menu">
						<svg className="icon icon-admin-menu">
							<use xlinkHref="#icon-admin-menu" />
						</svg>
					</i>
				</a>
			</div>
			<ul className="nav-links" ref={navLinks}>
				{
					menuItems?.map((menuItem, index) => {
						return (
							<li
								key={menuItem.id}
								className={
									(activeMenu === "non_partner_club" || activeMenu === "non_partner_player" || activeMenu === "migrations") &&
									menuItem.id === "non_partner_dataset"
										? "main-link hasSubMenu showMenu"
										: menuItem.hasSubMenu
										? "main-link hasSubMenu"
										: "main-link"
								}
								onClick={(e) => {
									closeAllSubMenu();
									if (e.currentTarget.classList.contains("hasSubMenu")) {
										e.currentTarget.classList.toggle("showMenu");
									} else {
										onMenuClick(menuItem.id);
									}
								}}
							>
								<div
									className={
										menuItem.id === "non_partner_dataset"
											? activeMenu === "non_partner_club"
												? "icon-link active"
												: "icon-link"
											: menuItem.id === activeMenu
											? "icon-link active"
											: "icon-link"
									}
								>
									<a href="#">
										<i><Icon icon={menuItem.icon} size={24} /></i>
										<span className="link_name">{menuItem.name}</span>
									</a>
									{menuItem.hasSubMenu && (
										<i className="bx arrow">
											<span className="icon-new icon-24 icon-dark-lavendar icon-admin-arrow-new"/>
										</i>
									)}
								</div>
								<>
									{menuItem.hasSubMenu && (
										<ul className="sub-menu">
											{menuItem.subMenuItems.map((subMenuItem, index) => {
												return (
													<li
														key={subMenuItem.id}
														id={subMenuItem.id}
														onClick={(e) => {
															closeAllSubMenu();
															onMenuClick(subMenuItem.id);
															e.currentTarget?.parentElement?.parentElement?.classList.toggle("showMenu");
														}}
													>
														<a href="#">{subMenuItem.name}</a>
													</li>
												);
											})}
										</ul>
									)}
								</>
							</li>
						);
					})}
			</ul>
		</div>
	);
};

export default Sidebar;
