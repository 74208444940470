import Layout from "components/layout";
import { Outlet, useLoaderData, useNavigate, useLocation } from "react-router-dom";
import { useActions, useCountriesStore } from "app/store";
import type { Client } from "libs/client";
import client from "libs/client";
import type { QueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { type CountrySelection, CountrySelectionModal, type UserCountrySelection, emptySelection, useToast } from "ui-kit";
import { useI18n } from "features/translation/context";
import { useIframeStore } from "app/iframeStore";
import { useConstants } from "features/constantsProvider/context";
import { useRightClickMenuStore } from "app/rightClickMenuStore";
import RightClickMenu from "components/RightClickMenu/RightClickMenu";
import { setScrollBehavior } from "features/scrollPageBehaviorProvider/scrollPageBehaviorProvider";
import UserDataProvider from "features/userData/context";
import { UserResponse } from "libs/apiClient";

const _query = () => ({
	queryKey: ["workspaces"],
	queryFn: async () => {},
});

export function loader(_queryClient: QueryClient, client: Client) {
	return async () => {
		const res = await client.getCurrentUser.query();

		return res?.data;

		// const q = query();
		// // ?? return data or fetch it
		// return queryClient.getQueryData(q.queryKey) ?? (await queryClient.fetchQuery(q));
	};
}

export default function RootPage() {
	const userData = useLoaderData() as UserResponse;
	const { locale } = useI18n();
	const { setCountryData, setSelectedCountries, closeCountrySelect, updateSelectedCountries } = useActions();
	const { countryData, selection, showModal } = useCountriesStore();
	const { openCountrySelect } = useActions();
	const { addSuccessToast, addErrorToast } = useToast();
	const {
		actions: { startIframeLoading, stopIframeLoading },
	} = useIframeStore();
	const navigate = useNavigate();
	const { apiURL } = useConstants();
	const {
		isOpen: isRightClickMenuOpen,
		actions: { openRightClickMenu, closeRightClickMenu },
	} = useRightClickMenuStore();
	const location = useLocation();

	// biome-ignore lint/correctness/useExhaustiveDependencies: adding other dependencies is undesirable
	useEffect(() => {
		const es = new EventSource(`${import.meta.env.VITE_SSE_URL}?topic=msg`, { withCredentials: true });

		es.onmessage = (ev) => {
			console.log("sse es");
			console.log(ev);
		};

		client.getAllCountries.query({ locale: locale }).then((res) => {
			if (res) {
				setCountryData(res.data);
			}
		});

		client.getSelectedCountries.query().then((res) => {
			if (res) {
				setSelectedCountries(res.data);
			}
		});

		client.setUserLocale.query({ locale });

		return () => {
			es.close();
		};
	}, [locale]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: adding other dependencies is undesirable
	useEffect(() => {
		const es = new EventSource(`${import.meta.env.VITE_SSE_URL}?topic=msg`, { withCredentials: true });
		// es.onmessage = (ev) => {
		// 	console.log("sse es");
		// 	console.log(ev);
		// };

		setScrollBehavior();

		const messageListener = (
			// biome-ignore lint/suspicious/noExplicitAny: <explanation>
			event: MessageEvent<{ event: string; url: string; position: { x: number; y: number }; code: number; errorEvent: any }>,
		) => {
			if (event.origin === apiURL) {
				// Handle cases where origin resource can be loaded using HTTP - Test Env., Review Links, Experimental
				const API_URL_HTTPS = apiURL.replace("http://", "https://");
				const API_URL_HTTP = apiURL.replace("https://", "http://");

				const url = event.data.url?.replace(API_URL_HTTPS, "").replace(API_URL_HTTP, "");
				switch (event.data.event) {
					case "load":
						stopIframeLoading();
						setScrollBehavior();
						break;

					case "error":
						stopIframeLoading();
						break;

					case "intercept":
						startIframeLoading();
						navigate(url);
						break;

					case "click":
						startIframeLoading();
						navigate(url);
						break;
					case "openCountrySelection":
						openCountrySelect();
						break;

					case "openContextMenu":
						openRightClickMenu(url, event.data.position);
						break;

					case "closeContextMenu":
						closeRightClickMenu();
						break;

					case "openTeamsPage":
						navigate(url);
						break;
				}
			}
		};

		window.addEventListener("message", messageListener);

		const popstateListener = () => {
			startIframeLoading();
		};

		window.addEventListener("popstate", popstateListener);

		return () => {
			window.removeEventListener("message", messageListener);
			window.removeEventListener("popstate", popstateListener);
			es.close();
		};
	}, []);

	useEffect(() => {

		client.logUserActivity.mutation({ body: { path: location.pathname } });

	}, [location.pathname]);

	const mutation = client.updateSelectedCountries.useMutation({
		onMutate: ({ body: { data } }) => {
			if (data) {
				updateSelectedCountries(data);
			}
			return selection;
		},
		onSuccess: (_res, { body: { data: _data } }) => {
			if (_res?.data.message) {
				addSuccessToast(_res?.data.message);
			}
			closeCountrySelect();
		},
		onError: (_error, _variables, context) => {
			addErrorToast(_error.message);
			updateSelectedCountries((context as UserCountrySelection).countries);
		},
	});

	const handleSave = (userSelectedCountries: CountrySelection) => {
		mutation.mutate({ body: { data: { ...userSelectedCountries } } });
	};

	return (
		<UserDataProvider user={userData || {}}>
		<Layout>
			<Outlet />
			{showModal ? (
				<CountrySelectionModal
					countryData={countryData}
					selection={{ ...selection.countries }}
					onSave={handleSave}
					onClose={closeCountrySelect}
				/>
			) : null}
			{isRightClickMenuOpen ? <RightClickMenu /> : null}
		</Layout>
		</UserDataProvider>
	);
}
