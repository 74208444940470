import { MediaPlayer, MediaProvider, Poster } from "@vidstack/react";
import { defaultLayoutIcons, DefaultVideoLayout } from "@vidstack/react/player/layouts/default";
import type { SingleValue } from "react-select";

interface VidstackPlayerProps {
	video: SingleValue<{ value: string; label: string }>;
}

export default function VidstackPlayer({ video }: VidstackPlayerProps) {
	const apiUrl = "https://d2iheamiwdkkor.cloudfront.net/";

	const videoUrl = new URL(`${apiUrl}${video?.value}Stream/stream.m3u8`);
	const thumbUrl = new URL(`${apiUrl}${video?.value}Thumbnails/thumbnails.vtt`);
	const posterUrl = new URL(`${apiUrl}${video?.value}Metadata/poster.png`);

	return (
		<div className="videoApp-playerContainer">
			<MediaPlayer src={videoUrl?.toString()}>
				<MediaProvider>
					<Poster className="vds-poster" src={posterUrl?.toString()} />
				</MediaProvider>
				<DefaultVideoLayout thumbnails={thumbUrl?.toString()} icons={defaultLayoutIcons} />
			</MediaPlayer>
		</div>
	);
}
