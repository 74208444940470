export interface Keys {
	locale: never;
	"btn.cancel": never;
	"btn.delete": never;
	"btn.edit": never;
	"btn.sign_in": never;
	"btn.submit": never;
	"btn.submit_filters": never;
	"btn.clear_filters": never;
	"btn.submit.ok": never;
	"btn.reset_password": never;
	"btn.new_reset_password": never;
	"btn.generate_new_password": never;
	"btn.continue": never;
	"btn.planing_match": never;
	"label.already_have_account": never;
	"account.signup.new.password": never;
	"account.signup.new.password.instruction": never;
	"account.signup.forgot_password": never;
	"account.signup.label.email": never;
	"account.signup.label.password": never;
	"account.creation.title": never;
	"account.reset_password.expired": never;
	"account.change.label.old_password": never;
	"profile.born_the": never;
	"profile.born_in": never;
	"profile.height.unit": never;
	"profile.weight.unit": never;
	"label.user.gender": never;
	"label.user.id": never;
	"label.user.first_name": never;
	"label.user.last_name": never;
	"label.user.email": never;
	"label.user.phone": never;
	"label.user.street": never;
	"label.user.extraStreet": never;
	"label.user.postal_code": never;
	"label.user.city": never;
	"label.user.country": never;
	"label.user.roles": never;
	"label.user.status": never;
	"label.user.plain_password.zero": never;
	"label.user.plain_password.first": never;
	"label.user.plain_password.second": never;
	"label.player.birthday": never;
	"label.player.birthday_short": never;
	"label.player.nationality": never;
	"label.player.nationality1": never;
	"label.player.nationality2": never;
	"label.player.position": never;
	"label.player.position1": never;
	"label.player.position2": never;
	"label.player.strongest_foot": never;
	"label.player.strongest_foot.left": never;
	"label.player.strongest_foot.right": never;
	"player.strongest_foot.left": never;
	"player.strongest_foot.right": never;
	"label.player.strongest_foot.left.letter": never;
	"label.player.strongest_foot.right.letter": never;
	"label.player.height": never;
	"label.player.weight": never;
	"label.player.photo": never;
	"label.player.team": never;
	"label.player.notes": never;
	"label.player.vip": never;
	"player.vip.success": never;
	"label.match.record.duration": never;
	"label.match.recordDuration.choices.championship": never;
	"label.match.recordDuration.choices.friendly": never;
	"label.match.recordDuration.choices.cup": never;
	"recordDuration.135": never;
	"recordDuration.180": never;
	"label.match.date": never;
	"label.match.time": never;
	"label.match.homeTeam": never;
	"label.match.awayTeam": never;
	"label.match.awayClub": never;
	"label.team.category": never;
	"label.match.pole_id": never;
	"label.level": never;
	"label.coach": never;
	"label.coach.team": never;
	"label.admin": never;
	"label.user": never;
	"label.create_account.admin": never;
	"label.create_account.coach": never;
	"label.update": never;
	"label.coach.empty_select": never;
	"label.select_team_category": never;
	"label.team_name": never;
	"label.team_name_error": never;
	"filter.team.name": never;
	"filter.team.all": never;
	"label.edit": never;
	"label.next_step": never;
	"label.not_my_player": never;
	"label.add_player_to_team": never;
	"label.add_player.photo_infos": never;
	"label.remove_player": never;
	"label.remove_player.cancel": never;
	"form.club.add_team.success": never;
	"form.club.add_team.error": never;
	"form.club.assign_coach.no_team": never;
	"form.club.assign_coach.coach_unassign": never;
	"form.club.assign_coach.no_coach": never;
	"form.club.assign_coach.success": never;
	"form.club.assign_coach.error": never;
	"form.club.level_update.success": never;
	"form.club.search_player.error": never;
	"form.club.match_sheet.edit.success": never;
	"enum.colors.black": never;
	"enum.colors.white": never;
	"enum.colors.red": never;
	"enum.colors.orange": never;
	"enum.colors.yellow": never;
	"enum.colors.green": never;
	"enum.colors.blue": never;
	"enum.colors.gray": never;
	"enum.colors.brown": never;
	"enum.colors.purple": never;
	"enum.position.G": never;
	"enum.position.DD": never;
	"enum.position.DC": never;
	"enum.position.DG": never;
	"enum.position.MDC": never;
	"enum.position.MC": never;
	"enum.position.MOC": never;
	"enum.position.MG": never;
	"enum.position.MD": never;
	"enum.position.MR": never;
	"enum.position.ATT": never;
	"enum.position.AG": never;
	"enum.position.AD": never;
	"enum.position.G.key": never;
	"enum.position.DD.key": never;
	"enum.position.DC.key": never;
	"enum.position.DG.key": never;
	"enum.position.MDC.key": never;
	"enum.position.MC.key": never;
	"enum.position.MR.key": never;
	"enum.position.MOC.key": never;
	"enum.position.MG.key": never;
	"enum.position.MD.key": never;
	"enum.position.ATT.key": never;
	"enum.position.AG.key": never;
	"enum.position.AD.key": never;
	"mail.user.password.reset": never;
	"mail.user.sign.up": never;
	"message.reset_password.success": never;
	"message.reset_password.back_to_login": never;
	"message.request_password.success.title": never;
	"message.request_password.success.msg": never;
	"message.request_password.fail.mail.msg": never;
	"message.request_password.fail.notfound.msg": never;
	"message.request_password.title": never;
	"message.remove_user.success": never;
	"message.remove_document.success": never;
	"message.searching_player.none": never;
	"message.searching_player.count": {
		count: number;
	};
	"message.searching_player.correspond": {
		lastname: string;
		firstname: string;
		birthday: string;
		nationality: string;
		count: number;
	};
	"message.searching_player.correspond.0": {
		lastname: string;
		firstname: string;
		birthday: string;
		nationality: string;
	};
	"message.searching_player.correspond.1": {
		count: string;
		lastname: string;
		firstname: string;
		birthday: string;
		nationality: string;
	};
	"message.searching_player.correspond.plural": {
		Count: string;
		lastname: string;
		firstname: string;
		birthday: string;
		nationality: string;
	};
	"message.searching_player.wrong": never;
	"message.add_team.warning": never;
	"message.remove_player_club.confirm": {
		lastname: string;
		firstname: string;
	};
	"message.add_player_club.success": {
		lastname: string;
		firstname: string;
	};
	"message.remove_player_club.success": {
		lastname: string;
		firstname: string;
	};
	"message.remove_player_club.dont_exist": {
		lastname: string;
		firstname: string;
	};
	"message.edit_player_club": {
		lastname: string;
		firstname: string;
	};
	"message.remove_player_team.confirm": never;
	"message.delete_team.confirm": never;
	"error.invalid_email": never;
	"error.invalid_password": never;
	"error.invalid_credentials": never;
	"error.bad_credentials": never;
	"error.invalid_permission": never;
	"error.not_active_account": never;
	"error.upload.wrong_type": never;
	"error.unknown_category": never;
	"error.reset_password.invalid_token": never;
	"club.page_and_account": never;
	"club.planing_match": never;
	"club.teams": never;
	"club.users": never;
	"club.players": never;
	"club.games": never;
	"club.former_players": never;
	"club.non_partner_club": never;
	"count.club.amat": never;
	"count.club.pro": never;
	"filter.alphabetic_asc": never;
	"filter.alphabetic_desc": never;
	"filter.date_asc": never;
	"filter.date_desc": never;
	"filter.installation": never;
	"filter.search_placeholder": never;
	"filter.add_club": never;
	"filter.add_match": never;
	"filter.add_player": never;
	"filter.add_team": never;
	"filter.delete_team": never;
	"filter.remove_player_team": never;
	"filter.remove_player_club": never;
	"filter.remove_player": never;
	"filter.add_recruiter": never;
	"filter.edit_recruiter": never;
	"form_add_club_amateur.label.logo": never;
	"form_add_club_amateur.label.name": never;
	"form_add_club_amateur.placeholder.name": never;
	"form_add_club_amateur.label.country": never;
	"form_add_club_amateur.placeholder.country": never;
	"form_add_club_amateur.label.address": never;
	"form_add_club_amateur.placeholder.address": never;
	"form_add_club_amateur.label.zipcode": never;
	"form_add_club_amateur.placeholder.zipcode": never;
	"form_add_club_amateur.label.city": never;
	"form_add_club_amateur.placeholder.city": never;
	"form_add_club_amateur.label.region": never;
	"form_add_club_amateur.placeholder.region": never;
	"form_add_club_amateur.placeholder.geographicalArea": never;
	"form_add_club_amateur.label.description": never;
	"form_add_club_amateur.placeholder.description": never;
	"form_add_club_amateur.label.idClub": never;
	"form_add_club_amateur.placeholder.idClub": never;
	"form_add_club_amateur.label.email": never;
	"form_add_club_amateur.placeholder.email": never;
	"form_add_club_amateur.label.phoneNumber": never;
	"form_add_club_amateur.placeholder.phoneNumber": never;
	"form_add_club_amateur.label.contactLastName": never;
	"form_add_club_amateur.placeholder.contactLastName": never;
	"form_add_club_amateur.label.contactFirstName": never;
	"form_add_club_amateur.placeholder.contactFirstName": never;
	"form_add_club_amateur.label.director": never;
	"form_add_club_amateur.label.directorEmail": never;
	"form_add_club_amateur.label.directorPhoneNumber": never;
	"form_add_club_amateur.label.manager_name": never;
	"form_add_club_amateur.label.manager_email": never;
	"form_add_club_amateur.label.manager_phone": never;
	"form_add_club_amateur.label.sent": never;
	"form_add_club_amateur.label.geographicalArea": never;
	"form_add_club_amateur.label.documents": never;
	"form_add_club_amateur.label.documents.add": never;
	"form_edit_club_amateur.label.edit": never;
	"form_edit_club_amateur.label.valid_edit": never;
	"form_edit_club_amateur.label.cancel_edit": never;
	"form_add_club_amateur.title": never;
	"form_add_club_amateur.header": never;
	"form_add_club_amateur.documents.mimeTypesMessage": never;
	"form_add_club_pro.placeholder.name": never;
	"form_add_club_pro.placeholder.idClub": never;
	"form_add_club_pro.label.is_active": never;
	"form_add_club_pro.label.is_active.true": never;
	"form_add_club_pro.label.is_active.false": never;
	"form_add_club_pro.header": never;
	"form_edit_player.title": never;
	"form_search_player_pro.birthmonth": never;
	"form_search_player_pro.birthyear": never;
	"form_search_player_pro.country": never;
	"form_search_player_pro.category": never;
	"form_search_pro_club_amateur.daterange": never;
	"form_search_pro_club_amateur.region": never;
	"form_search_pro_club_amateur.category": never;
	"form_search_pro_club_amateur.level": never;
	"form_search_pro_club_amateur.see": never;
	"breadcrumb.casual_club": never;
	"breadcrumb.casual_club_pro": never;
	"breadcrumb.casual_club_add": never;
	"breadcrumb.casual_club_infos": never;
	"breadcrumb.casual_club_users": never;
	"breadcrumb.casual_club_teams": never;
	"breadcrumb.casual_club_videos": never;
	"breadcrumb.casual_club_plan_matches": never;
	"breadcrumb.video_list": never;
	"breadcrumb.search_club": never;
	"breadcrumb.search_player": never;
	"clubInfos.installation": never;
	"clubInfos.pole": never;
	"clubInfos.camera": never;
	"bool.yes": never;
	"bool.no": never;
	"bool.true": never;
	"bool.false": never;
	"clubAmateur.users.clubManagerTable.title": never;
	"clubAmateur.users.clubManagerTable.add_button": never;
	"clubAmateur.users.clubManagerTable.modal.add_admin.title": never;
	"clubAmateur.users.clubManagerTable.modal.edit_admin.title": never;
	"clubAmateur.users.clubCoachesTable.title": never;
	"clubAmateur.users.clubCoachesTable.add_button": never;
	"clubAmateur.users.clubCoachesTable.modal.add_coach.title": never;
	"clubAmateur.users.clubCoachesTable.modal.edit_coach.title": never;
	"clubAmateur.users.clubManagerTable.colTitle.id": never;
	"clubAmateur.users.clubManagerTable.colTitle.firstname": never;
	"clubAmateur.users.clubManagerTable.colTitle.lastname": never;
	"clubAmateur.users.clubManagerTable.colTitle.email": never;
	"clubAmateur.users.clubManagerTable.colTitle.phone_number": never;
	"clubAmateur.users.clubManagerTable.colTitle.password": never;
	"clubAmateur.users.clubManagerTable.colTitle.team": never;
	"clubAmateur.users.renew_password": never;
	"clubAmateur.users.modal.delete_text": {
		user_type: string;
		user_name: string;
	};
	"clubAmateur.users.modal.delete_button": never;
	"clubAmateur.teams.sectionTitle.teams": never;
	"clubAmateur.teams.sectionTitle.my_teams": never;
	"clubAmateur.teams.button.add_team": never;
	"clubAmateur.teams.button.add_player": never;
	"clubAmateur.teams.button.remove_team": {
		remove_link: string;
	};
	"clubAmateur.teams.newTeam.submit_btn": never;
	"clubAmateur.newPlayer.modal.title": never;
	"clubAmateur.teams.newPlayer.modal.title": never;
	"clubAmateur.teams.newPlayer.searchPlayerResult": never;
	"clubAmateur.planning_match.title": never;
	"clubAmateur.planning_match.incoming.title": never;
	"clubAmateur.videos.title": never;
	"matchList.filter.team": never;
	"matchList.filter.range_date": never;
	"match.maillot": never;
	"match.short": never;
	"match.showTeam": never;
	"match.hideTeam": never;
	"match.coreTeam": never;
	"match.replacementteam": never;
	"match.score.edit.link": never;
	"match.score.edit.success": never;
	"match.aside.tab.teams": never;
	"match.highlight.btn.new": never;
	"match.highlight.btn.submit": never;
	"match.highlight.btn.reset": never;
	"match.highlight.required": never;
	"match.highlight.error.highlight_title_max_exceed": never;
	"match.highlight.edit": never;
	"match.highlight.delete": never;
	"match.highlight.delete.yes": never;
	"match.highlight.delete.no": never;
	"match.highlight.add": never;
	"match.highlight.empty": never;
	"match.highlight.deletePopup": never;
	"match.highlight.error.unauthorized": never;
	"match.reported": never;
	"highlight.placeholder.timestamp": never;
	"highlight.placeholder.title": never;
	"highlight.placeholder.note": never;
	"match.team.position": never;
	"match.team.player": never;
	"match.team.yob": never;
	"match.team.num": never;
	"match.team.goals": never;
	"match.edit_matchsheet": never;
	"match.add_matchsheet": never;
	"matchSheet.placeholder.position": never;
	"matchSheet.placeholder.playerName": never;
	"matchSheet.placeholder.numberSign": never;
	"matchSheet.placeholder.goal": never;
	"matchSheet.table.position": never;
	"matchSheet.table.playerName": never;
	"matchSheet.table.numberSign": never;
	"matchSheet.table.numberSign_mobile": never;
	"matchSheet.table.goal": never;
	"matchSheet.title": never;
	"matchSheet.composition": never;
	"matchSheet.jersey_color": never;
	"matchSheet.color_shorts": never;
	"matchSheet.title.workforce": never;
	"matchSheet.title.replacements": never;
	"matchSheet.button.add.replacement": never;
	"matchSheet.button.add.loadteam": never;
	"matchSheet.title.changes": never;
	"matchSheet.button.add.changes": never;
	"matchSheet.label.edit": never;
	"matchSheet.label.cancel_edit": never;
	"matchSheet.label.valid_edit": never;
	"matchSheet.table.player_in": never;
	"matchSheet.table.player_out": never;
	"matchSheet.table.time": never;
	"form.add_match.success": never;
	"form.add_match.failure": never;
	"form.add_match.failure.violation.badday": never;
	"form.add_match.failure.violation.badtime": never;
	"form.add_match.failure.violation.duplicate": never;
	"form.add_match.failure.violation.overlapping": never;
	"form.add_match.failure.violation.dailylimit": never;
	"form.add_match.failure.violation.weekly": never;
	"form.edit_match.success": never;
	"form.edit_match.failure": never;
	"form.matchSheet.edit.success": never;
	"filter.daterange.placeholder": never;
	"filter.button.finished": never;
	"filter.button.finished.shorter": never;
	"filter.button.coming": never;
	"filter.button.coming.shorter": never;
	"videos.list.title": never;
	"videos.table.th.region": never;
	"videos.table.th.club": never;
	"videos.table.th.date": never;
	"videos.table.th.team": never;
	"videos.table.th.opponent": never;
	"videos.table.th.matchsheet": never;
	"videos.table.th.score": never;
	"videos.table.th.upload": never;
	"videos.table.th.edit": never;
	"videos.table.th.page": never;
	"videos.table.th.vip": never;
	"videos.table.matchsheet.add": never;
	"videos.table.th.hidden": never;
	"videos.table.matchsheet.see": never;
	"videos.table.score.add": never;
	"videos.vip.success": never;
	"videos.hidden.success": never;
	"videos.modal.delete_match.title": never;
	"videos.modal.add_score.submit": never;
	"videos.modal.matchsheet.title": never;
	"videos.modal.add_score.title": never;
	"videos.modal.edit_match.title": never;
	"videos.modal.add_match.title": never;
	"videos.modal.edit_match.cancel": never;
	"videos.modal.add_match.cancel": never;
	"videos.modal.filter.title": never;
	"players.modal.filter.title": never;
	"clubs.modal.filter.title": never;
	"teams.modal.filter.title": never;
	"datatables.search": never;
	"datatables.aria.sort_ascending": never;
	"datatables.aria.sort_descending": never;
	"datatables.paginate.first": never;
	"datatables.paginate.previous": never;
	"datatables.paginate.next": never;
	"datatables.paginate.last": never;
	"datatables.processing": never;
	"datatables.lenght_menu": never;
	"datatables.info": never;
	"datatables.info_empty": never;
	"datatables.info_filtered": never;
	"datatables.loading_records": never;
	"datatables.zero_records": never;
	"datatables.empty_table": never;
	"form.club.add_club_manager.success": never;
	"form.club.edit_club_manager.success": never;
	"form.club.add_coach.success": never;
	"form.club.edit_coach.success": never;
	"form.club.add_recruiter.success": never;
	"form.club.edit_recruiter.success": never;
	"highlight.add.success": never;
	"highlight.edit.success": never;
	"highlight.delete.success": never;
	"daterange.applyLabel": never;
	"daterange.cancelLabel": never;
	"datepicker.clearText": never;
	"datepicker.clearStatus": never;
	"datepicker.closeText": never;
	"datepicker.closeStatus": never;
	"datepicker.prevText": never;
	"datepicker.prevStatus": never;
	"datepicker.nextText": never;
	"datepicker.nextStatus": never;
	"datepicker.currentText": never;
	"datepicker.currentStatus": never;
	"datepicker.days.sunday": never;
	"datepicker.days.monday": never;
	"datepicker.days.tuesday": never;
	"datepicker.days.wednesday": never;
	"datepicker.days.thursday": never;
	"datepicker.days.friday": never;
	"datepicker.days.saturday": never;
	"datepicker.days_short.sunday": never;
	"datepicker.days_short.monday": never;
	"datepicker.days_short.tuesday": never;
	"datepicker.days_short.wednesday": never;
	"datepicker.days_short.thursday": never;
	"datepicker.days_short.friday": never;
	"datepicker.days_short.saturday": never;
	"global.days_min.sunday": never;
	"global.days_min.monday": never;
	"global.days_min.tuesday": never;
	"global.days_min.wednesday": never;
	"global.days_min.thursday": never;
	"global.days_min.friday": never;
	"global.days_min.saturday": never;
	"datepicker.months.january": never;
	"datepicker.months.february": never;
	"datepicker.months.march": never;
	"datepicker.months.april": never;
	"datepicker.months.may": never;
	"datepicker.months.june": never;
	"datepicker.months.july": never;
	"datepicker.months.august": never;
	"datepicker.months.september": never;
	"datepicker.months.october": never;
	"datepicker.months.november": never;
	"datepicker.months.december": never;
	"datepicker.months_short.january": never;
	"datepicker.months_short.february": never;
	"datepicker.months_short.march": never;
	"datepicker.months_short.april": never;
	"datepicker.months_short.may": never;
	"datepicker.months_short.june": never;
	"datepicker.months_short.july": never;
	"datepicker.months_short.august": never;
	"datepicker.months_short.september": never;
	"datepicker.months_short.october": never;
	"datepicker.months_short.november": never;
	"datepicker.months_short.december": never;
	"player.birthday": never;
	"player.nationality": never;
	"front.login.title": never;
	"admin.login.title": never;
	"admin.login.forgot_password": never;
	"admin.login.remember_me": never;
	"admin.login.email.placeholder": never;
	"admin.login.password.placeholder": never;
	"admin.login.email.required": never;
	"admin.login.password.required": never;
	"forgot_password.title": never;
	"forgot_password.subtitle": never;
	"forgot_password.email": never;
	"forgot_password.sent": never;
	"email.see_on_site.text": never;
	"email.see_on_site.text.click_here": never;
	"email.copyright": never;
	"email.hello": never;
	"email.new_password.please_click": never;
	"email.new_account.please_click": never;
	"email.new_password.link_choose": never;
	"email.new_account.link_choose": never;
	"header.not_loged.contact": never;
	"header.not_loged.login": never;
	"header.admin.club_amat": never;
	"header.admin.upload_video": never;
	"header.admin.club_pro": never;
	"header.club_amat.my_club": never;
	"header.club_amat.team": never;
	"header.club_amat.planing": never;
	"header.club_pro.search_club": never;
	"header.club_pro.search_player": never;
	"header.club_pro.search_match": never;
	"header.club_pro.my_eyeball": never;
	"header.club_pro.search": never;
	"header.open_mobile_menu": never;
	"header.account.options": never;
	"header.account.options.close": never;
	"header.change_password": never;
	"header.logout": never;
	filter_match: never;
	"error.password.not_matching": never;
	"error.password.incorrect": never;
	"error.email.not_unique": never;
	"error.password.old": never;
	FR: never;
	"players.team_title": never;
	"players.team_title.count": never;
	"players.goals": never;
	"players.defenders": never;
	"players.strikers": never;
	"subheader.clubPro.video_matchs": never;
	"subheader.clubPro.team": never;
	"subheader.clubPro.calendar": never;
	"clubProCalendar.calendar.match.championship": never;
	"clubProCalendar.calendar.ranking.championship": never;
	"clubProCalendar.calendar.match.cup": never;
	"clubProCalendar.calendar.ranking.cup": never;
	"clubPro.plyers.modal.filter": never;
	"clubPro.player.header.contact": never;
	"clubPro.player.matchsTitle": never;
	"subheader.my_eyeball": never;
	"subheader.my_eyeball.matchs": never;
	"subheader.my_eyeball.clubs": never;
	"subheader.my_eyeball.teams": never;
	"subheader.my_eyeball.players": never;
	"subheader.my_eyeball.my_account": never;
	"regionMap.title": never;
	"regionMap.label.nord_ouest": never;
	"regionMap.label.nord_est": never;
	"regionMap.label.idf": never;
	"regionMap.label.centre": never;
	"regionMap.label.sud_ouest": never;
	"regionMap.label.sud_est": never;
	"my_eyeball.title": never;
	"my_eyeball.title.teams": never;
	"my_eyeball.title.matchs": never;
	"my_eyeball.title.players": never;
	"my_eyeball.title.clubs": never;
	match: never;
	clubs: never;
	teams: never;
	players: never;
	"label.match.endAtDate": never;
	"label.match.endAtTime": never;
	"label.player.type": never;
	amical: never;
	coupe: never;
	championnat: never;
	eightaside: never;
	poletest: never;
	"search.filter.content_type": never;
	"search.filter.content_type.matchs": never;
	"search.filter.content_type.clubs": never;
	"search.filter.content_type.equipes": never;
	"search.filter.content_type.joueurs": never;
	"clubHeader.contact.modal.btn": never;
	"clubHeader.contact.modal.title": never;
	"form_add_club_amateur.label.contactPosition": never;
	"form_add_club_amateur.placeholder.contactPosition": never;
	"form_add_club_amateur.placeholder.comments": never;
	"form_add_club_amateur.label.comments": never;
	"form_add_club_amateur.label.contractStartDate": never;
	"form_add_club_amateur.placeholder.contractStartDate": never;
	"formDocuments.empty": never;
	"formDocuments.remove": never;
	"formDocuments.see": never;
	"formDocuments.modal.remove.title": {
		Datei: string;
	};
	"formDocuments.modal.confirm_remove": never;
	"videos.modal.edit_match.delete": never;
	"videos.modal.edit_match.submit": never;
	"search.placeholder": never;
	"search_no_result.clubs": never;
	"search_no_result.nonpartnerclubs": never;
	"search_no_result.teams": never;
	"search_no_result.players": never;
	"search_no_result.matchs": never;
	"search_no_result.my_eyeball.clubs": never;
	"search_no_result.my_eyeball.teams": never;
	"search_no_result.my_eyeball.players": never;
	"search_no_result.my_eyeball.matchs": never;
	"home.club_pro.last_matchs": never;
	"home.club_pro.show_all_matchs": never;
	"home.club_pro.show_all_clubs": never;
	"home.club_pro.search_clubs": never;
	"home.club_pro.search_players": never;
	"home.club_pro.my_eyeball": never;
	"home.club_pro.nb_clubs_amat": {
		count: number;
	};
	"home.club_pro.nb_players_amat": {
		count: number;
	};
	"home.club_pro.nb_clubs": {
		count: number;
	};
	"home.club_pro.nb_players": {
		count: number;
	};
	"home.club_pro.nb_teams": {
		count: number;
	};
	"home.club_pro.nb_matchs": {
		count: number;
	};
	"home.club_pro.show_clubs": never;
	"home.club_pro.show_players": never;
	"home.club_pro.show_profils": never;
	"home.club_pro.player_to_watch": never;
	"select2.close_dropdown": never;
	"home.club_pro.game_to_watch": never;
	"home_not_logged.slide1.text": never;
	"home_not_logged.slide2.text": never;
	"home_not_logged.slide3.text": never;
	"home_not_logged.title1": never;
	"home_not_logged.keyblock1": never;
	"home_not_logged.keyblock2": never;
	"home_not_logged.keyblock3": never;
	"home_not_logged.slide3.title2": never;
	"home_not_logged.blocknum1.num": never;
	"home_not_logged.blocknum1.text": never;
	"home_not_logged.blocknum2.num": never;
	"home_not_logged.blocknum2.text": never;
	"home_not_logged.blocknum3.num": never;
	"home_not_logged.blocknum3.text": never;
	"home_not_logged.video.title": never;
	"home_not_logged.video.text": never;
	"home_not_logged.video.learn_more": never;
	"home_not_logged.parter.title": never;
	"page_infos.block1.title": never;
	"page_infos.block1.text1": never;
	"page_infos.block1.text2": never;
	"page_infos.block2.title": never;
	"page_infos.block2.text": never;
	"page_infos.block3.title": never;
	"page_infos.block3.text": never;
	"page_infos.block4.title": never;
	"page_infos.block4.text": never;
	"page_infos.block5.title": never;
	"page_infos.block5.text": never;
	"footer.contact.title": never;
	"footer.contact.phone": never;
	"footer.copyright": never;
	"footer.legals": never;
	"login.app.role.notsupported.scout": never;
	"match.placeholder.description": never;
	"match.description.special_char_error": never;
	"match.description.success": never;
	"matchsheet.required.substitute.name": never;
	"matchsheet.required.substitute.number": never;
	"header.club_pro.upcoming_games": never;
	"error.message.no_games_found": never;
	"team.api.add.error.wrongcategory.title": never;
	"team.api.add.error.wrongcategory.content": never;
	"team.api.add.error.duplicate.title": never;
	"team.api.add.error.duplicate.content": never;
	"planmatch.api.add.error.pasttime.title": never;
	"planmatch.api.add.error.pasttime.content": never;
	"planmatch.api.add.error.nopole.title": never;
	"planmatch.api.add.error.nopole.content": never;
	"planmatch.api.add.error.comfailure.title": never;
	"form.required.validation.message": never;
	"login.form.required.validation.message": never;
	"club_amateur.planification_match.add_game_popup.date.placeholder": never;
	"planificationmatch.datatable.date.format": never;
	"email.contains.comma.validation.message": never;
	"email.invalid.validation.message": never;
	"match.player_image_allowed_mimetypes_error": never;
	"match.unknown_away_club_logo_max_size_exceed": never;
	"club_pro.club_amateur.search.placeholder": never;
	"club_pro.club_amateur.search.error.invalid_data": never;
	"app.coach.main.app_title": never;
	"app.coach.main.become_customer_url": never;
	"app.coach.text.ok": never;
	"app.coach.text.sorry": never;
	"app.coach.text.retry": never;
	"app.coach.error.general": never;
	"app.coach.error.timeout": never;
	"app.coach.error.validate.email": never;
	"app.coach.error.validate.min_length": never;
	"app.coach.error.validate.lowercase_alpha_num": never;
	"app.coach.error.validate.empty": never;
	"app.coach.error.validate.number": never;
	"app.coach.error.validate.range": never;
	"app.coach.error.validate.positive_number": never;
	"app.coach.error.validate.no_string": never;
	"app.coach.error.validate.prefix": never;
	"app.coach.login.title": never;
	"app.coach.login.email_hint": never;
	"app.coach.login.password_hint": never;
	"app.coach.login.login_action": never;
	"app.coach.login.forgot_password": never;
	"app.coach.login.become_customer_title": never;
	"app.coach.login.become_customer_action": never;
	"app.coach.login.error": never;
	"app.coach.forgot_password.email_hint": never;
	"app.coach.forgot_password.send_action": never;
	"app.coach.forgot_password.sent_title": never;
	"app.coach.forgot_password.sent_message": never;
	"app.coach.home.my_club": never;
	"app.coach.home.squads": never;
	"app.coach.home.game_program": never;
	"app.coach.home.user_logout": never;
	"app.coach.my_club.teams_hint": never;
	"app.coach.my_club.matches_title": never;
	"app.coach.my_club.no_matches": never;
	"app.coach.squad.no_players_in_team": never;
	"app.coach.squad.player_name": never;
	"app.coach.squad.add_player_title": never;
	"app.coach.squad.add_player_first_name_title": never;
	"app.coach.squad.add_player_first_name_hint": never;
	"app.coach.squad.add_player_last_name_title": never;
	"app.coach.squad.add_player_last_name_hint": never;
	"app.coach.squad.add_player_birth_date_title": never;
	"app.coach.squad.add_player_nationality_1_title": never;
	"app.coach.squad.add_player_nationality_1_hint": never;
	"app.coach.squad.add_player_nationality_2_title": never;
	"app.coach.squad.add_player_nationality_2_hint": never;
	"app.coach.squad.add_player_height_title": never;
	"app.coach.squad.add_player_height_hint": never;
	"app.coach.squad.add_player_height_unit": never;
	"app.coach.squad.add_player_weight_title": never;
	"app.coach.squad.add_player_weight_hint": never;
	"app.coach.squad.add_player_weight_unit": never;
	"app.coach.squad.add_player_strong_foot_title": never;
	"app.coach.squad.add_player_strong_foot_left": never;
	"app.coach.squad.add_player_strong_foot_right": never;
	"app.coach.squad.add_player_position_1_title": never;
	"app.coach.squad.add_player_position_1_hint": never;
	"app.coach.squad.add_player_position_2_title": never;
	"app.coach.squad.add_player_position_2_hint": never;
	"app.coach.squad.add_player_save_action": never;
	"app.coach.squad.edit_player_title": never;
	"app.coach.squad.edit_player_first_name_title": never;
	"app.coach.squad.edit_player_first_name_hint": never;
	"app.coach.squad.edit_player_last_name_title": never;
	"app.coach.squad.edit_player_last_name_hint": never;
	"app.coach.squad.edit_player_position_1_title": never;
	"app.coach.squad.edit_player_position_1_hint": never;
	"app.coach.squad.edit_player_position_2_title": never;
	"app.coach.squad.edit_player_position_2_hint": never;
	"app.coach.squad.edit_player_save_action": never;
	"app.coach.squad.add_player_photo_title": never;
	"app.coach.squad.add_squad_title": never;
	"app.coach.squad.add_squad_category_title": never;
	"app.coach.squad.add_squad_category_hint": never;
	"app.coach.squad.add_squad_name_title": never;
	"app.coach.squad.add_squad_name_hint": never;
	"app.coach.squad.add_squad_save_action": never;
	"app.coach.squad.add_squad_not_selected_category": never;
	"app.coach.game_program.add_new_title": never;
	"app.coach.game_program.add_home_team_title": never;
	"app.coach.game_program.add_home_team_hint": never;
	"app.coach.game_program.add_home_team_error": never;
	"app.coach.game_program.add_away_team_title": never;
	"app.coach.game_program.add_away_team_hint": never;
	"app.coach.game_program.add_type_title": never;
	"app.coach.game_program.add_type_hint": never;
	"app.coach.game_program.add_type_error": never;
	"app.coach.game_program.add_date_title": never;
	"app.coach.game_program.add_time_title": never;
	"app.coach.game_program.add_pole_title": never;
	"app.coach.game_program.add_pole_hint": never;
	"app.coach.game_program.add_save": never;
	"app.coach.game.highlight.validate.title": never;
	"app.coach.game.highlight.validate.title_max": never;
	"app.coach.game.highlight.validate.note": never;
	"app.coach.game.highlight.validate.timestamp": never;
	"app.coach.game.highlight.success": never;
	"app.coach.filter_team_title": never;
	"app.coach.filter_game_type_title": never;
	"app.coach.filter_dates_title": never;
	"app.coach.match_filter_finished": never;
	"app.coach.match_filter_upcoming": never;
	"app.coach.result_loading": never;
	"app.coach.result_pull_up": never;
	"app.coach.result_release": never;
	"app.coach.squad.add_player_photo_source_gallery": never;
	"app.coach.squad.add_player_photo_source_camera": never;
	"app.coach.game_program.modify_score_title": never;
	"app.coach.game_program.modify_score_save": never;
	"app.coach.game_program.no_results": never;
	"app.coach.game_program.match_add_score": never;
	"label.team.pole_id": never;
	"home_not_logged.keyblock3.playerText": never;
	"app.coach.game.score.validate.score": never;
	"app.coach.game.score.validate.range": never;
	"videos.table.th.category": never;
	"admin.club.validate.character.clubname": never;
	"admin.club.validate.character.clubid": never;
	"admin.club.validate.character.lastname": never;
	"admin.club.validate.character.firstname": never;
	"admin.club.validate.length.clubname": never;
	"admin.club.validate.length.clubid": never;
	"admin.club.validate.length.firstname": never;
	"admin.club.validate.length.lastname": never;
	"game.tab.full_match_video": never;
	"game.tab.player_stats": never;
	"game.stats.menu.summary": never;
	"game.stats.menu.offensive": never;
	"game.stats.menu.defensive": never;
	"game.stats.menu.distribution": never;
	"game.stats.menu.goalkeeper": never;
	"game.stats.menu.label": never;
	"game.stats.table.playerName": never;
	"game.stats.table.position": never;
	"game.stats.table.rating": never;
	"game.stats.table.minutesPlayed": never;
	"game.stats.table.goalsScrored": never;
	"game.stats.table.assists": never;
	"game.stats.table.offsides": never;
	"game.stats.table.shots": never;
	"game.stats.table.freekicks": never;
	"game.stats.table.corners": never;
	"game.stats.table.throwIns": never;
	"game.stats.table.fouls": never;
	"game.stats.table.yellowCards": never;
	"game.stats.table.redCards": never;
	"game.stats.table.goalsConceded": never;
	"game.stats.table.catches": never;
	"game.stats.table.punches": never;
	"game.stats.table.goalsKicksSuccess": never;
	"game.stats.table.goalsKicksTotal": never;
	"game.stats.table.aerialClearancesSuccess": never;
	"game.stats.table.aerialClearancesTotal": never;
	"game.stats.table.passesSuccess": never;
	"game.stats.table.passesTotal": never;
	"game.stats.table.passAccuracy": never;
	"game.stats.table.keyPasses": never;
	"game.stats.table.passesInFinalThirdSuccess": never;
	"game.stats.table.passesInFinalThirdTotal": never;
	"game.stats.table.passesInMiddleThirdSuccess": never;
	"game.stats.table.passesInMiddleThirdTotal": never;
	"game.stats.table.passesInDefensiveThirdSuccess": never;
	"game.stats.table.passesInDefensiveThirdTotal": never;
	"game.stats.table.longPassesSuccess": never;
	"game.stats.table.longPassesTotal": never;
	"game.stats.table.mediumRangePassesSuccess": never;
	"game.stats.table.mediumRangePassesTotal": never;
	"game.stats.table.shortPassesSuccess": never;
	"game.stats.table.shortPassesTotal": never;
	"game.stats.table.passesForwardSuccess": never;
	"game.stats.table.passesForwardTotal": never;
	"game.stats.table.passesSidewaysSuccess": never;
	"game.stats.table.passesSidewaysTotal": never;
	"game.stats.table.passesBackwardSuccess": never;
	"game.stats.table.passesBackwardTotal": never;
	"game.stats.table.crossesSuccess": never;
	"game.stats.table.crossesTotal": never;
	"game.stats.table.controlUnder": never;
	"game.stats.table.shotsOnTarget": never;
	"game.stats.table.blockedShots": never;
	"game.stats.table.shotsOffTarget": never;
	"game.stats.table.shotsInsidePa": never;
	"game.stats.table.shotsOutsidePa": never;
	"game.stats.table.takeOnsSuccessful": never;
	"game.stats.table.takeOnsTotal": never;
	"game.stats.table.tacklesSuccess": never;
	"game.stats.table.tacklesTotal": never;
	"game.stats.table.aerialDuelsSuccess": never;
	"game.stats.table.aerialDuelsTotal": never;
	"game.stats.table.groundDuelsSuccess": never;
	"game.stats.table.groundDuelsTotal": never;
	"game.stats.table.interceptions": never;
	"game.stats.table.clearances": never;
	"game.stats.table.interventions": never;
	"game.stats.table.recoveries": never;
	"game.stats.table.blocks": never;
	"game.stats.table.mistakes": never;
	"game.stats.table.foulsWon": never;
	"videos.score.success": never;
	"game.stats.table.no": never;
	"game.stats.download.csv": never;
	"game.tab.team_stats": never;
	"game.stats.see_more": never;
	"game.stats.see_less": never;
	"game.stats.table.goals": never;
	"game.stats.table.possession": never;
	"game.stats.table.freeKicks": never;
	"game.stats.table.averageRating": never;
	"enum.geographical_area.north_west": never;
	"enum.geographical_area.north_east": never;
	"enum.geographical_area.ile_de_france": never;
	"enum.geographical_area.center": never;
	"enum.geographical_area.south_west": never;
	"enum.geographical_area.south_est": never;
	"all.enum.geographical.center": never;
	"all.enum.geographical.north_west": never;
	"all.enum.geographical.north_east": never;
	"all.enum.geographical.south_west": never;
	"all.enum.geographical.south_est": never;
	"all.enum.geographical.ile_de_france": never;
	"matchsheet.player.goal_exist": never;
	"videos.table.th.stats": never;
	"match.highlight.error.edit.unauthorized": never;
	"continent.africa": never;
	"continent.antartica": never;
	"continent.asia": never;
	"continent.europe": never;
	"continent.north_america": never;
	"continent.oceania": never;
	"continent.south_america": never;
	"club.non_partner": never;
	"club.non_partner_player": never;
	"country.selection.success.message": never;
	"country.selection.save.btn": never;
	"country.selection.selected.countries": never;
	"country.selection.choose.country": never;
	"date.format.d-LLLL-Y": never;
	"date.format.d-Y-LLLL": never;
	"date.format.Y-d-LLLL": never;
	"date.format.Y-LLLL-d": never;
	"date.format.LLLL-d-Y": never;
	"date.format.LLLL-Y-d": never;
	"date-format.Y-m-d-H:i": never;
	"date.format.Y-m-d": never;
	"date.format.Y-d-m": never;
	"date.format.m-d-Y": never;
	"date.format.m-Y-d": never;
	"date.format.d-m-Y": never;
	"date.format.d-Y-m": never;
	"date.format.Y-m-d-H:i:s": never;
	"date.format.d-m-Y-H:i": never;
	"date.format.d/m/Y": never;
	"date.format.dd/MM/yyyy": never;
	"date.format.d LLLL y": never;
	"match.team.player_action": never;
	"terms.agree": never;
	"terms.disagree": never;
	"countries.selection.text": never;
	"label.match.national": never;
	"player.validate.length.notes": never;
	"admin.club.validate.character.contactposition": never;
	"admin.club.validate.length.position": never;
	"game.not.found": never;
	"enum.position.GK": never;
	"enum.position.RB": never;
	"enum.position.CB": never;
	"enum.position.LB": never;
	"enum.position.RWB": never;
	"enum.position.LWB": never;
	"enum.position.CDM": never;
	" enum.position.RM": never;
	"enum.position.LM": never;
	"enum.position.CM": never;
	"enum.position.RW": never;
	"enum.position.LW": never;
	"enum.position.CAM": never;
	"enum.position.RF": never;
	"enum.position.LF": never;
	"enum.position.CF": never;
	"enum.position.GK.key": never;
	"enum.position.RB.key": never;
	"enum.position.LB.key": never;
	"enum.position.CB.key": never;
	"enum.position.RWB.key": never;
	"enum.position.LWB.key": never;
	"enum.position.CDM.key": never;
	"enum.position.RM.key": never;
	"enum.position.LM.key": never;
	"enum.position.CM.key": never;
	"enum.position.RW.key": never;
	"enum.position.LW.key": never;
	"enum.position.CAM.key": never;
	"enum.position.RF.key": never;
	"enum.position.LF.key": never;
	"enum.position.CF.key": never;
	"match.aside.tab.highlights": never;
	"tooltip.default": never;
	"select.disabled.countries": never;
	"tooltip.comingsoon": never;
	"matchsheet.team.goal_exist": never;
	"eyeball.something.wrong": never;
	"videos.table.th.country": never;
	"delete.game.success.message": never;
	"product.management.nonpartner.datatset": never;
	"product.management.nonpartner.players": never;
	"product.management.nonpartner.clubs": never;
	"product.management.poles": never;
	"product.management.bundles": never;
	"product.management.notifications": never;
	"product.management.specific.notifications": never;
	"product.management.schedule.notifications": never;
	"product.management.admin.user.management": never;
	"product.management.admin.role.permission": never;
	"product.management.country.expansion": never;
	"product.management.feed.area": never;
	"product.management.player.account": never;
	"permission.read": never;
	"permission.create": never;
	"permission.delete": never;
	"permission.edit": never;
	"permission.set_permissions": never;
	"permission.migrate_to_nonpartner": never;
	"permission.migrate_to_partner": never;
	"permission.convert_to_partner": never;
	"permission.publish": never;
	"permission.assign_pole": never;
	"resource.product_management": never;
	"resource.poles": never;
	"resource.non_partner_clubs": never;
	"resource.non_partner_players": never;
	"resource.roles_permissions_management": never;
	"resource.admins_creation": never;
	"resource.bundles": never;
	"resource.notifications": never;
	"resource.countries_expansion": never;
	"resource.player_accounts_management": never;
	"resource.feed_area": never;
	"admin.user.role.creation": never;
	"admin.user.role.updation": never;
	"admin.user.deletion": never;
	"admin.role.creation": never;
	"admin.role.updation": never;
	"admin.role.permission.assign": never;
	"admin.role.deletion.permission.unassign": never;
	"product.management.admin.user.creation": never;
	"admin.role.restrict.delete": never;
	"admin.role.name.exists": never;
	"photo.upload.success": never;
	"pole.add.success": never;
	"pole.update.success": never;
	"pole.delete.success": never;
	"pole.assign.success": never;
	"pole.assign.error": never;
	"error.bundle.not_unique": never;
	"admin.bundle.creation": never;
	"admin.bundle.updation": never;
	"admin.subscription.deletion": never;
	"admin.pole.unique": never;
	"admin.pole.assigned.pole1": never;
	"admin.pole.assigned.pole2": never;
	"admin.pole.assigned.pole3": never;
	"error.nonPartnerClub.not_unique": never;
	"admin.nonPartnerClub.creation": never;
	"admin.nonPartnerClub.updation": never;
	"admin.nonPartnerClub.delete": never;
	"error.date.mismatch": never;
	"permission.assign_bundle": never;
	"admin.assign.subscription.club": never;
	"admin.unassign.subscription.club": never;
	"btn.save": never;
	"admin.nonPartnerPlayer.creation": never;
	"admin.nonPartnerPlayer.updation": never;
	"error.nonPartnerPlayer.unique": never;
	"admin.nonPartnerPlayer.delete": never;
	"label.action": never;
	"label.user.role": never;
	"label.user.account": never;
	"label.admin.users": never;
	"label.switch": never;
	"label.user.add": never;
	"label.role.add": never;
	"label.timezone": never;
	"label.location": never;
	"label.current_battery": never;
	"label.assigned_club": never;
	"label.sync": never;
	"label.assign": never;
	"label.assigned": never;
	"label.pole_management": never;
	"label.add.pole": never;
	"label.assign.pole.club": never;
	"label.assign_into": never;
	"label.serial_number": never;
	"label.bundles_management": never;
	"label.max_accounts": never;
	"label.countries": never;
	"label.add.bundle": never;
	"label.start_date": never;
	"label.expiration_date": never;
	"label.assign.bundle.club": never;
	"message.select_country": never;
	"message.select_game_options": never;
	"label.bundle": never;
	"label.form.start_date": never;
	"label.form.expiration_date": never;
	"label.pro_club": never;
	"label.game_options": never;
	"label.AI": never;
	"label.statistics_rating": never;
	"label.statistics_player": never;
	"label.statistics_team": never;
	"label.events": never;
	"label.search": never;
	"label.migrate": never;
	"label.unassign": never;
	"logo.upload.success": never;
	"pole.unassign.success": never;
	"pole.unassign.error": never;
	"form.club.edit_team.success": never;
	"clubAmateur.teams.button.edit_team": never;
	"migration.already.exists": never;
	"migration.validation.message.select_source_club": never;
	"migration.validation.message.select_target_club": never;
	"btn.execute_migration": never;
	"migration.btn.skip": never;
	"migration.btn.merge": never;
	"migration.btn.retain": never;
	"migration.players_migration_process": never;
	"migration.players_successfully_migrated": never;
	"migration.games_successfully_migrated": never;
	"migration.players_failed_while_migration": never;
	"migration.games_failed_while_migration": never;
	"migration.btn.more_details": never;
	"tooltip.purchase.country": never;
	"tooltip.purchase.country.discount.five": never;
	"tooltip.purchase.country.discount.ten": never;
	"delete.confirmation.are_you_sure": never;
	"delete.confirmation.are_you_sure_want_to_delete": {
		title: string;
	};
	"delete.confirmation.yes_delete_it": never;
	"club.non_partner_club.games": never;
	"club.non_partner.label.country": never;
	"club.non_partner.label.games": never;
	"club.non_partner.label.teams": never;
	"club.non_partner.label.players": never;
	"club.non_partner.label.comments": never;
	"club.non_partner.view.title": never;
	"club.non_partner.label.createdAt": never;
	"club.non_partner.label.createdBy": never;
	"club.non_partner.label.updatedAt": never;
	"club.non_partner.label.updatedBy": never;
	"message.migrate_player_club.success": {
		lastname: string;
		firstname: string;
	};
	"message.migrate_player_club.error": never;
	"player.migrate_player.button": never;
	"label.match.homeClub": never;
	"label.showcase": never;
	"form.club.match_sheet.players.autoload.success": never;
	"scout.player.moved_player_text": never;
	"scout.player.profile_club_title": never;
	"form.error.description-length-exceed": never;
	"form.error.name-length-exceed": never;
	"form.validation.phone-number-length-exceed": never;
	"form.validation.email-length-exceed": never;
	"form.validation.address-length-exceed": never;
	"form.validation.city-length-exceed": never;
	"admin.sourceclub.migrate.delete": never;
	"label.product": never;
	"resource.clubs_amateur": never;
	"resource.club_amateur_users": never;
	"resource.club_amateur_user_directors": never;
	"resource.club_amateur_user_coachs": never;
	"resource.club_amateur_teams": never;
	"resource.club_amateur_team_players": never;
	"resource.club_amateur_games": never;
	"resource.game_uploads": never;
	"resource.clubs_pro": never;
	"resource.clubs_pro_users": never;
	"permission.list": never;
	"permission.game_vip": never;
	"permission.game_hidden": never;
	"permission.game_view": never;
	"permission.game_score": never;
	"permission.game_teamsheet": never;
	"label.add.subscription": never;
	"label.subscriptions_management": never;
	"label.assign.subscription.club": never;
	"label.subscription": never;
	"product.management.subscriptions": never;
	"label.download": never;
	"label.club_name": never;
	"label.contact_name": never;
	"label.contact_phone": never;
	"label.contact_email": never;
	"form.validation.please_select_timezone": never;
	"videos.analyse.success": never;
	"header.admin.product_management": never;
	"team.deleted.successfully": never;
	"message.edit_player_club.success": {
		lastname: string;
		firstname: string;
	};
	"player.already.exists": never;
	"label.subscription.comment": never;
	"admin.enabled.success": never;
	"admin.disabled.success": never;
	"admin.country.delete": never;
	"admin.country.updation": never;
	"admin.country.creation": never;
	"error.country.not_unique": {
		land: string;
	};
	"admin.npc.games": never;
	"admin.npc.players": never;
	"scout.subscription.popup_message": never;
	"club.status.missing": never;
	"btn.add_player": never;
	"btn.add_team": never;
	"btn.filter_by_team": never;
	"btn.club_teams": never;
	"btn.assign_coach_to_team": never;
	"btn.edit_team": never;
	"label.similar_player_already_exists": never;
	"message.no_players_available": never;
	"message.no_players_in_team": never;
	"permission.test_email": never;
	"resource.specific_notiifcations": never;
	"resource.schedule_notifications": never;
	"game.player.delete.restrict": never;
	"notification.sent.successfully": never;
	"error.message.select_category": never;
	"label.notification_management": never;
	"error.message.select_nationality": never;
	"error.message.select_team": never;
	"scout.not_subscription.popup_message": never;
	"admin.program_game.away_club.message": never;
	"admin.program_game.home_team.message": never;
	"label.language": never;
	"label.date/time": never;
	"label.method": never;
	"label.delivered": never;
	"message.message_copied": never;
	"label.author": never;
	"label.methods": never;
	"label.retry": never;
	"label.detail": never;
	"label.failed": never;
	"label.success": never;
	"label.add_notification": never;
	"label.notification": never;
	"message.please_select_method": never;
	"message.all_message_fields_required": never;
	"message.message_content_required": never;
	"label.receivers": never;
	"label.individual_user": never;
	"label.accounts": never;
	"resource.subscriptions": never;
	"permission.assign_subscription": never;
	"phoneNumber.not.exists": never;
	"email.not.exists": never;
	"label.tooltip_message": never;
	"label.enabled": never;
	"label.countries.listing": never;
	"label.add": never;
	"label.Please_select_country": never;
	"error.message.select_position": never;
	"label.enable": never;
	"admin.program_game.away_team.message": never;
	"admin.program_game.home_club.message": never;
	"label.add_country": never;
	"label.update_country": never;
	"label.program_game": never;
	"message.please_select_amateurclubs": never;
	"message.please_select_proclubs": never;
	"message.please_select_receivers": never;
	"select.missed.locales": {
		missedLocales: string;
	};
	"label.signup_to_eyeball": never;
	"label.email_address": never;
	"label.mobile_number": never;
	"label.club": never;
	"label.role": never;
	"label.signup": never;
	"label.or_signup_with": never;
	"label.login_to_eyeball": never;
	"label.dont_have_account": never;
	"label.register": never;
	"label.google": never;
	"label.microsoft": never;
	"label.refresh": never;
	"btn.filter_by_coach": never;
	"job.refresh.success": never;
	"favorite.remove.club": never;
	"favorite.add.club": never;
	"favorite.remove.match": never;
	"favorite.add.match": never;
	"favorite.remove.player": never;
	"favorite.add.player": never;
	"favorite.remove.team": never;
	"favorite.add.team": never;
	"label.destination": never;
	"label.as_your_selected": never;
	"label.message_title _reached_maximum_length": never;
	"test.notification.sent": never;
	"label.filter_by_event(s)": never;
	"label.select_all": never;
	"gameAnalysis.add.success": never;
	"gameAnalysis.update.success": never;
	"gameAnalysis.exists": never;
	"enum.geographical.ile_de_france": never;
	"enum.geographical.south_est": never;
	"enum.geographical.south_west": never;
	"enum.geographical.north_east": never;
	"enum.geographical.north_west": never;
	"enum.geographical.center": never;
	"label.user.region": never;
	"label.player.geographicalArea": never;
	"club.non-partner": never;
	"message.error_download_link": never;
	"message.not_allowed_to_download": never;
	"admin.club.validate.address": never;
	"admin.club.validate.zipcode": never;
	"admin.club.validate.city": never;
	"admin.club.validate.fill.required.fields": never;
	"admin.club.validate.character.PhoneNumber": never;
	"admin.club.validate.character.contactEmail": never;
	"admin.club.validate.access": never;
	"picture.delete.success": never;
	"label.profile_photo": never;
	"profile.phone.missing": never;
	"form.validation.phone.wrong_format": never;
	"label.bepro": never;
	"label.physical_statistics": never;
	"label.first_half": never;
	"label.start_time": never;
	"label.end_time": never;
	"label.second_half": never;
	"label.analyse": never;
	"title.add_game": never;
	"label.my_profile": never;
	"label.subheader_my_subscription": never;
	"label.unselect_all_countries": never;
	"label.select_all_countries": never;
	"text.dynamic.club.10306.description": never;
	"text.dynamic.club.10424.description": never;
	"text.dynamic.club.10401.description": never;
	"text.dynamic.club.10556.description": never;
	"label.my_subscriptions": never;
	"label.subscriptions.expired": never;
	"label.subscriptions.tooltip": never;
	"label.game_option_review": never;
	"label.game_option_players": never;
	"label.game_option_team": never;
	"label.game_option_events": never;
	"label.game_option_download": never;
	"label.delete_account.check_2": never;
	"label.delete_account.permanent": never;
	"text.dynamic.club.10664.description": never;
	"text.dynamic.club.10562.description": never;
	"user.no.longer": never;
	"label.create_clip_from_selectedevent_types": never;
	"label.create_clip": never;
	"label.enter_the_clip_name": never;
	"label.video_clip_name": never;
	"label.share": never;
	"label.rename": never;
	"label.manual": never;
	"label.share_the_clip": never;
	"label.clip_link(url)": never;
	"label.link_expire_date": never;
	"label.set_expiration_date_disable_Link_after_this_date": never;
	"label.password_protect": never;
	"label.by_adding_password_you_can_restrict_access": never;
	"label.enter_the_clip_url": never;
	"label.enter_the_password": never;
	"label.play_video": never;
	"message.clip_name_changed_successfully": never;
	"text.dynamic.club.10637.description": never;
	"text.dynamic.club.10558.description": never;
	"text.dynamic.club.10638.description": never;
	"label.delete_account.placeholder": never;
	"label.datagrid.club_exists": never;
	"label.datagrid.club_exists_2": never;
	"label.datagrid.club_no_match": never;
	"label.button.proceed": never;
	"label.migrate.non_partner": never;
	"label.player_to": never;
	"label.to": never;
	"label.club_to": never;
	"label.partner": never;
	"label.migration": never;
	"migration.error.close_modal": never;
	"migration.are_you_sure": never;
	"button.migration.confirm": never;
	"game.clip.add.success": never;
	"game.clip.delete.success": never;
	"text.dynamic.club.10724.description": never;
	"game.clip.already.exists": never;
	"migration.note.1": never;
	"migration.note.2": never;
	"migration.note.3": never;
	"migration.placeholder.team": never;
	"label.migration.player_skipped": never;
	"label.notofications.message": never;
	"label.notofications.body": never;
	"label.placeholder.products": never;
	"max.clip.exceeded": never;
	"game.clip.overwrite.success": never;
	"game.clip.replace.success": never;
	"label.overwrite": never;
	"label.can_either_replace_last_clip_by_or_not_continue": never;
	"label.replace_it": never;
	"text.dynamic.club.10689.description": never;
	"text.dynamic.club.10612.description": never;
	"text.dynamic.club.9963.description": never;
	"text.dynamic.club.10339.description": never;
	"text.dynamic.club.10589.description": never;
	"text.dynamic.club.10587.description": never;
	"text.dynamic.club.10619.description": never;
	"label.clips": never;
	"text.dynamic.player.17731.notes": never;
	"restore.my.account": never;
	"email.restore.my.account.please.click": never;
	"birthyear.range.validate": {
		startYear: string;
		endYear: string;
	};
	"type.three.or.more.characters.validate": never;
	"contact.support.agent": never;
	"player.validate.phoneNumberFormat": never;
	"message.restore.my.account.mail.sent": never;
	"label.notification_modal.test": never;
	"message.restore.my.account.mail.failure": never;
	"label.no_longer.email_sent": never;
	"label.btn.back_to_login": never;
	"club.not.exists": never;
	"account.player.registered": never;
	"text.dynamic.club.8403.description": never;
	"text.dynamic.club.8405.description": never;
	"text.dynamic.club.8526.description": never;
	"text.dynamic.club.8668.description": never;
	"text.dynamic.club.8821.description": never;
	"text.dynamic.club.10789.description": never;
	"player.validate.userName": never;
	"player.validate.userName_string": never;
	"player.validate.password": never;
	"player.validate.password_string": never;
	"player.validate.playerId": never;
	"player.validate.playerId_int": never;
	"text.dynamic.club.10198.description": never;
	"text.dynamic.club.10829.description": never;
	"continents.northAmerica": never;
	"continents.southAmerica": never;
	"continents.africa": never;
	"continents.asia": never;
	"continents.europe": never;
	"continents.oceania": never;
	"continents.antarctica": never;
	"label.subscription.expiration_days": {
		daysToExpiration: string;
	};
	"text.dynamic.club.10882.description": never;
	"text.dynamic.club.10891.description": never;
	"text.dynamic.club.10890.description": never;
	"label.director": never;
	"terms.conditions": never;
	"text.dynamic.club.10803.description": never;
	"text.dynamic.club.10712.description": never;
	"text.dynamic.club.10547.description": never;
	"text.dynamic.club.10962.description": never;
	"text.dynamic.club.11011.description": never;
	"text.dynamic.club.10932.description": never;
	"all.enum.geographical.test": never;
	testgp: never;
	"text.dynamic.club.10911.description": never;
	"button.generate_link": never;
	"label.my_clips": never;
	"button.update_link": never;
	"your.otp.is": never;
	"label.copy_link": never;
	"message.link_copied_to_clipboard": never;
	"text.dynamic.club.9329.description": never;
	"player.validate.otp": never;
	"player.validate.otp_string": never;
	"player.validate.phoneNumber": never;
	"player.validate.phoneNumber_string": never;
	"otp.valid": never;
	"otp.expired": never;
	"otp.invalid.Credentials": never;
	"allowed.only.3.times.perday'": never;
	"text.dynamic.club.11153.description": never;
	"label.created_date": never;
	"otp.sms1.attempt": never;
	"otp.sms.2.attempt": never;
	"otp.sms.3.attempt": never;
	"otp.sms.4.attempt": never;
	"text.dynamic.club.11262.description": never;
	"text.dynamic.club.11261.description": never;
	"text.dynamic.club.11263.description": never;
	"migration.game-edit-status-note": never;
	"text.dynamic.player.13443.notes": never;
	"label.assign_team": never;
	"text.dynamic.club.11333.description": never;
	"label.date_time": never;
	"label.migrated_players": never;
	"label.migrated_games": never;
	"text.dynamic.club.10218.description": never;
	"text.dynamic.club.10219.description": never;
	"text.dynamic.club.9969.description": never;
	"text.dynamic.club.9622.description": never;
	"text.dynamic.club.9485.description": never;
	"text.dynamic.club.9434.description": never;
	"text.dynamic.club.9428.description": never;
	"text.dynamic.club.9432.description": never;
	"text.dynamic.club.9433.description": never;
	"text.dynamic.club.9427.description": never;
	"text.dynamic.club.9426.description": never;
	"text.dynamic.club.9425.description": never;
	"text.dynamic.club.9424.description": never;
	"text.dynamic.club.8204.description": never;
	"text.dynamic.club.7675.description": never;
	"text.dynamic.club.7582.description": never;
	"text.dynamic.club.7585.description": never;
	"text.dynamic.club.7510.description": never;
	"text.dynamic.club.7313.description": never;
	"text.dynamic.club.7123.description": never;
	"text.dynamic.club.7124.description": never;
	"text.dynamic.club.7103.description": never;
	"text.dynamic.club.7104.description": never;
	"text.dynamic.club.7466.description": never;
	"text.dynamic.club.7105.description": never;
	"text.dynamic.club.6763.description": never;
	"text.dynamic.club.6805.description": never;
	"text.dynamic.club.6849.description": never;
	"text.dynamic.club.9456.description": never;
	"text.dynamic.club.9455.description": never;
	"text.dynamic.club.9454.description": never;
	"text.dynamic.club.9453.description": never;
	"text.dynamic.club.8205.description": never;
	"text.dynamic.club.7676.description": never;
	"text.dynamic.club.7583.description": never;
	"text.dynamic.club.7511.description": never;
	"text.dynamic.club.9457.description": never;
	"text.dynamic.club.9458.description": never;
	"text.dynamic.club.9459.description": never;
	"text.dynamic.club.9460.description": never;
	"text.dynamic.club.9537.description": never;
	"text.dynamic.club.9614.description": never;
	"text.dynamic.club.9829.description": never;
	"text.dynamic.club.9900.description": never;
	"text.dynamic.club.9901.description": never;
	"text.dynamic.club.7315.description": never;
	"edit.matchsheet.validate": never;
	"scout.menu.u17.u19": never;
	"club.not.found": never;
	"error.phoneNumber.not_unique": never;
	"validate.notblank": never;
	"validate.type.boolean": never;
	"player.validate.club": never;
	"player.validate.club_int": never;
	"player.playedInNationalTeam": never;
	"scout.menu.national_leagues": never;
	"label.ownership": never;
	"label.expires_in": never;
	"admin.program_game.ownership.message": never;
	"user.not.assigned.club": never;
	"title.link_expired": never;
	"msg.link_expired": never;
	"label.content_protected": never;
	"label.to_view_enter_password": never;
	"ownership-restrictions-alert": {
		nonOwnerShipGamesCount: string;
	};
	"player.validate.email_string": never;
	"otp.sent": never;
	"otp.sms.1.attempt": never;
	"user-contact-sales-team": never;
	"msg.clip_ready": never;
	"msg.team_sheet_not_completed ": never;
	"msg.select_country": never;
	"form_search_player_game.category": never;
	"all.enum.geographical.north": never;
	"all.enum.geographical.east": never;
	"all.enum.geographical.west": never;
	"all.enum.geographical.south": never;
	"label.select": never;
	"search_no_result.my_eyeball.clips": never;
	"label.password.clip.wrong_format": never;
	"game.clip.add.failed": never;
	"phoneNumber.validation.error.invalidCountryCode": never;
	"label.match.category": never;
	"prod.text.dynamic.player.13021.notes": never;
	"prod.text.dynamic.game.13878.description": never;
	"prod.text.dynamic.player.10400.notes": never;
	"prod.text.dynamic.player.10407.notes": never;
	"prod.text.dynamic.player.10718.notes": never;
	"prod.text.dynamic.game.13875.description": never;
	"prod.text.dynamic.game.13904.description": never;
	"prod.text.dynamic.game.13879.description": never;
	"prod.text.dynamic.game.13876.description": never;
	"prod.text.dynamic.game.13880.description": never;
	"prod.text.dynamic.game.13948.description": never;
	"message.clip_link_updated": never;
	"prod.text.dynamic.game.13962.description": never;
	"prod.text.dynamic.game.13947.description": never;
	"prod.text.dynamic.game.13988.description": never;
	"prod.text.dynamic.game.13984.description": never;
	"prod.text.dynamic.game.13987.description": never;
	"prod.text.dynamic.player.19747.notes": never;
	"prod.text.dynamic.game.13961.description": never;
	"prod.text.dynamic.game.13963.description": never;
	"prod.text.dynamic.game.13335.description": never;
	"prod.text.dynamic.game.13336.description": never;
	"prod.text.dynamic.game.13283.description": never;
	"prod.text.dynamic.game.13992.description": never;
	"prod.text.dynamic.game.13264.description": never;
	"prod.text.dynamic.player.23795.notes": never;
	"prod.text.dynamic.player.23127.notes": never;
	"prod.text.dynamic.game.13261.description": never;
	"prod.text.dynamic.game.13280.description": never;
	"prod.text.dynamic.game.14000.description": never;
	"prod.text.dynamic.game.13997.description": never;
	"prod.text.dynamic.game.13877.description": never;
	"prod.text.dynamic.game.14013.description": never;
	"prod.text.dynamic.game.13995.description": never;
	"prod.text.dynamic.game.13881.description": never;
	"prod.text.dynamic.game.14015.description": never;
	"prod.text.dynamic.game.14014.description": never;
	"prod.text.dynamic.game.14016.description": never;
	"prod.text.dynamic.game.14020.description": never;
	"prod.text.dynamic.game.14019.description": never;
	"prod.text.dynamic.game.14018.description": never;
	"prod.text.dynamic.game.14017.description": never;
	"btn.filter_by_club": never;
	"label.biography": never;
	"prod.text.dynamic.club.5208.biography": never;
	"prod.text.dynamic.player.21775.notes": never;
	"prod.text.dynamic.game.14054.description": never;
	"prod.text.dynamic.player.23610.notes": never;
	"prod.text.dynamic.player.23618.notes": never;
	"prod.text.dynamic.player.23621.notes": never;
	"label.player.dob": never;
	"prod.text.dynamic.player.23609.notes": never;
	"prod.text.dynamic.game.14062.description": never;
	"scout.menu.u17": never;
	"scout.menu.u19": never;
	"scout.menu.n2": never;
	"scout.menu.n3": never;
	"scout.menu.national": never;
	"scout.menu.league": never;
	"filter.daterange.season": never;
	"label.pagination.page": never;
	"label.pagination.of": never;
	"label.pagination.go.to.page": never;
	"label.pagination.show": never;
	"enter.role.name": never;
	"search.name": never;
	"search.club": never;
	"label.title": never;
	"prod.text.dynamic.club.5209.biography": never;
	"prod.text.dynamic.player.23608.notes": never;
	"prod.text.dynamic.player.23613.notes": never;
	"prod.text.dynamic.player.23619.notes": never;
	"prod.text.dynamic.player.23623.notes": never;
	"prod.text.dynamic.player.1682.notes": never;
	"prod.text.dynamic.player.0.notes": never;
	"prod.text.dynamic.player.24947.notes": never;
	"message.shared_link_generated": never;
	"prod.text.dynamic.player.348.notes": never;
	"prod.text.dynamic.player.3565.notes": never;
	"prod.text.dynamic.player.19476.notes": never;
	"prod.text.dynamic.player.23611.notes": never;
	"prod.text.dynamic.player.23614.notes": never;
	"prod.text.dynamic.player.23622.notes": never;
	"prod.text.dynamic.player.23617.notes": never;
	"prod.text.dynamic.player.23625.notes": never;
	"prod.text.dynamic.player.15387.notes": never;
	"prod.text.dynamic.player.272.notes": never;
	"prod.text.dynamic.player.17493.notes": never;
	"prod.text.dynamic.player.25056.notes": never;
	"prod.text.dynamic.player.23616.notes": never;
	"prod.text.dynamic.club.5081.biography": never;
	"info.nodata_available": never;
	"admin.program_game.ownership.message-2": never;
	"prod.text.dynamic.club.5210.biography": never;
	"prod.text.dynamic.player.25349.notes": never;
	"prod.text.dynamic.player.25350.notes": never;
	"prod.text.dynamic.game.14478.description": never;
	"prod.text.dynamic.player.16699.notes": never;
	"prod.text.dynamic.game.14483.description": never;
	"label.downloadable_gametags": never;
	"label.ubitrack": never;
	"prod.text.dynamic.player.2686.notes": never;
	"label.download_options": never;
	"label.download_format": never;
	"label.1st_half": never;
	"prod.text.dynamic.game.14620.description": never;
	"label.2nd_half": never;
	"label.selected_events": never;
	"label.download_all": never;
	"prod.text.dynamic.club.5211.biography": never;
	"prod.text.dynamic.club.5212.biography": never;
	"prod.text.dynamic.player.10916.notes": never;
	"game.download.validate.team": never;
	"game.download.validate.download": never;
	"game.download.validate.download_string": never;
	"game.download.validate.format": never;
	"game.download.validate.format_string": never;
	"game.download.validate.event_category_string": never;
	"prod.text.dynamic.club.5213.biography": never;
	"prod.text.dynamic.club.5214.biography": never;
	"prod.text.dynamic.player.25843.notes": never;
	"prod.text.dynamic.player.25844.notes": never;
	"prod.text.dynamic.player.25845.notes": never;
	"prod.text.dynamic.player.1855.notes": never;
	"prod.text.dynamic.player.13259.notes": never;
	"prod.text.dynamic.player.21776.notes": never;
	"prod.text.dynamic.player.26009.notes": never;
	"prod.text.dynamic.player.26010.notes": never;
	"prod.text.dynamic.player.26011.notes": never;
	"prod.text.dynamic.player.26012.notes": never;
	"prod.text.dynamic.player.26013.notes": never;
	"prod.text.dynamic.player.26014.notes": never;
	"prod.text.dynamic.player.26015.notes": never;
	"prod.text.dynamic.player.26016.notes": never;
	"prod.text.dynamic.player.26017.notes": never;
	"prod.text.dynamic.player.26018.notes": never;
	"prod.text.dynamic.player.26019.notes": never;
	"prod.text.dynamic.player.26020.notes": never;
	"prod.text.dynamic.player.26021.notes": never;
	"prod.text.dynamic.player.26022.notes": never;
	"prod.text.dynamic.player.26023.notes": never;
	"prod.text.dynamic.game.14759.description": never;
	"prod.text.dynamic.player.14149.notes": never;
	"prod.text.dynamic.player.14866.notes": never;
	"msg.analyse_finished": never;
	"country.session.remove.success.message": never;
	"prod.text.dynamic.club.1.biography": never;
	"prod.text.dynamic.club.3.biography": never;
	"prod.text.dynamic.club.10.biography": never;
	"prod.text.dynamic.club.12.biography": never;
	"prod.text.dynamic.club.13.biography": never;
	"prod.text.dynamic.club.14.biography": never;
	"prod.text.dynamic.club.16.biography": never;
	"prod.text.dynamic.club.17.biography": never;
	"prod.text.dynamic.club.19.biography": never;
	"prod.text.dynamic.club.30.biography": never;
	"prod.text.dynamic.club.31.biography": never;
	"prod.text.dynamic.club.32.biography": never;
	"prod.text.dynamic.club.33.biography": never;
	"prod.text.dynamic.club.34.biography": never;
	"prod.text.dynamic.club.35.biography": never;
	"prod.text.dynamic.club.38.biography": never;
	"prod.text.dynamic.club.39.biography": never;
	"prod.text.dynamic.club.40.biography": never;
	"prod.text.dynamic.club.41.biography": never;
	"prod.text.dynamic.club.5043.biography": never;
	"prod.text.dynamic.club.5044.biography": never;
	"prod.text.dynamic.club.5045.biography": never;
	"prod.text.dynamic.club.5048.biography": never;
	"prod.text.dynamic.club.5049.biography": never;
	"prod.text.dynamic.club.5052.biography": never;
	"prod.text.dynamic.club.5053.biography": never;
	"prod.text.dynamic.club.5056.biography": never;
	"prod.text.dynamic.club.5057.biography": never;
	"prod.text.dynamic.club.5058.biography": never;
	"prod.text.dynamic.club.5060.biography": never;
	"prod.text.dynamic.club.5061.biography": never;
	"prod.text.dynamic.club.5062.biography": never;
	"prod.text.dynamic.club.5063.biography": never;
	"prod.text.dynamic.club.5064.biography": never;
	"prod.text.dynamic.club.5065.biography": never;
	"prod.text.dynamic.club.5066.biography": never;
	"prod.text.dynamic.club.5067.biography": never;
	"prod.text.dynamic.club.5068.biography": never;
	"prod.text.dynamic.club.5069.biography": never;
	"prod.text.dynamic.club.5070.biography": never;
	"prod.text.dynamic.club.5071.biography": never;
	"prod.text.dynamic.club.5072.biography": never;
	"prod.text.dynamic.club.5074.biography": never;
	"prod.text.dynamic.club.5075.biography": never;
	"prod.text.dynamic.club.5076.biography": never;
	"prod.text.dynamic.club.5078.biography": never;
	"prod.text.dynamic.club.5079.biography": never;
	"prod.text.dynamic.club.5080.biography": never;
	"prod.text.dynamic.club.5082.biography": never;
	"prod.text.dynamic.club.5083.biography": never;
	"prod.text.dynamic.club.5085.biography": never;
	"prod.text.dynamic.club.5087.biography": never;
	"prod.text.dynamic.club.5088.biography": never;
	"prod.text.dynamic.club.5089.biography": never;
	"prod.text.dynamic.club.5090.biography": never;
	"prod.text.dynamic.club.5091.biography": never;
	"prod.text.dynamic.club.5092.biography": never;
	"prod.text.dynamic.club.5093.biography": never;
	"prod.text.dynamic.club.5094.biography": never;
	"prod.text.dynamic.club.5095.biography": never;
	"prod.text.dynamic.club.5096.biography": never;
	"prod.text.dynamic.club.5097.biography": never;
	"prod.text.dynamic.club.5098.biography": never;
	"prod.text.dynamic.club.5099.biography": never;
	"prod.text.dynamic.club.5100.biography": never;
	"prod.text.dynamic.club.5101.biography": never;
	"prod.text.dynamic.club.5102.biography": never;
	"prod.text.dynamic.club.5103.biography": never;
	"prod.text.dynamic.club.5104.biography": never;
	"prod.text.dynamic.club.5105.biography": never;
	"prod.text.dynamic.club.5106.biography": never;
	"prod.text.dynamic.club.5108.biography": never;
	"prod.text.dynamic.club.5109.biography": never;
	"prod.text.dynamic.club.5110.biography": never;
	"prod.text.dynamic.club.5113.biography": never;
	"prod.text.dynamic.club.5114.biography": never;
	"prod.text.dynamic.club.5115.biography": never;
	"prod.text.dynamic.club.5116.biography": never;
	"prod.text.dynamic.club.5117.biography": never;
	"prod.text.dynamic.club.5118.biography": never;
	"prod.text.dynamic.club.5119.biography": never;
	"prod.text.dynamic.club.5120.biography": never;
	"prod.text.dynamic.club.5121.biography": never;
	"prod.text.dynamic.club.5125.biography": never;
	"prod.text.dynamic.club.5128.biography": never;
	"prod.text.dynamic.club.5129.biography": never;
	"prod.text.dynamic.club.5130.biography": never;
	"prod.text.dynamic.club.5132.biography": never;
	"prod.text.dynamic.club.5134.biography": never;
	"prod.text.dynamic.club.5135.biography": never;
	"prod.text.dynamic.club.5136.biography": never;
	"prod.text.dynamic.club.5137.biography": never;
	"prod.text.dynamic.club.5138.biography": never;
	"prod.text.dynamic.club.5139.biography": never;
	"prod.text.dynamic.club.5140.biography": never;
	"prod.text.dynamic.club.5141.biography": never;
	"prod.text.dynamic.club.5142.biography": never;
	"prod.text.dynamic.club.5143.biography": never;
	"prod.text.dynamic.club.5145.biography": never;
	"prod.text.dynamic.club.5147.biography": never;
	"prod.text.dynamic.club.5148.biography": never;
	"prod.text.dynamic.club.5149.biography": never;
	"prod.text.dynamic.club.5150.biography": never;
	"prod.text.dynamic.club.5151.biography": never;
	"prod.text.dynamic.club.5152.biography": never;
	"prod.text.dynamic.club.5153.biography": never;
	"prod.text.dynamic.club.5154.biography": never;
	"prod.text.dynamic.club.5155.biography": never;
	"prod.text.dynamic.club.5156.biography": never;
	"prod.text.dynamic.club.5157.biography": never;
	"prod.text.dynamic.club.5158.biography": never;
	"prod.text.dynamic.club.5159.biography": never;
	"prod.text.dynamic.club.5160.biography": never;
	"prod.text.dynamic.club.5162.biography": never;
	"prod.text.dynamic.club.5163.biography": never;
	"prod.text.dynamic.club.5164.biography": never;
	"prod.text.dynamic.club.5165.biography": never;
	"prod.text.dynamic.club.5166.biography": never;
	"prod.text.dynamic.club.5167.biography": never;
	"prod.text.dynamic.club.5168.biography": never;
	"prod.text.dynamic.club.5169.biography": never;
	"prod.text.dynamic.club.5170.biography": never;
	"prod.text.dynamic.club.5171.biography": never;
	"prod.text.dynamic.club.5172.biography": never;
	"prod.text.dynamic.club.5173.biography": never;
	"prod.text.dynamic.club.5174.biography": never;
	"prod.text.dynamic.club.5175.biography": never;
	"prod.text.dynamic.club.5176.biography": never;
	"prod.text.dynamic.club.5179.biography": never;
	"prod.text.dynamic.club.5180.biography": never;
	"prod.text.dynamic.club.5182.biography": never;
	"prod.text.dynamic.club.5183.biography": never;
	"prod.text.dynamic.club.5184.biography": never;
	"prod.text.dynamic.club.5191.biography": never;
	"prod.text.dynamic.club.5192.biography": never;
	"prod.text.dynamic.club.5193.biography": never;
	"prod.text.dynamic.club.5195.biography": never;
	"prod.text.dynamic.club.5196.biography": never;
	"prod.text.dynamic.club.5197.biography": never;
	"prod.text.dynamic.club.5199.biography": never;
	"prod.text.dynamic.club.5200.biography": never;
	"prod.text.dynamic.club.5202.biography": never;
	"prod.text.dynamic.club.5203.biography": never;
	"prod.text.dynamic.club.5204.biography": never;
	"prod.text.dynamic.club.5205.biography": never;
	"prod.text.dynamic.club.5206.biography": never;
	"prod.text.dynamic.club.5229.biography": never;
	"prod.text.dynamic.game.15755.description": never;
	"btn.search-stats": never;
	"prod.text.dynamic.club.5231.biography": never;
	"prod.text.dynamic.club.5232.biography": never;
	"prod.text.dynamic.player.563.notes": never;
	"prod.text.dynamic.club.5233.biography": never;
	"prod.text.dynamic.player.27859.notes": never;
	"prod.text.dynamic.player.1875.notes": never;
	"prod.text.dynamic.player.3705.notes": never;
	"prod.text.dynamic.player.10884.notes": never;
	"prod.text.dynamic.player.1813.notes": never;
	"prod.text.dynamic.player.13120.notes": never;
	"prod.text.dynamic.player.3977.notes": never;
	"prod.text.dynamic.player.14033.notes": never;
	"prod.text.dynamic.player.2566.notes": never;
	"prod.text.dynamic.player.1883.notes": never;
	"prod.text.dynamic.player.991.notes": never;
	"prod.text.dynamic.player.20968.notes": never;
	"prod.text.dynamic.player.10999.notes": never;
	"prod.text.dynamic.player.9963.notes": never;
	"prod.text.dynamic.player.14124.notes": never;
	"prod.text.dynamic.player.1444.notes": never;
	"prod.text.dynamic.player.3062.notes": never;
	"prod.text.dynamic.player.14133.notes": never;
	"prod.text.dynamic.player.13289.notes": never;
	"prod.text.dynamic.player.3979.notes": never;
	"prod.text.dynamic.player.2097.notes": never;
	"prod.text.dynamic.club.5234.biography": never;
	"player.not.found": never;
	"prod.text.dynamic.club.4.biography": never;
	"prod.text.dynamic.club.5.biography": never;
	"prod.text.dynamic.club.6.biography": never;
	"prod.text.dynamic.club.8.biography": never;
	"prod.text.dynamic.club.9.biography": never;
	"prod.text.dynamic.club.20.biography": never;
	"prod.text.dynamic.club.21.biography": never;
	"prod.text.dynamic.club.23.biography": never;
	"prod.text.dynamic.club.26.biography": never;
	"prod.text.dynamic.club.27.biography": never;
	"prod.text.dynamic.club.28.biography": never;
	"prod.text.dynamic.club.29.biography": never;
	"team.not.found": never;
	"prod.text.dynamic.player.10167.notes": never;
	"prod.text.dynamic.player.10148.notes": never;
	"prod.text.dynamic.player.1807.notes": never;
	"prod.text.dynamic.player.11012.notes": never;
	"prod.text.dynamic.player.10997.notes": never;
	"prod.text.dynamic.club.5230.biography": never;
	"prod.text.dynamic.club.5236.biography": never;
	"prod.text.dynamic.club.5237.biography": never;
	"logoff.success": never;
	"logoff.fail": never;
	"prod.text.dynamic.club.5238.biography": never;
	"prod.text.dynamic.club.5239.biography": never;
	"prod.text.dynamic.player.26453.notes": never;
	"prod.text.dynamic.game.16062.description": never;
	"prod.text.dynamic.club.5240.biography": never;
	"stats.view.grid": never;
	"label.filters": never;
	"label.team.level": never;
	"label.info": never;
	"game.stats.table.shotsInsidePenaltyarea": never;
	"game.stats.table.shotsOutsidePenaltyarea": never;
	"label.apply.colum.selection": never;
	"warning.maximum.column.selection": never;
	"game.stats.table.aerialClearances": never;
	"game.stats.table.goalsKicks": never;
	"game.stats.table.controlUnderPressure": never;
	"game.stats.table.crosses": never;
	"stats.filters": never;
	"select.rank.players.stat": never;
	"game.stats.menu.general": never;
	"game.stats.menu.goalkeeping": never;
	"label.goalkeeping": never;
	"label.show_columns": never;
	"game.stats.menu.columnPresets": never;
	"edit.columns": never;
	"stats.filter.settings": never;
	"player.forward": never;
	"players.found": never;
	"cell.background.color.meaning": never;
	"text.position.bottom": never;
	"text.position.middle": never;
	"text.position.top": never;
	"label.player_birthday": never;
	"label.player.nat": never;
	"game.stats.table.shotsInside": never;
	"game.stats.table.shotsOutside": never;
	"game.stats.table.passesAccuracy": never;
	"player.midfielder": never;
	"player.defender": never;
	"label.custom": never;
	"contextMenu.openInNewTab": never;
	"contextMenu.copyLink": never;
	"contextMenu.share": never;
	"prod.text.dynamic.club.5178.biography": never;
	"contextMenu.openInNewWindow": never;
	"contextMenu.saveAs": never;
	"label.player.strongestFoot.left.letter": never;
	"label.player.strongestFoot.right.letter": never;
	"prod.text.dynamic.game.15474.description": never;
	"prod.text.dynamic.club.5241.biography": never;
	"enum.position.-.key": never;
	"prod.text.dynamic.player.27376.notes": never;
	"prod.text.dynamic.player.27374.notes": never;
	"iframeLoader.text": never;
	"prod.text.dynamic.club.5244.biography": never;
	"prod.text.dynamic.game.16206.description": never;
	"prod.text.dynamic.player.1927.notes": never;
	"prod.text.dynamic.player.26274.notes": never;
	"prod.text.dynamic.player.1611.notes": never;
	"prod.text.dynamic.player.1618.notes": never;
	"prod.text.dynamic.player.12642.notes": never;
	"prod.text.dynamic.player.9369.notes": never;
	"prod.text.dynamic.player.11007.notes": never;
	"prod.text.dynamic.player.1615.notes": never;
	"file.maximum.size.warning": never;
	"staging.text.dynamic.game.15643.description": never;
	"staging.text.dynamic.game.15642.description": never;
	"staging.text.dynamic.player.0.notes": never;
	"prod.text.dynamic.player.20112.notes": never;
	"prod.text.dynamic.player.261.notes": never;
	"stats.view.country.message": never;
	"prod.text.dynamic.club.5245.biography": never;
	"staging.text.dynamic.game.15249.description": never;
	"prod.text.dynamic.club.5246.biography": never;
	"prod.text.dynamic.game.16219.description": never;
	"staging.text.dynamic.game.15261.description": never;
	"app.player.validate.type": never;
	"app.player.validate.dateFromUnix": never;
	"app.player.validate.dateToUnix": never;
	"app.player.validate.clubs": never;
	"app.player.validate.firstName_string": never;
	"app.player.validate.lastName_string": never;
	"app.player.validate.nationality_string": never;
	"app.player.validate.nationalityTwo_string": never;
	"app.player.validate.nationalityTwo_notEqual": never;
	"app.player.validate.height_integer": never;
	"app.player.validate.height_min": never;
	"app.player.validate.height_max": never;
	"app.player.validate.weight_integer": never;
	"app.player.validate.weight_min": never;
	"app.player.validate.weight_max": never;
	"app.player.validate.position_string": never;
	"app.player.validate.positionTwo_string": never;
	"app.player.validate.strongFoot_string": never;
	"app.player.validate.mediaType": never;
	"app.player.validate.filename": never;
	"app.player.update.success": never;
	"app.player.validate.team": never;
	"app.player.validate.team_int": never;
	"app.player.validate.firstName": never;
	"app.player.validate.lastName": never;
	"app.player.validate.strongFoot": never;
	"app.player.validate.height": never;
	"app.player.validate.weight": never;
	"app.player.validate.position": never;
	"app.player.validate.notes_string": never;
	"app.player.validate.photo_string": never;
	"app.player.validate.birthday_digit": never;
	"app.game.error.validate.team": never;
	"app.game.error.validate.team_int": never;
	"app.game.error.validate.awayclub_int": never;
	"app.game.error.validate.awayteam_int": never;
	"app.game.error.validate.awayteamName_string": never;
	"app.game.error.validate.type_string": never;
	"app.game.error.validate.pole_int": never;
	"app.game.error.validate.matchTimeUnix": never;
	"app.game.error.validate.matchTimeUnix_int": never;
	"app.player.validate.gameId.gameId_int": never;
	"app.gameClip.error.validate.name_string": never;
	"app.gameClip.error.validate.type_array": never;
	"app.gameClip.error.validate.overwrite_bool": never;
	"app.gameClip.error.validate.maximum_bool": never;
	"app.gameClip.error.validate.clipId_int": never;
	"app.gameClip.error.validate.isPasswordUpdated_bool": never;
	"app.gameClip.error.validate.password_string": never;
	"app.gameClip.error.validate.expireDate": never;
	"app.gameClip.error.validate.expireDate_int": never;
	"app.gameClip.error.validate.ids_array": never;
	"app.coach.game.highlight.validate.timestamp_int": never;
	"app.coach.game.highlight.validate.id": never;
	"app.coach.game.highlight.validate.id_int": never;
	"app.player.validate.otp": never;
	"app.player.validate.otp_string": never;
	"app.player.validate.phoneNumber": never;
	"app.player.validate.phoneNumber_string": never;
	"app.player.validate.phoneNumber_invalid": never;
	"app.player.validate.signUpRequestID_string": never;
	"app.player.validate.type_invalid": never;
	"app.player.validate.club": never;
	"app.player.validate.player": never;
	"app.player.validate.isOtpVerified": never;
	"app.player.validate.isOtpVerified_bool": never;
	"app.validate.opt.not_verified": never;
	"app.player.validate.clubName_length": never;
	"app.player.validate.email_invalid": never;
	"app.player.validate.email": never;
	"app.player.validate.email_string": never;
	"app.player.validate.emailOrPhone_required": never;
	"app.player.validate.id": never;
	"app.player.validate.id.id_int": never;
	"app.player.validate.questionAnswers_array": never;
	"app.player.validate.password": never;
	"app.player.validate.password_string": never;
	"app.player.validate.signUpRequestID": never;
	"app.player.validate.plain_password.second": never;
	"app.player.validate.clubId": never;
	"app.player.validate.clubId_int": never;
	"app.player.validate.club_int": never;
	"app.player.validate.player_int": never;
	"app.player.validate.stepName": never;
	"app.player.validate.stepName_string": never;
	"app.player.validate.signUpRequestId_required": never;
	"app.player.validate.phoneNumber_required": never;
	"app.player.validate.confirmPassword": never;
	"app.player.validate.confirmPassword_string": never;
	"app.player.validate.token": never;
	"app.player.validate.token_string": never;
	"app.player.validate.reason": never;
	"app.player.validate.reason_type": never;
	"app.player.validate.password.not_matching": never;
	"app.player.otp.sent": never;
	"app.player.otp.valid": never;
	"app.player.otp.codeIs": never;
	"app.coach.planmatch.add.pasttime.title": never;
	"app.coach.planmatch.add.pasttime.content": never;
	"app.coach.planmatch.add.homeTeamNotFound": never;
	"app.coach.planmatch.add.awayTeamNotFound": never;
	"app.coach.planmatch.add.poleNotFound": never;
	"app.coach.planmatch.add.nopole.title": never;
	"app.coach.planmatch.add.nopole.content": never;
	"app.coach.team.add.error.duplicate_title": never;
	"app.coach.team.add.error.duplicate_content": never;
	"app.coach.team.add.error.wrongcategory_title": never;
	"app.coach.team.add.error.wrongcategory_content": never;
	"app.player.strongest_foot.left.letter": never;
	"app.player.strongest_foot.right.letter": never;
	"app.user.not.found": never;
	"app.user.update.success": never;
	"app.player.update.error": never;
	"app.player.validate.player.sign_up_request.updated": never;
	"app.player.validate.player.sign_up_request.phoneNumber_exists": never;
	"app.player.validate.player.sign_up_request.club_required": never;
	"app.player.validate.player.sign_up_request.player_registered": never;
	"app.player.validate.player.sign_up_request.club_not_match": never;
	"app.player.validate.player.sign_up_request.id_required": never;
	"app.player.validate.player.sign_up_request.not_found": never;
	"app.player.not.found": never;
	"app.player.not.editable": never;
	"app.clubName.mandatory": never;
	"app.game.clip.delete.success": never;
	"app.game.clip.dowload_link.failed": never;
	"app.game.clip.add.failed": never;
	"app.user.passwordReset.success": never;
	"app.highlight.delete.success": never;
	"app.club.not.exists": never;
	"app.game.error.unauthorized": never;
	"app.team.not.found": never;
	"app.game.not.found": never;
	"app.highlight.error.edit.unauthorized": never;
	"app.user.reset_password.invalid_token": never;
	"app.user.password.not_matching": never;
	"app.eyeball.privacy.title": never;
	"app.eyeball.privacy.accept": never;
	"app.eyeball.privacy.decline": never;
	"app.eyeball.privacy.required": never;
	"app.eyeball.privacy.url": never;
	"app.eyeball.terms.title": never;
	"app.eyeball.terms.accept": never;
	"app.eyeball.terms.decline": never;
	"app.eyeball.error.validate.range": never;
	"app.eyeball.error.validate.positive_number": never;
	"app.eyeball.error.validate.no_string": never;
	"app.eyeball.error.sign_up_player": never;
	"app.eyeball.error.password": never;
	"app.eyeball.error.validate.email": never;
	"app.eyeball.error.validate.min_length": never;
	"app.eyeball.error.validate.lowercase_alpha_num": never;
	"app.eyeball.error.validate.empty": never;
	"app.eyeball.error.validate.number": never;
	"app.eyeball.error.validate.prefix": never;
	"app.eyeball.error.validate.phone": never;
	"app.eyeball.error.register_player": never;
	"app.eyeball.text.ok": never;
	"app.eyeball.text.sorry": never;
	"app.eyeball.error.general": never;
	"app.eyeball.error.timeout": never;
	"app.eyeball.result_loading": never;
	"app.eyeball.result_pull_up": never;
	"app.eyeball.result_release": never;
	"app.eyeball.add_score": never;
	"app.eyeball.regional": never;
	"app.eyeball.national": never;
	"app.eyeball.menu.logout": never;
	"app.eyeball.menu.source_info": never;
	"app.eyeball.menu.home": never;
	"app.eyeball.menu.my_account": never;
	"app.eyeball.menu.games": never;
	"app.eyeball.menu.highlights": never;
	"app.eyeball.menu.support": never;
	"app.eyeball.lang.en": never;
	"app.eyeball.lang.fr": never;
	"app.eyeball.photo_source_gallery": never;
	"app.eyeball.photo_source_camera": never;
	"app.eyeball.discard.title": never;
	"app.eyeball.discard.message": never;
	"app.eyeball.yes": never;
	"app.eyeball.no": never;
	"app.eyeball.filter.title": never;
	"app.eyeball.filter.reset": never;
	"app.eyeball.filter.game_type": never;
	"app.eyeball.filter.date_period": never;
	"app.eyeball.filter.date_period.from": never;
	"app.eyeball.filter.date_period.to": never;
	"app.eyeball.filter.clubs": never;
	"app.eyeball.filter.teams": never;
	"app.eyeball.filter.submit": never;
	"app.eyeball.forgot_password.setup.title": never;
	"app.eyeball.forgot_password.setup.password.title": never;
	"app.eyeball.forgot_password.setup.password.hint": never;
	"app.eyeball.forgot_password.setup.password_again.title": never;
	"app.eyeball.forgot_password.setup.password_again.hint": never;
	"app.eyeball.forgot_password.setup.password.error": never;
	"app.eyeball.forgot_password.setup.password.not_match_error": never;
	"app.eyeball.forgot_password.setup.password.set_title": never;
	"app.eyeball.forgot_password.setup.password.set_message": never;
	"app.eyeball.profile.verify.header": never;
	"app.eyeball.profile.verify.title": never;
	"app.eyeball.profile.verify.message": never;
	"app.eyeball.profile.verify.reason.title": never;
	"app.eyeball.profile.verify.reason.message": never;
	"app.eyeball.profile.verify.take_photo": never;
	"app.eyeball.profile.verify.reason": never;
	"app.eyeball.profile.verify.confirm.title": never;
	"app.eyeball.profile.verify.confirm.message": never;
	"app.eyeball.profile.verify.confirm.check1": never;
	"app.eyeball.profile.verify.confirm.check2": never;
	"app.eyeball.profile.verify.confirm.submit": never;
	"app.eyeball.profile.verify.confirm.retake": never;
	"app.eyeball.profile.verify.success.title": never;
	"app.eyeball.profile.verify.success.message": never;
	"app.eyeball.profile.verify.success.done": never;
	"app.eyeball.login.login_title": never;
	"app.eyeball.login.email_title": never;
	"app.eyeball.login.email_hint": never;
	"app.eyeball.login.password_title": never;
	"app.eyeball.login.password_hint": never;
	"app.eyeball.login.login_action": never;
	"app.eyeball.login.forgot_password": never;
	"app.eyeball.login.become_customer_title": never;
	"app.eyeball.login.become_customer_action": never;
	"app.eyeball.login.error": never;
	"app.eyeball.login.biometric_message": never;
	"app.eyeball.login.biometric_enable_message": never;
	"app.eyeball.login.biometric_enable_action": never;
	"app.eyeball.forgot_password.title": never;
	"app.eyeball.forgot_password.subtitle": never;
	"app.eyeball.forgot_password.phone_title": never;
	"app.eyeball.forgot_password.phone_hint": never;
	"app.eyeball.forgot_password.submit_action": never;
	"app.eyeball.forgot_password.send_action": never;
	"app.eyeball.forgot_password.sent_title": never;
	"app.eyeball.forgot_password.sent_message": never;
	"app.eyeball.landing.title": never;
	"app.eyeball.landing.subtitle": never;
	"app.eyeball.landing.signup.player": never;
	"app.eyeball.landing.signup.coach": never;
	"app.eyeball.landing.signin.continue": never;
	"app.eyeball.landing.signin.title": never;
	"app.eyeball.landing.signin.action": never;
	"app.eyeball.landing.become_customer_url": never;
	"app.eyeball.open_source.title": never;
	"app.eyeball.open_source.disclaimer": never;
	"app.eyeball.open_source.index": never;
	"app.eyeball.open_source.components": never;
	"app.coach.home.welcome": never;
	"app.coach.home.name": never;
	"app.coach.home.stats.teams": never;
	"app.coach.home.stats.games": never;
	"app.coach.home.teams.title": never;
	"app.coach.home.teams.empty": never;
	"app.coach.home.teams.view_all": never;
	"app.coach.home.teams.info_players": never;
	"app.coach.home.games.title": never;
	"app.coach.home.games.view_all": never;
	"app.coach.home.club.contact": never;
	"app.coach.home.club.first_name": never;
	"app.coach.home.club.last_name": never;
	"app.coach.home.club.title": never;
	"app.coach.home.club.phone": never;
	"app.coach.home.club.email": never;
	"app.coach.squad.add_player_note_title": never;
	"app.coach.squad.add_player_note_hint": never;
	"app.coach.game_program.add_away_club_title": never;
	"app.coach.game_program.add_away_club_hint": never;
	"app.coach.game_program.add_away_team_error": never;
	"app.coach.game_program.is_national_title": never;
	"app.coach.game_program.is_national_yes": never;
	"app.coach.game_program.is_national_no": never;
	"app.coach.filter_coach_title": never;
	"app.coach.match.team_sheet_composition_title": never;
	"app.coach.match.team_sheet_composition_hint": never;
	"app.coach.match.team_sheet_color_jersey_title": never;
	"app.coach.match.team_sheet_color_jersey_hint": never;
	"app.coach.match.team_sheet_color_shorts_title": never;
	"app.coach.match.team_sheet_color_shorts_hint": never;
	"app.coach.match.team_sheet_squad_title": never;
	"app.coach.match.team_sheet_substitutes_title": never;
	"app.coach.match.team_sheet_position_title": never;
	"app.coach.match.team_sheet_position_hint": never;
	"app.coach.match.team_sheet_not_set": never;
	"app.coach.match.team_sheet_squad_player_title": never;
	"app.coach.match.team_sheet_player_title": never;
	"app.coach.match.team_sheet_player_hint": never;
	"app.coach.match.team_sheet_player_error": never;
	"app.coach.match.team_sheet_number_title": never;
	"app.coach.match.team_sheet_number_hint": never;
	"app.coach.match.team_sheet_goal_title": never;
	"app.coach.match.team_sheet_goal_hint": never;
	"app.coach.match.team_sheet_clear_action": never;
	"app.coach.match.team_sheet_save_action": never;
	"app.coach.match.team_sheet_not_assigned": never;
	"app.coach.match.team_sheet_player_number_header": never;
	"app.coach.match.team_sheet_player_name_header": never;
	"app.coach.match.team_sheet_player_yob_header": never;
	"app.coach.match.teams_tab": never;
	"app.coach.match.tags_tab": never;
	"app.coach.match.home_side_title": never;
	"app.coach.match.away_side_title": never;
	"app.coach.match.shirt_title": never;
	"app.coach.match.shorts_title": never;
	"app.coach.match.add_player_to_sheet": never;
	"app.coach.match.add_highlight": never;
	"app.coach.match.edit_team_sheet": never;
	"app.coach.match.highlights_empty": never;
	"app.coach.match.highlights_remove_title": never;
	"app.coach.match.highlights_remove_message": never;
	"app.coach.match.highlights_remove_yes": never;
	"app.coach.match.highlights_remove_no": never;
	"app.coach.match.highlights_add_header": never;
	"app.coach.match.highlights_add_title": never;
	"app.coach.match.highlights_add_title_hint": never;
	"app.coach.match.highlights_add_note": never;
	"app.coach.match.highlights_add_note_hint": never;
	"app.coach.match.highlights_add_save": never;
	"app.player.my_games.empty": never;
	"app.player.highlights.empty": never;
	"app.player.clips.empty": never;
	"app.player.profile.saved.title": never;
	"app.player.profile.saved.message": never;
	"app.player.profile.title": never;
	"app.player.profile.edit": never;
	"app.player.profile.save": never;
	"app.player.profile.percentage": never;
	"app.player.profile.sections_expand": never;
	"app.player.profile.sections_collapse": never;
	"app.player.profile.section_profile": never;
	"app.player.profile.section_info": never;
	"app.player.profile.section_goals": never;
	"app.player.profile.section_password": never;
	"app.player.profile.first_name.title": never;
	"app.player.profile.first_name.hint": never;
	"app.player.profile.last_name.title": never;
	"app.player.profile.last_name.hint": never;
	"app.player.profile.position.title": never;
	"app.player.profile.position.hint": never;
	"app.player.profile.position.error": never;
	"app.player.profile.position2.title": never;
	"app.player.profile.position2.hint": never;
	"app.player.profile.birth_date.title": never;
	"app.player.profile.birth_date.hint": never;
	"app.player.profile.nationality.title": never;
	"app.player.profile.nationality.hint": never;
	"app.player.profile.nationality2.title": never;
	"app.player.profile.nationality2.hint": never;
	"app.player.profile.nationality_equal.error": never;
	"app.player.profile.weight.title": never;
	"app.player.profile.weight.hint": never;
	"app.player.profile.weight.unit": never;
	"app.player.profile.height.title": never;
	"app.player.profile.height.hint": never;
	"app.player.profile.height.unit": never;
	"app.player.profile.strong_foot.title": never;
	"app.player.profile.strong_foot.left": never;
	"app.player.profile.strong_foot.right": never;
	"app.player.profile.email.title": never;
	"app.player.profile.email.hint": never;
	"app.player.profile.phone.title": never;
	"app.player.profile.phone.hint": never;
	"app.player.profile.intro_video.title": never;
	"app.player.profile.intro_video.upload": never;
	"app.player.profile.intro_video.empty": never;
	"app.player.profile.delete_profile": never;
	"app.player.profile.support_for_edit": never;
	"app.player.profile.support_clickable": never;
	"app.player.profile.delete_profile.title": never;
	"app.player.profile.delete_profile.action": never;
	"app.player.profile.delete_profile.cancel": never;
	"app.player.profile.delete_profile.hint": never;
	"app.player.profile.delete_profile.message": never;
	"app.player.profile.saved_info.message": never;
	"app.player.profile.password_title": never;
	"app.player.profile.password_hint": never;
	"app.player.profile.password_again_title": never;
	"app.player.profile.password_again_hint": never;
	"app.player.profile.password_not_match_error": never;
	"app.player.profile.delete.title": never;
	"app.player.profile.delete.message": never;
	"app.player.profile.delete.note": never;
	"app.player.profile.delete.reactivate": never;
	"app.player.profile.delete.exit": never;
	"app.player.home.tabbar.games": never;
	"app.player.home.tabbar.home": never;
	"app.player.home.tabbar.highlights": never;
	"app.player.home.tabbar.clips": never;
	"app.player.home.welcome": never;
	"app.player.home.info.position": never;
	"app.player.home.info.birthday": never;
	"app.player.home.info.height": never;
	"app.player.home.info.weight": never;
	"app.player.home.info.country": never;
	"app.player.home.info.right_foot": never;
	"app.player.home.info.left_foot": never;
	"app.player.home.info.collapse": never;
	"app.player.home.info.expand": never;
	"app.player.home.stats.highlights": never;
	"app.player.home.stats.games": never;
	"app.player.home.my_games.title": never;
	"app.player.home.my_games.view_all": never;
	"app.player.home.highlights.title": never;
	"app.player.home.highlights.view_all": never;
	"app.player.home.highlights.game_tag_title": never;
	"app.player.home.highlights.game_tag_manual": never;
	"app.player.home.highlights.game_tag_clip": never;
	"app.player.home.clips.title": never;
	"app.player.home.clips.view_all": never;
	"app.player.home.deleted.title": never;
	"app.player.home.deleted.reactivate": never;
	"app.player.home.deleted.login": never;
	"app.player.clip.remove_title": never;
	"app.player.clip.remove_message": never;
	"app.player.clip.remove_yes": never;
	"app.player.clip.remove_no": never;
	"app.player.clip.edit_title": never;
	"app.player.clip.edit_name_hint": never;
	"app.player.clip.edit_save": never;
	"app.player.clip.share_title": never;
	"app.player.clip.share_expire_title": never;
	"app.player.clip.share_expire_hint": never;
	"app.player.clip.share_password_title": never;
	"app.player.clip.share_description_title": never;
	"app.player.clip.share_generate": never;
	"app.player.clip.exist_title": never;
	"app.player.clip.exist_replace": never;
	"app.player.clip.exist_cancel": never;
	"app.player.clip.maximum_title": never;
	"app.player.clip.maximum_message": never;
	"app.player.clip.maximum_replace": never;
	"app.player.clip.maximum_cancel": never;
	"app.player.events.title": never;
	"app.player.events.empty": never;
	"app.player.events.filter_title": never;
	"app.player.events.filter_hint": never;
	"app.player.events.filter_apply": never;
	"app.player.events.clip_title": never;
	"app.player.events.clip_apply": never;
	"app.player.events.timeline": never;
	"app.player.events.filter_reset": never;
	"app.player.events.playerStat": never;
	"app.player.events.offensiveStat": never;
	"app.player.events.defensiveStat": never;
	"app.player.events.distributionStat": never;
	"app.player.events.goalkeeperStat": never;
	"app.player.events.summaryStat": never;
	"app.player.register.title": never;
	"app.player.register.club.title": never;
	"app.player.register.club.subtitle": never;
	"app.player.register.club.select": never;
	"app.player.register.club.empty": never;
	"app.player.register.empty.error": never;
	"app.player.register.support": never;
	"app.player.register.support.title": never;
	"app.player.register.support.message": never;
	"app.player.register.support.name_title": never;
	"app.player.register.support.name_hint": never;
	"app.player.register.support.email_title": never;
	"app.player.register.support.email_hint": never;
	"app.player.register.support.start_chat": never;
	"app.player.register.info.title": never;
	"app.player.register.info.first_name_title": never;
	"app.player.register.info.first_name_hint": never;
	"app.player.register.info.last_name_title": never;
	"app.player.register.info.last_name_hint": never;
	"app.player.register.info.birth_date_title": never;
	"app.player.register.info.birth_date_hint": never;
	"app.player.register.info.phone_title": never;
	"app.player.register.info.phone_hint": never;
	"app.player.register.info.country_picker_title": never;
	"app.player.register.info.error_number": never;
	"app.player.register.continue": never;
	"app.player.register.next": never;
	"app.player.register.select_player.title": never;
	"app.player.register.select_player.empty": never;
	"app.player.register.select_player.contact_support": never;
	"app.player.register.select_player.not_see_myself": never;
	"app.player.register.sms.title": never;
	"app.player.register.sms.subtitle": never;
	"app.player.register.submit_action": never;
	"app.player.register.sms_resend": never;
	"app.player.register.sms_resend_action": never;
	"app.player.register.error.code_valid": never;
	"app.player.register.signup.title": never;
	"app.player.register.signup.username.title": never;
	"app.player.register.signup.username.hint": never;
	"app.player.register.signup.password.title": never;
	"app.player.register.signup.password.hint": never;
	"app.player.register.signup.action": never;
	"app.player.register.signup.or_line": never;
	"app.player.register.signup.biometric.title": never;
	"app.player.register.signup.terms.full": never;
	"app.player.register.signup.terms.privacy": never;
	"app.player.register.signup.existing_account.title": never;
	"app.player.register.signup.existing_account.login": never;
	"app.eyeball.terms.content": never;
	"global.locale.en": never;
	"global.locale.fr": never;
	"global.locale.es": never;
	"global.locale.de": never;
	"subscription.comment.default_value": never;
	"staging.text.dynamic.player.27677.notes": never;
	"header.search.doesntMatch": never;
	"prod.text.dynamic.game.16247.description": never;
	"prod.text.dynamic.game.15662.description": never;
	"prod.text.dynamic.game.15970.description": never;
	"enum.gametype.championnat": never;
	"enum.gametype.amical": never;
	"enum.gametype.coupe": never;
	"enum.gametype.eightaside": never;
	"enum.gametype.poletest": never;
	"app.player.events.timeline_legend": never;
	"app.eyeball.login.contact_support_title": never;
	"app.eyeball.login.contact_support_action": never;
	"app.player.clip.edit_name_title": never;
	"app.eyeball.lang.title": never;
	"prod.text.dynamic.player.12814.notes": never;
	"prod.text.dynamic.player.2886.notes": never;
	"prod.text.dynamic.player.2885.notes": never;
	"prod.text.dynamic.player.4353.notes": never;
	"prod.text.dynamic.player.28541.notes": never;
	"prod.text.dynamic.player.16438.notes": never;
	"prod.text.dynamic.player.10826.notes": never;
	"prod.text.dynamic.player.3155.notes": never;
	"prod.text.dynamic.player.1450.notes": never;
	"prod.text.dynamic.game.16288.description": never;
	"prod.text.dynamic.game.16252.description": never;
	"prod.text.dynamic.game.15315.description": never;
	"prod.text.dynamic.game.15445.description": never;
	"prod.text.dynamic.game.15446.description": never;
	"prod.text.dynamic.game.15133.description": never;
	"prod.text.dynamic.club.5248.biography": never;
	"header.search.doesntMatch.club": never;
	"header.search.doesntMatch.player": never;
	"header.search.doesntMatch.game": never;
	"msg.subscription.expired": never;
	"msg.no.subscription": never;
	"label.scout.subscription.warning": never;
	"app.eyeball.lang.de": never;
	"app.eyeball.lang.es": never;
	"prod.text.dynamic.game.15971.description": never;
	"form_search_player_pro.custom_presets": never;
	"form_search_player_pro.default_presets": never;
	"prod.text.dynamic.club.5253.biography": never;
	"prod.text.dynamic.player.27854.notes": never;
	"prod.text.dynamic.player.3150.notes": never;
	"player.question.1.title": never;
	"player.question.2.title": never;
	"player.question.3.title": never;
	"player.question.4.title": never;
	"player.question.5.title": never;
	"player.question.6.title": never;
	"player.question.7.title": never;
	"player.question.1.answer.1": never;
	"player.question.1.answer.2": never;
	"player.question.1.answer.3": never;
	"player.question.1.answer.4": never;
	"player.question.1.answer.5": never;
	"player.question.6.answer.1": never;
	"player.question.6.answer.2": never;
	"prod.text.dynamic.player.28516.notes": never;
	"prod.text.dynamic.player.19785.notes": never;
	"prod.text.dynamic.club.5255.biography": never;
	"prod.text.dynamic.player.22670.notes": never;
	"prod.text.dynamic.player.22675.notes": never;
	"prod.text.dynamic.club.5256.biography": never;
	"prod.text.dynamic.player.22904.notes": never;
	"prod.text.dynamic.player.2156.notes": never;
	"prod.text.dynamic.player.21783.notes": never;
	"prod.text.dynamic.player.21778.notes": never;
	"prod.text.dynamic.player.342.notes": never;
	"prod.text.dynamic.player.401.notes": never;
	"prod.text.dynamic.player.377.notes": never;
	"favorite.remove.club.error": never;
	"favorite.add.club.error": never;
	"scout_homepage.search_clubs": never;
	"scout_homepage.search_players": never;
	"prod.text.dynamic.player.18759.notes": never;
	"prod.text.dynamic.player.1141.notes": never;
	"favorite.remove.match.error": never;
	"favorite.add.match.error": never;
	"favorite.add.player.error": never;
	"favorite.remove.player.error": never;
	"prod.text.dynamic.club.5257.biography": never;
	"app.eyeball.menu.clips": never;
	"app.eyeball.clip.error_create": never;
	"app.eyeball.clip.error_create_support": never;
	"app.eyeball.clip.creating_time": never;
	"app.eyeball.privacy.content": never;
	"fill.fields.form": never;
	"selected.players.batch.update": {
		noOfPlayers: string;
	};
	"player.batch.updated": never;
	"player.batch.failed": never;
	"prod.text.dynamic.player.17473.notes": never;
	"prod.text.dynamic.player.17477.notes": never;
	"prod.text.dynamic.game.16125.description": never;
	"leagueFilter.selectCountries": never;
	"label.player_stats_search": never;
	"prod.text.dynamic.player.1169.notes": never;
	"staging.text.dynamic.game.6701.description": never;
	"videoPlayer.controls.seekTime": {
		noOfSeconds: string;
	};
	"label.batch.update": never;
	"game.stats.table.gamesCount": never;
	"prod.text.dynamic.game.16479.description": never;
	"game.stats.table.backNumber": never;
	"prod.text.dynamic.club.5112.biography": never;
	"prod.text.dynamic.club.5260.biography": never;
	"timeline.first_half_start": never;
	"timeline.first_half_end": never;
	"timeline.second_half_start": never;
	"timeline.second_half_end": never;
	"stats.view.gold.subscription.message": never;
	"prod.text.dynamic.club.5261.biography": never;
	"prod.text.dynamic.club.5262.biography": never;
	"prod.text.dynamic.club.5265.biography": never;
	"label.match.national_leagues": never;
	"permission.game_analyze": never;
	"product.management.migrations": never;
	"label.migrate_club": never;
	"migration.label.select_deselect_all_players": never;
	"prod.text.dynamic.player.26035.notes": never;
	"prod.text.dynamic.player.29974.notes": never;
	"prod.text.dynamic.player.26033.notes": never;
	"prod.text.dynamic.player.26046.notes": never;
	"prod.text.dynamic.player.26041.notes": never;
	"prod.text.dynamic.player.26048.notes": never;
	"prod.text.dynamic.player.26791.notes": never;
	"leagues.coutries.not_selected": never;
	"prod.text.dynamic.club.5266.biography": never;
	"prod.text.dynamic.player.13772.notes": never;
	"prod.text.dynamic.player.13773.notes": never;
	"prod.text.dynamic.player.18121.notes": never;
	"prod.text.dynamic.player.22905.notes": never;
	"prod.text.dynamic.player.13846.notes": never;
	"prod.text.dynamic.player.11111.notes": never;
	"prod.text.dynamic.player.2823.notes": never;
	"migration.label.select_team_for_all_players": never;
	"prod.text.dynamic.player.30101.notes": never;
	"prod.text.dynamic.club.5270.biography": never;
	"prod.text.dynamic.club.5263.biography": never;
	"btn.continue_all": never;
	"scout.subscription.UK.replace.popup_message": never;
	"prod.text.dynamic.club.5272.biography": never;
	"prod.text.dynamic.game.16754.description": never;
	"explore.player.stats.page": never;
	"prod.text.dynamic.player.18768.notes": never;
	"prod.text.dynamic.club.5228.biography": never;
	"admin.program_game.national_league.message": never;
	"prod.text.dynamic.player.25852.notes": never;
	"newVersionPrompt.heading": never;
	"newVersionPrompt.description": never;
	"newVersionPrompt.btnUpdate.text": never;
	"newVersionPrompt.btnHide.text": never;
	"newVersionPrompt.icon.ttitle": never;
	"prod.text.dynamic.player.1025.notes": never;
	"label.nationalLeagues.more": never;
	"label.nationalLeagues.subText": never;
	"prod.text.dynamic.player.16545.notes": never;
	"prod.text.dynamic.player.3211.notes": never;
	"prod.text.dynamic.player.3212.notes": never;
	"prod.text.dynamic.player.3213.notes": never;
	"prod.text.dynamic.player.16701.notes": never;
	"prod.text.dynamic.club.5288.biography": never;
	"prod.text.dynamic.player.27930.notes": never;
	"prod.text.dynamic.player.27931.notes": never;
	"prod.text.dynamic.player.27932.notes": never;
	"prod.text.dynamic.player.27933.notes": never;
	"prod.text.dynamic.player.27935.notes": never;
	"prod.text.dynamic.player.27936.notes": never;
	"prod.text.dynamic.player.27937.notes": never;
	"prod.text.dynamic.player.27940.notes": never;
	"prod.text.dynamic.player.27941.notes": never;
	"prod.text.dynamic.player.27942.notes": never;
	"prod.text.dynamic.player.27943.notes": never;
	"prod.text.dynamic.player.27944.notes": never;
	"prod.text.dynamic.player.27929.notes": never;
	"prod.text.dynamic.player.17390.notes": never;
	"staging.text.dynamic.game.14968.description": never;
	"label.nationalLeagues.less": never;
	"prod.text.dynamic.player.29432.notes": never;
	"prod.text.dynamic.player.29434.notes": never;
	"prod.text.dynamic.player.29435.notes": never;
	"prod.text.dynamic.player.29436.notes": never;
	"prod.text.dynamic.player.29437.notes": never;
	"prod.text.dynamic.player.29438.notes": never;
	"prod.text.dynamic.player.29439.notes": never;
	"prod.text.dynamic.player.29440.notes": never;
	"prod.text.dynamic.player.29441.notes": never;
	"prod.text.dynamic.player.29442.notes": never;
	"prod.text.dynamic.player.29443.notes": never;
	"prod.text.dynamic.player.29445.notes": never;
	"prod.text.dynamic.player.29446.notes": never;
	"prod.text.dynamic.player.29447.notes": never;
	"prod.text.dynamic.player.29448.notes": never;
	"prod.text.dynamic.player.29449.notes": never;
	"prod.text.dynamic.player.29450.notes": never;
	"stats.view.limited-offer": never;
	"stats.view.gold.subscription.upgrade.message": never;
	"prod.text.dynamic.game.17225.description": never;
	"prod.text.dynamic.game.17259.description": never;
	"prod.text.dynamic.game.17267.description": never;
	"validate.player.search.player.playedInNationalTeam": never;
	"label.countrySelection.maxCountriesSelected": never;
	"label.nationalLeagues.max": never;
	"label.nationalGames.noGames": never;
	"prod.text.dynamic.player.31051.notes": never;
	"prod.text.dynamic.player.31052.notes": never;
	"prod.text.dynamic.player.31053.notes": never;
	"prod.text.dynamic.player.31141.notes": never;
	"prod.text.dynamic.player.26043.notes": never;
	"prod.text.dynamic.player.30417.notes": never;
	"prod.text.dynamic.game.17504.description": never;
	"prod.text.dynamic.game.17519.description": never;
	"staging.text.dynamic.game.14969.description": never;
	"prod.text.dynamic.game.17598.description": never;
	"prod.text.dynamic.game.17515.description": never;
	"prod.text.dynamic.club.5306.biography": never;
	"form_search_player_pro.columns": never;
	"prod.text.dynamic.club.5307.biography": never;
	"prod.text.dynamic.game.17522.description": never;
	"prod.text.dynamic.club.5308.biography": never;
	"prod.text.dynamic.game.17618.description": never;
	"prod.text.dynamic.game.17645.description": never;
	"prod.text.dynamic.game.17585.description": never;
	"form_search_player_pro.presets": never;
	"prod.text.dynamic.player.33669.notes": never;
	"prod.text.dynamic.player.33670.notes": never;
	"prod.text.dynamic.player.28052.notes": never;
	"prod.text.dynamic.player.28042.notes": never;
	"prod.text.dynamic.player.28644.notes": never;
	"prod.text.dynamic.player.28053.notes": never;
	"prod.text.dynamic.player.28039.notes": never;
	"prod.text.dynamic.player.28040.notes": never;
	"prod.text.dynamic.player.28043.notes": never;
	"prod.text.dynamic.player.28036.notes": never;
	"prod.text.dynamic.player.28049.notes": never;
	"prod.text.dynamic.player.28047.notes": never;
	"prod.text.dynamic.player.28045.notes": never;
	"prod.text.dynamic.game.17692.description": never;
	"label.nationality.continents": never;
	"prod.text.dynamic.game.17720.description": never;
	"prod.text.dynamic.player.28041.notes": never;
	"prod.text.dynamic.game.17803.description": never;
	"prod.text.dynamic.game.17756.description": never;
	"prod.text.dynamic.player.12883.notes": never;
	"prod.text.dynamic.game.17842.description": never;
	"prod.text.dynamic.game.17840.description": never;
	"prod.text.dynamic.game.17859.description": never;
	"prod.text.dynamic.game.17830.description": never;
	"prod.text.dynamic.game.17771.description": never;
	"prod.text.dynamic.game.17870.description": never;
	"prod.text.dynamic.club.5310.biography": never;
	"prod.text.dynamic.player.13156.notes": never;
	"prod.text.dynamic.player.24300.notes": never;
	"prod.text.dynamic.club.5311.biography": never;
	"prod.text.dynamic.game.17773.description": never;
	"prod.text.dynamic.club.5312.biography": never;
	"prod.text.dynamic.club.5313.biography": never;
	"signup.confirmation.generate.link": {
		title: string;
	};
	"generate.confirmation.are_you_sure": never;
	"generate.confirmation.yes_generate_it": never;
	"prod.text.dynamic.club.5295.biography": never;
	"prod.text.dynamic.game.17966.description": never;
	"opensearch.error": never;
	"select.rank.player.goals": never;
	"prod.text.dynamic.player.1013.notes": never;
	"prod.text.dynamic.player.16854.notes": never;
	"prod.text.dynamic.player.10165.notes": never;
	"prod.text.dynamic.game.17999.description": never;
	"prod.text.dynamic.player.17624.notes": never;
	"prod.text.dynamic.player.1806.notes": never;
	"prod.text.dynamic.player.20662.notes": never;
	"prod.text.dynamic.game.18029.description": never;
	"prod.text.dynamic.game.18096.description": never;
	"prod.text.dynamic.game.18097.description": never;
	"prod.text.dynamic.game.18099.description": never;
	"prod.text.dynamic.player.9926.notes": never;
	"prod.text.dynamic.player.13296.notes": never;
	"prod.text.dynamic.game.18018.description": never;
	"prod.text.dynamic.game.18188.description": never;
	"player.profile.best_game": never;
	"player.profile.club.category": never;
	"player.profile.club.none": never;
	"prod.text.dynamic.game.18276.description": never;
	"player.question.answer.yes": never;
	"player.question.answer.no": never;
	"player.question.answer.maybe": never;
	"player.question.answer.little": never;
	"player.question.8.title": never;
	"player.question.9.title": never;
	"player.question.10.title": never;
	"player.question.11.title": never;
	"player.question.12.title": never;
	"player.question.13.title": never;
	"player.question.14.title": never;
	"player.question.15.title": never;
	"game.stats.table.goalsScored": never;
	"game.stats.table.gamesPlayed": never;
	"game.stats.table.teamAgeGroup": never;
	"signupLink.successfullyGenerated": never;
	"signupLink.copiedToClipboard": never;
	"signupLink.copyLink": never;
	"game.stats.table.takeons": never;
	"game.stats.table.tackles": never;
	"signupLink.confirmation.title": never;
	"signupLink.confirmation.confirm": never;
	"signupLink.confirmation.description": {
		title: string;
	};
	"game.stats.table.aerialDuels": never;
	"game.stats.table.groundDuels": never;
	"game.stats.table.passes": never;
	"game.stats.table.passesForward": never;
	"game.stats.table.keyPassess": never;
	"select.sort_by": never;
	"prod.text.dynamic.club.5318.biography": never;
	"prod.text.dynamic.game.18427.description": never;
	"prod.text.dynamic.game.18488.description": never;
	"prod.text.dynamic.club.5291.biography": never;
	"prod.text.dynamic.club.5319.biography": never;
	"staging.text.dynamic.club.5230.biography": never;
	"staging.text.dynamic.clubNonPartner.7093.description": never;
	"dev.text.dynamic.club.5116.biography": never;
	"prod.text.dynamic.game.18361.description": never;
	"prod.text.dynamic.game.18550.description": never;
	"prod.text.dynamic.player.17735.notes": never;
	"prod.text.dynamic.player.30514.notes": never;
	"prod.text.dynamic.player.14029.notes": never;
	"prod.text.dynamic.player.21582.notes": never;
	"prod.text.dynamic.player.33735.notes": never;
	"prod.text.dynamic.game.18334.description": never;
	"label.game.stat.time": never;
	"label.game.notAnalyzed": never;
	"label.game.video.noData": never;
	"prod.text.dynamic.club.5315.biography": never;
	"prod.text.dynamic.player.33873.notes": never;
	"prod.text.dynamic.player.36200.notes": never;
	"prod.text.dynamic.club.5298.biography": never;
	"prod.text.dynamic.player.37107.notes": never;
	"prod.text.dynamic.player.37108.notes": never;
	"prod.text.dynamic.player.37109.notes": never;
	"prod.text.dynamic.player.37110.notes": never;
	"testing.text.dynamic.club.5116.biography": never;
	"form_search_player_pro.saved_filters": never;
	"prod.text.dynamic.player.35948.notes": never;
	"prod.text.dynamic.player.35949.notes": never;
	"prod.text.dynamic.player.35950.notes": never;
	"prod.text.dynamic.player.35951.notes": never;
	"prod.text.dynamic.player.35952.notes": never;
	"prod.text.dynamic.player.35953.notes": never;
	"prod.text.dynamic.player.35957.notes": never;
	"prod.text.dynamic.player.36175.notes": never;
	"prod.text.dynamic.player.36176.notes": never;
	"prod.text.dynamic.player.36177.notes": never;
	"prod.text.dynamic.player.36178.notes": never;
	"prod.text.dynamic.player.36179.notes": never;
	"prod.text.dynamic.player.36180.notes": never;
	"prod.text.dynamic.player.36181.notes": never;
	"prod.text.dynamic.player.36182.notes": never;
	"prod.text.dynamic.player.36183.notes": never;
	"prod.text.dynamic.player.36184.notes": never;
	"prod.text.dynamic.player.36185.notes": never;
	"prod.text.dynamic.player.36186.notes": never;
	"prod.text.dynamic.player.35938.notes": never;
	"prod.text.dynamic.player.35940.notes": never;
	"prod.text.dynamic.player.35941.notes": never;
	"prod.text.dynamic.player.35942.notes": never;
	"prod.text.dynamic.player.35943.notes": never;
	"prod.text.dynamic.player.35944.notes": never;
	"prod.text.dynamic.player.35946.notes": never;
	"prod.text.dynamic.player.35947.notes": never;
	"prod.text.dynamic.player.35955.notes": never;
	"prod.text.dynamic.game.18710.description": never;
	"prod.text.dynamic.game.18825.description": never;
	"enum.position.ALL": never;
	"prod.text.dynamic.game.18850.description": never;
	"label.position.compare_to": never;
	"prod.text.dynamic.game.18890.description": never;
	"prod.text.dynamic.player.36050.notes": never;
	"prod.text.dynamic.player.36057.notes": never;
	"prod.text.dynamic.player.36058.notes": never;
	"prod.text.dynamic.player.36060.notes": never;
	"prod.text.dynamic.player.36068.notes": never;
	"prod.text.dynamic.game.18889.description": never;
	"prod.text.dynamic.game.18520.description": never;
	"prod.text.dynamic.game.18822.description": never;
	"prod.text.dynamic.game.18379.description": never;
	"prod.text.dynamic.club.5324.biography": never;
	"prod.text.dynamic.player.34620.notes": never;
	"prod.text.dynamic.player.17817.notes": never;
	"prod.text.dynamic.player.2070.notes": never;
	"prod.text.dynamic.game.18927.description": never;
	"home.club_pro.news.title": never;
	"home.club_pro.news.button": never;
	"label.priority": never;
	"prod.text.dynamic.player.35979.notes": never;
	"prod.text.dynamic.game.19017.description": never;
	"prod.text.dynamic.player.35261.notes": never;
	"prod.text.dynamic.game.19053.description": never;
	"prod.text.dynamic.game.19060.description": never;
	"prod.text.dynamic.game.19093.description": never;
	"prod.text.dynamic.game.19074.description": never;
	"prod.text.dynamic.player.25354.notes": never;
	"prod.text.dynamic.player.35877.notes": never;
	"prod.text.dynamic.player.35879.notes": never;
	"prod.text.dynamic.player.35880.notes": never;
	"prod.text.dynamic.player.35897.notes": never;
	"prod.text.dynamic.player.35896.notes": never;
	"prod.text.dynamic.player.35895.notes": never;
	"prod.text.dynamic.player.35894.notes": never;
	"prod.text.dynamic.player.35893.notes": never;
	"prod.text.dynamic.player.35892.notes": never;
	"prod.text.dynamic.player.35891.notes": never;
	"prod.text.dynamic.player.35890.notes": never;
	"prod.text.dynamic.player.35889.notes": never;
	"prod.text.dynamic.player.35888.notes": never;
	"prod.text.dynamic.player.35887.notes": never;
	"prod.text.dynamic.player.35886.notes": never;
	"prod.text.dynamic.player.35885.notes": never;
	"prod.text.dynamic.player.35884.notes": never;
	"prod.text.dynamic.player.35883.notes": never;
	"prod.text.dynamic.player.35882.notes": never;
	"prod.text.dynamic.player.35881.notes": never;
	"prod.text.dynamic.player.35878.notes": never;
	"prod.text.dynamic.game.19180.description": never;
	"player.hasNoTeam": never;
	"prod.text.dynamic.player.17199.notes": never;
	"prod.text.dynamic.player.15510.notes": never;
	"prod.text.dynamic.player.35189.notes": never;
	"prod.text.dynamic.player.13301.notes": never;
	"prod.text.dynamic.player.23070.notes": never;
	"prod.text.dynamic.game.19109.description": never;
	"prod.text.dynamic.game.19049.description": never;
	"prod.text.dynamic.game.19119.description": never;
	"prod.text.dynamic.game.18277.description": never;
	"prod.text.dynamic.player.1480.notes": never;
	"prod.text.dynamic.player.70.notes": never;
	"prod.text.dynamic.player.1493.notes": never;
	"prod.text.dynamic.player.38958.notes": never;
	"prod.text.dynamic.player.17551.notes": never;
	"prod.text.dynamic.player.38770.notes": never;
	"prod.text.dynamic.player.15325.notes": never;
	"not.enough.data": never;
	"prod.text.dynamic.player.38969.notes": never;
	"prod.text.dynamic.player.38970.notes": never;
	"prod.text.dynamic.player.38973.notes": never;
	"prod.text.dynamic.player.17536.notes": never;
	"player.stats.menu.goalkeeper": never;
	"player.stats.menu.distribution": never;
	"player.stats.menu.defensive": never;
	"player.stats.menu.offensive": never;
	"player.stats.menu.summary": never;
	"prod.text.dynamic.game.19324.description": never;
	"prod.text.dynamic.game.19327.description": never;
	"prod.text.dynamic.game.19370.description": never;
	"prod.text.dynamic.game.19372.description": never;
	"prod.text.dynamic.game.19343.description": never;
	"prod.text.dynamic.game.19340.description": never;
	"prod.text.dynamic.game.19439.description": never;
	"prod.text.dynamic.game.19526.description": never;
	"prod.text.dynamic.game.19303.description": never;
	"home.club_pro.show_all_news": never;
	"prod.text.dynamic.player.37850.notes": never;
	"app.eyeball.profile.verify.reject.title": never;
	"app.eyeball.profile.verify.reject.message": never;
	"app.eyeball.profile.verify.reject.support": never;
	"app.eyeball.profile.verify.pending.title": never;
	"app.eyeball.profile.verify.pending.message": never;
	"app.eyeball.profile.verify.clarify_question.title": never;
	"app.eyeball.profile.verify.clarify_question.message": never;
	"app.eyeball.profile.verify.clarify_question.hint": never;
	"app.eyeball.profile.verify.clarify_question.submit": never;
	"app.eyeball.profile.verify.clarify_question.success": never;
	"app.eyeball.profile.verify.clarify_photo.title": never;
	"app.eyeball.profile.verify.clarify_photo.message": never;
	"app.player.home.mandatory_questions.title": never;
	"app.player.home.mandatory_questions.message": never;
	"app.player.home.mandatory_questions.error": never;
	"app.player.gestureHand.10141": never;
	"app.player.gestureHand.10142": never;
	"app.player.validate.player.sign_up_request.not_allowed": never;
	"prod.text.dynamic.game.19622.description": never;
	"prod.text.dynamic.player.38243.notes": never;
	"prod.text.dynamic.player.18791.notes": never;
	"prod.text.dynamic.player.18773.notes": never;
	"prod.text.dynamic.player.18786.notes": never;
	"prod.text.dynamic.player.18798.notes": never;
	"prod.text.dynamic.game.16476.description": never;
	"prod.text.dynamic.game.19651.description": never;
	"prod.text.dynamic.game.19698.description": never;
	"prod.text.dynamic.game.19684.description": never;
	"prod.text.dynamic.player.35433.notes": never;
	"prod.text.dynamic.game.19806.description": never;
	"prod.text.dynamic.club.5271.biography": never;
	"prod.text.dynamic.game.19842.description": never;
	"prod.text.dynamic.player.34900.notes": never;
	"prod.text.dynamic.player.34187.notes": never;
	"prod.text.dynamic.player.34898.notes": never;
	"prod.text.dynamic.player.34899.notes": never;
	"prod.text.dynamic.player.36609.notes": never;
	"prod.text.dynamic.club.5335.biography": never;
	"label.news.tags": never;
	"prod.text.dynamic.game.19832.description": never;
	"prod.text.dynamic.game.19830.description": never;
	"prod.text.dynamic.player.27450.notes": never;
	"prod.text.dynamic.game.20006.description": never;
	"prod.text.dynamic.club.5338.biography": never;
	"prod.text.dynamic.game.10185.description": never;
	"label.news.relatedNews": never;
	"dev.text.dynamic.club.5229.biography": never;
	"prod.text.dynamic.game.20281.description": never;
	"prod.text.dynamic.game.20183.description": never;
	"prod.text.dynamic.game.19928.description": never;
	"label.pageNotFound": never;
	"label.pageNotFound.text": never;
	"label.button.backToHome": never;
	"prod.text.dynamic.game.20359.description": never;
	"app.player.clip.create_title": never;
	"app.player.clip.create_replace": never;
	"app.player.clip.create_cancel": never;
	"app.eyeball.analyzed_game": never;
	"app.eyeball.legal.info": never;
	"app.eyeball.legal.terms.clickable": never;
	"app.eyeball.legal.privacy.clickable": never;
	"app.player.register.club_supported.title": never;
	"app.player.register.club_supported.message": never;
	"prod.text.dynamic.game.20438.description": never;
	"prod.text.dynamic.game.20411.description": never;
	"prod.text.dynamic.game.20100.description": never;
	"prod.text.dynamic.game.20086.description": never;
	"prod.text.dynamic.game.20088.description": never;
	"prod.text.dynamic.game.20087.description": never;
	"prod.text.dynamic.game.20085.description": never;
	"prod.text.dynamic.game.19292.description": never;
	"prod.text.dynamic.game.20140.description": never;
	"prod.text.dynamic.game.20096.description": never;
	"prod.text.dynamic.game.20401.description": never;
	"prod.text.dynamic.game.20348.description": never;
	"prod.text.dynamic.game.20218.description": never;
	"prod.text.dynamic.game.20141.description": never;
	"prod.text.dynamic.game.20099.description": never;
	"prod.text.dynamic.game.18666.description": never;
	"prod.text.dynamic.game.20095.description": never;
	"prod.text.dynamic.game.17989.description": never;
	"prod.text.dynamic.game.20089.description": never;
	"prod.text.dynamic.game.20101.description": never;
	"prod.text.dynamic.game.17695.description": never;
	"prod.text.dynamic.game.17694.description": never;
	"prod.text.dynamic.game.17398.description": never;
	"prod.text.dynamic.game.17441.description": never;
	"prod.text.dynamic.game.17170.description": never;
	"prod.text.dynamic.game.16733.description": never;
	"prod.text.dynamic.game.16701.description": never;
	"prod.text.dynamic.game.16734.description": never;
	"prod.text.dynamic.game.16858.description": never;
	"prod.text.dynamic.game.16874.description": never;
	"prod.text.dynamic.game.16382.description": never;
	"prod.text.dynamic.game.16748.description": never;
	"prod.text.dynamic.game.16070.description": never;
	"prod.text.dynamic.game.16049.description": never;
	"prod.text.dynamic.game.16071.description": never;
	"prod.text.dynamic.player.23612.notes": never;
	"prod.text.dynamic.player.23615.notes": never;
	"message.select_product": never;
	"app.eyeball.clip.creating_time_few_seconds": never;
	"app.eyeball.clip.creating_time_exact_seconds": never;
	"prod.text.dynamic.player.27312.notes": never;
	"prod.text.dynamic.player.27313.notes": never;
	"prod.text.dynamic.player.27314.notes": never;
	"prod.text.dynamic.player.27315.notes": never;
	"prod.text.dynamic.player.27316.notes": never;
	"prod.text.dynamic.player.27317.notes": never;
	"prod.text.dynamic.player.27318.notes": never;
	"prod.text.dynamic.player.27319.notes": never;
	"prod.text.dynamic.player.27320.notes": never;
	"prod.text.dynamic.player.27321.notes": never;
	"prod.text.dynamic.player.27322.notes": never;
	"prod.text.dynamic.player.27323.notes": never;
	"prod.text.dynamic.player.27325.notes": never;
	"prod.text.dynamic.player.27328.notes": never;
	"prod.text.dynamic.player.27329.notes": never;
	"prod.text.dynamic.player.40283.notes": never;
	"prod.text.dynamic.player.40284.notes": never;
	"prod.text.dynamic.player.40286.notes": never;
	"prod.text.dynamic.player.40287.notes": never;
	"prod.text.dynamic.player.40285.notes": never;
	"prod.text.dynamic.player.27979.notes": never;
	"prod.text.dynamic.player.27980.notes": never;
	"prod.text.dynamic.player.27981.notes": never;
	"prod.text.dynamic.player.27983.notes": never;
	"prod.text.dynamic.player.27984.notes": never;
	"prod.text.dynamic.player.27985.notes": never;
	"prod.text.dynamic.player.27988.notes": never;
	"prod.text.dynamic.player.27990.notes": never;
	"prod.text.dynamic.game.20499.description": never;
	"prod.text.dynamic.club.5341.biography": never;
	"prod.text.dynamic.game.20457.description": never;
	"prod.text.dynamic.player.26947.notes": never;
	"prod.text.dynamic.player.36897.notes": never;
	"prod.text.dynamic.club.5321.biography": never;
	"prod.text.dynamic.game.20772.description": never;
	"prod.text.dynamic.game.20664.description": never;
	"prod.text.dynamic.game.20583.description": never;
	"prod.text.dynamic.game.20865.description": never;
	"prod.text.dynamic.game.20878.description": never;
	"prod.text.dynamic.player.39601.notes": never;
	"prod.text.dynamic.player.39602.notes": never;
	"prod.text.dynamic.player.39603.notes": never;
	"prod.text.dynamic.player.39605.notes": never;
	"prod.text.dynamic.player.39606.notes": never;
	"prod.text.dynamic.player.39608.notes": never;
	"prod.text.dynamic.player.39610.notes": never;
	"prod.text.dynamic.player.39612.notes": never;
	"tag.Africa": never;
	"prod.text.dynamic.player.23814.notes": never;
	"signupLink.not.copiedToClipboard": never;
	"tag.Belgium": never;
	"tag.Best XI": never;
	"tag.France": never;
	"prod.text.dynamic.player.12375.notes": never;
	"tag.England": never;
	"tag.Sweden": never;
	"tag.Finland": never;
	"tag.Iceland": never;
	"tag.Spain": never;
	"tag.Northern Ireland": never;
	"tag.Norway": never;
	"tag.Netherlands": never;
	"tag.Player focus": never;
	"tag.World cup": never;
	"tag.First team": never;
	"tag.National team": never;
	"tag.Playing up": never;
	"tag.Goal": never;
	"tag.Striker": never;
	"tag.Platform": never;
	"tag.Denmark": never;
	"label.accessDenied.title": never;
	"label.accessDenied.text": never;
	"prod.text.dynamic.game.21085.description": never;
	"prod.text.dynamic.club.5347.biography": never;
	"prod.text.dynamic.player.38961.notes": never;
	"prod.text.dynamic.player.14019.notes": never;
	"prod.text.dynamic.player.35065.notes": never;
	"prod.text.dynamic.player.15630.notes": never;
	"app.player.approvalStatusNotify.title": never;
	"app.player.approvalStatusNotify.body": never;
	"label.korastats": never;
	"gameAnalysis.rejected.success": never;
	"btn.statSearch.preview": never;
	"app.gesture.handAboveHead": never;
	"app.gesture.handNextToHeadFist": never;
	"app.gesture.peaceSign": never;
	"app.gesture.threeFingers": never;
	"app.gesture.thumbOnChin": never;
	"app.gesture.handNextToHeadPalm": never;
	"app.gesture.thumbUp": never;
	"app.gesture.handOnCheek": never;
	"prod.text.dynamic.player.28159.notes": never;
	"prod.text.dynamic.player.28160.notes": never;
	"prod.text.dynamic.player.28162.notes": never;
	"prod.text.dynamic.player.28165.notes": never;
	"prod.text.dynamic.player.28152.notes": never;
	"prod.text.dynamic.player.28154.notes": never;
	"prod.text.dynamic.game.21536.description": never;
	"prod.text.dynamic.game.21331.description": never;
	"prod.text.dynamic.game.21245.description": never;
	"prod.text.dynamic.game.20982.description": never;
	"btn.reject": never;
	"status.rejected": never;
	"regionMap.newTitle": never;
	"prod.text.dynamic.game.21573.description": never;
	"prod.text.dynamic.game.21872.description": never;
	"prod.text.dynamic.player.1256.notes": never;
	"prod.text.dynamic.player.1264.notes": never;
	"prod.text.dynamic.game.21909.description": never;
	"prod.text.dynamic.game.21799.description": never;
	"prod.text.dynamic.game.22018.description": never;
	"prod.text.dynamic.player.9920.notes": never;
	"prod.text.dynamic.player.9946.notes": never;
	"prod.text.dynamic.player.18165.notes": never;
	"prod.text.dynamic.player.10142.notes": never;
	"prod.text.dynamic.club.5353.biography": never;
	"prod.text.dynamic.player.15208.notes": never;
	"prod.text.dynamic.game.21974.description": never;
	"label.button.countriesExpand": never;
	"prod.text.dynamic.game.22099.description": never;
	"prod.text.dynamic.club.5369.biography": never;
	"prod.text.dynamic.game.22110.description": never;
	"prod.text.dynamic.game.22142.description": never;
	"prod.text.dynamic.game.22204.description": never;
	"prod.text.dynamic.game.22203.description": never;
	"prod.text.dynamic.game.22151.description": never;
	"permission.game_analyze.denied": never;
	"prod.text.dynamic.player.27370.notes": never;
	"cannot.get.result": never;
	"game.clip.add.failed.non_standard_exception": never;
	"game.clip.add.failed.issue_with_video": never;
	"prod.text.dynamic.game.22607.description": never;
	"prod.text.dynamic.game.22596.description": never;
	"prod.text.dynamic.game.22597.description": never;
	"prod.text.dynamic.game.22608.description": never;
	"prod.text.dynamic.game.22619.description": never;
	"prod.text.dynamic.game.22621.description": never;
	"prod.text.dynamic.club.5374.biography": never;
	"prod.text.dynamic.player.41547.notes": never;
	"prod.text.dynamic.player.41569.notes": never;
	"prod.text.dynamic.game.22675.description": never;
	"prod.text.dynamic.player.35929.notes": never;
	"prod.text.dynamic.player.35920.notes": never;
	"prod.text.dynamic.player.35925.notes": never;
	"prod.text.dynamic.player.35932.notes": never;
	"prod.text.dynamic.player.35926.notes": never;
	"prod.text.dynamic.player.30725.notes": never;
	"prod.text.dynamic.game.22702.description": never;
	"prod.text.dynamic.game.22715.description": never;
	"prod.text.dynamic.game.22740.description": never;
	"prod.text.dynamic.player.15239.notes": never;
	"prod.text.dynamic.player.19216.notes": never;
	"prod.text.dynamic.player.20659.notes": never;
	"prod.text.dynamic.player.13292.notes": never;
	"prod.text.dynamic.player.41099.notes": never;
	"prod.text.dynamic.club.5376.biography": never;
	"prod.text.dynamic.player.2574.notes": never;
	"prod.text.dynamic.game.22874.description": never;
	"prod.text.dynamic.game.22822.description": never;
	"prod.text.dynamic.game.23001.description": never;
	"prod.text.dynamic.game.22891.description": never;
	"prod.text.dynamic.game.22321.description": never;
	"prod.text.dynamic.game.21602.description": never;
	"prod.text.dynamic.game.22035.description": never;
	"prod.text.dynamic.game.22287.description": never;
	"prod.text.dynamic.game.22513.description": never;
	"prod.text.dynamic.game.22889.description": never;
	"staging.text.dynamic.player.27754.notes": never;
	"prod.text.dynamic.game.23017.description": never;
	"prod.text.dynamic.player.21171.notes": never;
	"prod.text.dynamic.player.21182.notes": never;
	"prod.text.dynamic.game.23067.description": never;
	"prod.text.dynamic.player.29475.notes": never;
	"prod.text.dynamic.player.146.notes": never;
	"prod.text.dynamic.game.22974.description": never;
	"prod.text.dynamic.game.23118.description": never;
	"prod.text.dynamic.game.23117.description": never;
	"prod.text.dynamic.game.23113.description": never;
	"prod.text.dynamic.game.23119.description": never;
	"prod.text.dynamic.club.5383.biography": never;
	"prod.text.dynamic.game.23300.description": never;
	"prod.text.dynamic.game.23274.description": never;
	"pole.sync.success": never;
	"admin.subscription.updation": never;
	"admin.subscription.creation": never;
	"label.extra_2nd_half": never;
	"label.extra_1st_half": never;
	"prod.text.dynamic.club.5382.biography": never;
	"prod.text.dynamic.player.33105.notes": never;
	"prod.text.dynamic.player.33103.notes": never;
	"prod.text.dynamic.player.33114.notes": never;
	"prod.text.dynamic.player.37226.notes": never;
	"prod.text.dynamic.player.37227.notes": never;
	"prod.text.dynamic.player.37228.notes": never;
	"prod.text.dynamic.player.37230.notes": never;
	"prod.text.dynamic.player.38199.notes": never;
	"prod.text.dynamic.player.38200.notes": never;
	"prod.text.dynamic.player.38201.notes": never;
	"prod.text.dynamic.player.38204.notes": never;
	"prod.text.dynamic.player.38205.notes": never;
	"prod.text.dynamic.player.38206.notes": never;
	"prod.text.dynamic.player.38207.notes": never;
	"prod.text.dynamic.player.40587.notes": never;
	"prod.text.dynamic.player.40588.notes": never;
	"prod.text.dynamic.player.40589.notes": never;
	"prod.text.dynamic.player.35738.notes": never;
	"prod.text.dynamic.player.45392.notes": never;
	"favorite.remove.team.error": never;
	"favorite.add.team.error": never;
	"prod.text.dynamic.player.15292.notes": never;
	"prod.text.dynamic.player.24784.notes": never;
	"testing.text.dynamic.clubNonPartner.7089.description": never;
	"testing.text.dynamic.clubNonPartner.7090.description": never;
	"testing.text.dynamic.clubNonPartner.7091.description": never;
	"prod.text.dynamic.game.23659.description": never;
	"prod.text.dynamic.game.23570.description": never;
	"prod.text.dynamic.player.20646.notes": never;
	"prod.text.dynamic.player.17145.notes": never;
	"prod.text.dynamic.player.2878.notes": never;
	"testing.text.dynamic.clubNonPartner.7092.description": never;
	"testing.text.dynamic.clubNonPartner.7093.description": never;
	"timeline.extra_half_one_start": never;
	"timeline.extra_half_one_end": never;
	"timeline.extra_half_two_start": never;
	"timeline.extra_half_two_end": never;
	"prod.text.dynamic.game.23555.description": never;
	"prod.text.dynamic.club.5391.biography": never;
	"prod.text.dynamic.player.18913.notes": never;
	"prod.text.dynamic.club.5379.biography": never;
	"game.stats.table.goalsOwn": never;
	"label.showMore": never;
	"prod.text.dynamic.club.5392.biography": never;
	"label.extra_3rd_half": never;
	"prod.text.dynamic.player.17887.notes": never;
	"prod.text.dynamic.player.29310.notes": never;
	"prod.text.dynamic.club.5395.biography": never;
	"clip.label.event": never;
	"prod.text.dynamic.club.5396.biography": never;
	"prod.text.dynamic.player.47834.notes": never;
	"prod.text.dynamic.player.47833.notes": never;
	"testing.text.dynamic.player.1257.notes": never;
	"testing.text.dynamic.player.15319.notes": never;
	"prod.text.dynamic.game.24067.description": never;
	"prod.text.dynamic.game.24357.description": never;
	"prod.text.dynamic.game.23904.description": never;
	"dev.text.dynamic.club.5232.biography": never;
	"prod.text.dynamic.club.5181.biography": never;
	"testing.text.dynamic.player.15317.notes": never;
	"dev.text.dynamic.club.5234.biography": never;
	" app.game.clip.delete.error": never;
	"staging.text.dynamic.game.22972.description": never;
	"message.clip_name_change_error": never;
	"prod.text.dynamic.club.5399.biography": never;
	"prod.text.dynamic.player.187.notes": never;
	"staging.text.dynamic.clubNonPartner.8427.description": never;
	"select.rank.player.goalsOwn": never;
	"prod.text.dynamic.club.5404.biography": never;
	"prod.text.dynamic.club.5405.biography": never;
	"prod.text.dynamic.club.5406.biography": never;
	"prod.text.dynamic.game.24009.description": never;
	"prod.text.dynamic.game.24516.description": never;
	"prod.text.dynamic.game.24508.description": never;
	"message.clips.share.success": never;
	" message.clips.share.error": never;
	"prod.text.dynamic.game.24525.description": never;
	"prod.text.dynamic.game.24526.description": never;
	"prod.text.dynamic.game.24276.description": never;
	"prod.text.dynamic.game.24256.description": never;
	"prod.text.dynamic.game.24275.description": never;
	"prod.text.dynamic.player.29765.notes": never;
	"placeholder.dropdown.player": never;
	"placeholder.dropdown.club": never;
	"label.loginToEyeballAdmin": never;
	"staging.text.dynamic.player.17279.notes": never;
	"label.resyncType": never;
	"label.syncLocally": never;
	"label.resyncAfter": never;
	" label.resync_options.transformed": never;
	"title.resync_game_stats": never;
	"msg.game_provider_not_found": never;
	"msg.resync_request_sent_successfully": never;
	"msg.game_provider_not_supported": never;
	"prod.text.dynamic.player.3590.notes": never;
	"prod.text.dynamic.player.10195.notes": never;
	"prod.text.dynamic.player.2444.notes": never;
	"prod.text.dynamic.player.16672.notes": never;
	"label.resyncType.info": never;
	"label.syncLocally.info": never;
	"label.resyncAfter.info": never;
	"prod.text.dynamic.game.24877.description": never;
	"prod.text.dynamic.player.37946.notes": never;
	"prod.text.dynamic.game.24873.description": never;
	"prod.text.dynamic.game.24854.description": never;
	"prod.text.dynamic.club.5411.biography": never;
	"no.route.found": never;
	"prod.text.dynamic.club.5412.biography": never;
	"prod.text.dynamic.game.25038.description": never;
	"status.approved": never;
	"status.none": never;
	"prod.text.dynamic.club.5413.biography": never;
	"search_no_result.posts": never;
	"migration.validation.message.select_both_source_target_club_same": never;
	"form_add_club_manager.label.is_active": never;
	"form_add_club_manager.label.is_active.true": never;
	"form_add_club_manager.label.is_active.false": never;
	"prod.text.dynamic.club.5415.biography": never;
	"staging.text.dynamic.clubNonPartner.6498.description": never;
	"prod.text.dynamic.game.24857.description": never;
	"prod.text.dynamic.player.49816.notes": never;
	"prod.text.dynamic.player.49817.notes": never;
	"form_add_coach.label.is_active.true": never;
	"form_add_coach.label.is_active.false": never;
	"form_add_coach.label.is_active": never;
	"label.user.deactivate_reason": never;
	"app.player.userEmail.exists": never;
	"label.account.preferences": never;
	"label.account.details": never;
	"prod.text.dynamic.game.25068.description": never;
	"prod.text.dynamic.game.21588.description": never;
	"label.account.newsletter_checkbox": never;
	"prod.text.dynamic.game.25214.description": never;
	"btn.abort_resync": never;
	"label.resync": never;
	" label.resync_options.all": never;
	"prod.text.dynamic.game.24543.description": never;
	"prod.text.dynamic.player.3596.notes": never;
	"msg.resync_request_failed": never;
	"msg.resync_aborted_successfully": never;
	"msg.game_not_resynced": never;
	"prod.text.dynamic.player.17737.notes": never;
	"label.valitadion.password": never;
	"message.reset_password.fail": never;
	"message.notification_update.success": never;
	"message.notification_update.fail": never;
	"app.user.update.fail": never;
	"message.preference_update.success": never;
	"message.preference_update.fail": never;
	"prod.text.dynamic.player.9144.notes": never;
	"prod.text.dynamic.player.23815.notes": never;
	"label.search.header.placeholder": never;
	"label.search.noSub": never;
	"button.search.upgrade": never;
	"btn.analysis_approval": never;
	"prod.text.dynamic.player.20929.notes": never;
	"msg.resync_already_in_progress": never;
	"error.blocked_account": never;
	"timeline.extra_half_three_start": never;
	"timeline.extra_half_three_end": never;
	"prod.text.dynamic.game.25204.description": never;
	"prod.text.dynamic.game.25523.description": never;
	"prod.text.dynamic.game.24574.description": never;
	"app.player.ageGroup.notAvailable": never;
	"player.search.preset.column.goalsOwn": never;
	"prod.text.dynamic.club.5421.biography": never;
	"tag.Tim's Choice": never;
	"tag.Must-watch games": never;
	"message.500page": never;
	"prod.text.dynamic.game.25150.description": never;
	"prod.text.dynamic.club.5422.biography": never;
	"photo.upload.fail": never;
	"prod.text.dynamic.game.25360.description": never;
	"prod.text.dynamic.game.25157.description": never;
	"prod.text.dynamic.game.25499.description": never;
	"prod.text.dynamic.player.19054.notes": never;
	"prod.text.dynamic.player.33559.notes": never;
	"label.download_option.simpleXml": never;
	"label.download_option.detailedXml": never;
	"message.500page.info": never;
	"prod.text.dynamic.player.33397.notes": never;
	"prod.text.dynamic.player.24775.notes": never;
	"prod.text.dynamic.player.23182.notes": never;
	"prod.text.dynamic.game.25855.description": never;
	"prod.text.dynamic.game.25854.description": never;
	"prod.text.dynamic.game.25853.description": never;
	"prod.text.dynamic.club.5428.biography": never;
	"prod.text.dynamic.club.5429.biography": never;
	"prod.text.dynamic.player.50435.notes": never;
	"prod.text.dynamic.player.30377.notes": never;
	"prod.text.dynamic.player.21792.notes": never;
	"prod.text.dynamic.player.21777.notes": never;
	"btn.subscribe_newsletter": never;
	"prod.text.dynamic.player.49167.notes": never;
	"prod.text.dynamic.game.25876.description": never;
	"prod.text.dynamic.game.24613.description": never;
	"prod.text.dynamic.game.25218.description": never;
	"prod.text.dynamic.game.25641.description": never;
	"prod.text.dynamic.game.26237.description": never;
	"prod.text.dynamic.club.5378.biography": never;
	"label.globalSearch.outOfSub": never;
	"prod.text.dynamic.player.18556.notes": never;
	"label.globalSearch.upgradeSub.title": never;
	"label.globalSearch.upgradeSub.text": never;
	"prod.text.dynamic.club.5432.biography": never;
	"staging.text.dynamic.player.47032.notes": never;
	"prod.text.dynamic.player.477.notes": never;
	"error.message.contact_customer_support": never;
	"not.enough.permission": never;
	"prod.text.dynamic.player.27740.notes": never;
	"prod.text.dynamic.player.350.notes": never;
	"label.outOfSub.games": {
		outOfCountryCount: string;
	};
	"label.outOfSub.clubs": {
		outOfCountryCount: string;
	};
	"label.outOfSub.players": {
		outOfCountryCount: string;
	};
	"label.accessDenied.text.formatted": never;
	"prod.text.dynamic.player.15504.notes": never;
	"prod.text.dynamic.player.15509.notes": never;
	"prod.text.dynamic.player.1369.notes": never;
	"prod.text.dynamic.game.26459.description": never;
	"prod.text.dynamic.player.34840.notes": never;
	"testing.text.dynamic.player.0.notes": never;
	"prod.text.dynamic.game.24232.description": never;
	"prod.text.dynamic.player.38460.notes": never;
	"delete.game.failed.message": never;
	"prod.text.dynamic.player.34265.notes": never;
	"prod.text.dynamic.game.26696.description": never;
	"prod.text.dynamic.player.3218.notes": never;
	"prod.text.dynamic.club.5447.biography": never;
	"prod.text.dynamic.club.5448.biography": never;
	"prod.text.dynamic.player.29225.notes": never;
	"prod.text.dynamic.player.444.notes": never;
	"prod.text.dynamic.game.26806.description": never;
	"cannot.access.match.sheet": never;
	"prod.text.dynamic.game.26766.description": never;
	"prod.text.dynamic.game.26887.description": never;
	"prod.text.dynamic.club.5452.biography": never;
	"contact.sales.team": never;
	"prod.text.dynamic.club.5453.biography": never;
	"prod.text.dynamic.club.5454.biography": never;
	"prod.text.dynamic.player.38218.notes": never;
	"prod.text.dynamic.game.26929.description": never;
	"prod.text.dynamic.player.30230.notes": never;
	"prod.text.dynamic.player.30235.notes": never;
	"prod.text.dynamic.player.30234.notes": never;
	"prod.text.dynamic.player.30237.notes": never;
	"prod.text.dynamic.player.40460.notes": never;
	"prod.text.dynamic.player.15805.notes": never;
	"prod.text.dynamic.game.27093.description": never;
	"prod.text.dynamic.player.14126.notes": never;
	"prod.text.dynamic.player.10147.notes": never;
	"prod.text.dynamic.player.10731.notes": never;
	"prod.text.dynamic.player.10374.notes": never;
	"prod.text.dynamic.game.27024.description": never;
	"prod.text.dynamic.game.25829.description": never;
	"prod.text.dynamic.club.5464.biography": never;
	"prod.text.dynamic.game.27262.description": never;
	"prod.text.dynamic.game.25007.description": never;
	"prod.text.dynamic.game.25006.description": never;
	"prod.text.dynamic.game.24515.description": never;
	"prod.text.dynamic.game.24831.description": never;
	"prod.text.dynamic.game.24096.description": never;
	"prod.text.dynamic.game.24483.description": never;
	"prod.text.dynamic.game.24513.description": never;
	"prod.text.dynamic.game.23485.description": never;
	"prod.text.dynamic.game.23146.description": never;
	"prod.text.dynamic.game.21067.description": never;
	"prod.text.dynamic.game.22792.description": never;
	"prod.text.dynamic.game.18988.description": never;
	"prod.text.dynamic.game.18989.description": never;
	"prod.text.dynamic.game.18990.description": never;
	"prod.text.dynamic.game.18991.description": never;
	"prod.text.dynamic.game.18993.description": never;
	"prod.text.dynamic.game.18994.description": never;
	"prod.text.dynamic.game.19301.description": never;
	"prod.text.dynamic.game.19321.description": never;
	"prod.text.dynamic.player.54553.notes": never;
	"prod.text.dynamic.player.38459.notes": never;
	"prod.text.dynamic.player.34363.notes": never;
	"prod.text.dynamic.player.21035.notes": never;
	"prod.text.dynamic.player.50512.notes": never;
	"video.not.found": never;
	"prod.text.dynamic.player.47016.notes": never;
	"prod.text.dynamic.player.47027.notes": never;
	"prod.text.dynamic.game.27462.description": never;
	"prod.text.dynamic.player.29567.notes": never;
	"prod.text.dynamic.player.37951.notes": never;
	"prod.text.dynamic.game.27943.description": never;
	"prod.text.dynamic.game.27944.description": never;
	"prod.text.dynamic.game.27942.description": never;
	"prod.text.dynamic.game.27667.description": never;
	"prod.text.dynamic.game.27895.description": never;
	"tag.Market Watch": never;
	"tag.Club Focus": never;
	"tag.Croatia": never;
	"btn.load_more": never;
	"prod.text.dynamic.player.32439.notes": never;
	"prod.text.dynamic.player.31344.notes": never;
	"team.coach.unassign": never;
	"prod.text.dynamic.game.28088.description": never;
	"prod.text.dynamic.game.27977.description": never;
	"prod.text.dynamic.game.27801.description": never;
	"no.coaches.selected": never;
	"team.coaches.updated": never;
	"invalid.data": never;
	"feature.not.enabled": never;
	"app.player.alreadyRegistered": never;
	"app.player.forbidden": never;
	"tag.Europe": never;
	"tag.Drin Gashi": never;
	"tag.Emilio Iglesias": never;
	"tag.Kieran Batchelor": never;
	"tag.Nael Blanchard": never;
	"tag.Trey Tak": never;
	"prod.text.dynamic.player.29223.notes": never;
	"staging.text.dynamic.game.23435.description": never;
	"staging.text.dynamic.clubNonPartner.8429.description": never;
	"moved.to.old.players": never;
	"prod.text.dynamic.game.28033.description": never;
	"app.eyeball.analyzed_game.not_yet": never;
	"prod.text.dynamic.player.15082.notes": never;
	"prod.text.dynamic.player.23088.notes": never;
	"prod.text.dynamic.player.41751.notes": never;
	"prod.text.dynamic.game.28345.description": never;
	"prod.text.dynamic.game.28504.description": never;
	"prod.text.dynamic.club.5487.biography": never;
	"prod.text.dynamic.player.3145.notes": never;
	"label.login.continue": never;
	"app.player.player_card.improved_stats.quote": never;
	"app.eyeball.filter.manual": never;
	"app.eyeball.filter.manual.info": never;
	"app.eyeball.filter.disables_during_manual.info": never;
	"app.eyeball.filter.clip": never;
	"app.eyeball.filter.clip.info": never;
	"app.eyeball.filter.title.filters": never;
	"app.eyeball.filter.title.favorite": never;
	"app.eyeball.sort.title": never;
	"app.eyeball.sort.type.title": never;
	"app.eyeball.sort.type.date": never;
	"app.eyeball.sort.type.type": never;
	"app.eyeball.sort.type.opponent": never;
	"app.player.my_games": never;
	"revokeSessions.success": never;
	"revokeSessions.fail": never;
	"revokeSessions.confirmation": never;
	"prod.text.dynamic.player.20327.notes": never;
	"prod.text.dynamic.player.26996.notes": never;
	"prod.text.dynamic.player.17825.notes": never;
	"prod.text.dynamic.player.20652.notes": never;
	"prod.text.dynamic.player.15578.notes": never;
	"prod.text.dynamic.game.29437.description": never;
	"prod.text.dynamic.game.29345.description": never;
	"prod.text.dynamic.player.24777.notes": never;
	"prod.text.dynamic.player.56360.notes": never;
	"prod.text.dynamic.player.56361.notes": never;
	"prod.text.dynamic.player.56362.notes": never;
	"prod.text.dynamic.player.56363.notes": never;
	"prod.text.dynamic.player.56364.notes": never;
	"prod.text.dynamic.player.56365.notes": never;
	"prod.text.dynamic.player.56366.notes": never;
	"prod.text.dynamic.player.56367.notes": never;
	"prod.text.dynamic.player.56368.notes": never;
	"prod.text.dynamic.player.56369.notes": never;
	"prod.text.dynamic.player.56370.notes": never;
	"prod.text.dynamic.player.56371.notes": never;
	"prod.text.dynamic.player.56372.notes": never;
	"prod.text.dynamic.player.56373.notes": never;
	"prod.text.dynamic.player.56374.notes": never;
	"prod.text.dynamic.player.56375.notes": never;
	"prod.text.dynamic.player.56376.notes": never;
	"prod.text.dynamic.player.56377.notes": never;
	"prod.text.dynamic.player.57761.notes": never;
	"prod.text.dynamic.game.29302.description": never;
	"prod.text.dynamic.game.28938.description": never;
	"prod.text.dynamic.game.28676.description": never;
	"prod.text.dynamic.game.28503.description": never;
	"prod.text.dynamic.game.28168.description": never;
	"prod.text.dynamic.game.27787.description": never;
	"prod.text.dynamic.game.27185.description": never;
	"prod.text.dynamic.game.28782.description": never;
	"prod.text.dynamic.game.29520.description": never;
	"prod.text.dynamic.game.29218.description": never;
	"prod.text.dynamic.game.28714.description": never;
	"prod.text.dynamic.game.28597.description": never;
	"prod.text.dynamic.club.5494.biography": never;
	"error.invalid_lineup": never;
	"prod.text.dynamic.player.20672.notes": never;
	"subscription.upgrade.download_option": never;
	"prod.text.dynamic.club.5496.biography": never;
	"testing.text.dynamic.player.1176.notes": never;
	"prod.text.dynamic.game.29897.description": never;
	"prod.text.dynamic.game.29898.description": never;
	"prod.text.dynamic.game.29955.description": never;
	"prod.text.dynamic.game.29927.description": never;
	"prod.text.dynamic.game.29929.description": never;
	"prod.text.dynamic.game.29951.description": never;
	"prod.text.dynamic.game.29912.description": never;
	"prod.text.dynamic.game.26236.description": never;
	"prod.text.dynamic.player.817.notes": never;
	"header.player_stats": never;
	"permission.email_edit": never;
	"stats.view.coach.cm.info.panel": never;
	"label.show_table_results": {
		numOfResults: string;
	};
	"prod.text.dynamic.player.3897.notes": never;
	"staging.text.dynamic.player.47051.notes": never;
	"prod.text.dynamic.player.41501.notes": never;
	"prod.text.dynamic.player.41486.notes": never;
	"prod.text.dynamic.player.41466.notes": never;
	"prod.text.dynamic.game.30207.description": never;
	"prod.text.dynamic.game.30112.description": never;
	"prod.text.dynamic.game.30000.description": never;
	"prod.text.dynamic.game.29821.description": never;
	"prod.text.dynamic.game.29823.description": never;
	"prod.text.dynamic.player.2876.notes": never;
	"prod.text.dynamic.player.58454.notes": never;
	"prod.text.dynamic.player.58387.notes": never;
	"prod.text.dynamic.game.30284.description": never;
	"prod.text.dynamic.game.30091.description": never;
	"prod.text.dynamic.game.30071.description": never;
	"prod.text.dynamic.club.5499.biography": never;
	"staging.text.dynamic.club.5174.biography": never;
	"staging.text.dynamic.club.5368.biography": never;
	"staging.text.dynamic.clubNonPartner.8431.description": never;
	"notification.push.title.game.added": never;
	"notification.push.title.game.analyzed": never;
	"prod.text.dynamic.game.29822.description": never;
	"prod.text.dynamic.game.27896.description": never;
	"prod.text.dynamic.player.1307.notes": never;
	"prod.text.dynamic.player.33887.notes": never;
	"testing.text.dynamic.clubNonPartner.248.description": never;
	"prod.text.dynamic.game.30512.description": never;
	"prod.text.dynamic.club.5501.biography": never;
	"testing.text.dynamic.clubNonPartner.6222.description": never;
	"prod.text.dynamic.game.30577.description": never;
	"prod.text.dynamic.game.30576.description": never;
	"prod.text.dynamic.game.30575.description": never;
	"tag.Germany": never;
	"tag.Lithuania": never;
	"tag. Sultan Adeleke": never;
	"tag.Nigeria": never;
	"tag.Senegal": never;
	"tag.Performers of the Week": never;
	"tag.Wales": never;
	"staging.text.dynamic.clubNonPartner.8430.description": never;
	"prod.text.dynamic.game.30609.description": never;
	"prod.text.dynamic.player.15259.notes": never;
	"prod.text.dynamic.player.3176.notes": never;
	"prod.text.dynamic.player.12646.notes": never;
	"prod.text.dynamic.player.29508.notes": never;
	"prod.text.dynamic.player.17618.notes": never;
	"prod.text.dynamic.player.13353.notes": never;
	"staging.text.dynamic.player.47057.notes": never;
	"prod.text.dynamic.game.30650.description": never;
	"prod.text.dynamic.player.10158.notes": never;
	"prod.text.dynamic.player.15506.notes": never;
	"prod.text.dynamic.player.36479.notes": never;
	"prod.text.dynamic.club.5504.biography": never;
	"prod.text.dynamic.game.30677.description": never;
	"prod.text.dynamic.game.30667.description": never;
	"prod.text.dynamic.player.56872.notes": never;
	"prod.text.dynamic.player.39719.notes": never;
	"prod.text.dynamic.player.39426.notes": never;
	"prod.text.dynamic.player.37901.notes": never;
	"prod.text.dynamic.game.30741.description": never;
	"prod.text.dynamic.game.30716.description": never;
	"prod.text.dynamic.player.1707.notes": never;
	"prod.text.dynamic.game.30712.description": never;
	"prod.text.dynamic.game.30760.description": never;
	"prod.text.dynamic.game.30785.description": never;
	"prod.text.dynamic.player.31345.notes": never;
	"prod.text.dynamic.game.30786.description": never;
	"prod.text.dynamic.game.30731.description": never;
	"prod.text.dynamic.game.30734.description": never;
	"prod.text.dynamic.game.30735.description": never;
	"prod.text.dynamic.game.30736.description": never;
	"prod.text.dynamic.player.34123.notes": never;
	"prod.text.dynamic.player.1938.notes": never;
	"prod.text.dynamic.player.2998.notes": never;
	"prod.text.dynamic.player.27829.notes": never;
	"prod.text.dynamic.player.147.notes": never;
	"prod.text.dynamic.player.47552.notes": never;
	"prod.text.dynamic.player.2904.notes": never;
	"header.admin.video_test": never;
	"label.two_factor": never;
	"message.authentication_method.success": never;
	"option.authentication.app": never;
	"player.info.last.updated": never;
	"prod.text.dynamic.game.30829.description": never;
	"prod.text.dynamic.game.30827.description": never;
	"mail.user.verify.your.identity": never;
	"cannot.edit.highlight": never;
	"sendGame.confirmation.description": never;
	"sendGame.confirmation.yes": never;
	"sendGame.confirmation.no": never;
	"sendGame.confirmation.are_you_sure": never;
	"permission.game_analyze.no.changes.detected": never;
	"selfMfa.email.sendCode": never;
	"filter.ageCategory.tooltip": never;
	"selfMfa.totp.description": never;
	"prod.text.dynamic.player.31667.notes": never;
	"prod.text.dynamic.player.31650.notes": never;
	"button.activate": never;
	"label.mfa.verified": never;
	"button.deactivate": never;
	"prod.text.dynamic.game.30894.description": never;
	"prod.text.dynamic.game.30895.description": never;
	"prod.text.dynamic.game.30896.description": never;
	"prod.text.dynamic.game.30897.description": never;
	"prod.text.dynamic.game.30898.description": never;
	"prod.text.dynamic.game.30899.description": never;
	"prod.text.dynamic.game.30900.description": never;
	"selfMfa.code.enterCode": never;
	"prod.text.dynamic.game.30902.description": never;
	"prod.text.dynamic.game.30901.description": never;
	"staging.text.dynamic.club.1.biography": never;
	"prod.text.dynamic.player.29421.notes": never;
	"prod.text.dynamic.game.30907.description": never;
	"prod.text.dynamic.game.30905.description": never;
	"prod.text.dynamic.game.30825.description": never;
	"prod.text.dynamic.game.30821.description": never;
	"prod.text.dynamic.game.30818.description": never;
	"prod.text.dynamic.game.30824.description": never;
	"prod.text.dynamic.game.29952.description": never;
	"prod.text.dynamic.game.29953.description": never;
	"prod.text.dynamic.game.29942.description": never;
	"prod.text.dynamic.game.26235.description": never;
	"prod.text.dynamic.game.30819.description": never;
	"prod.text.dynamic.player.14144.notes": never;
	"prod.text.dynamic.player.12593.notes": never;
	"prod.text.dynamic.player.42058.notes": never;
	"prod.text.dynamic.player.11408.notes": never;
	"prod.text.dynamic.game.30949.description": never;
	"prod.text.dynamic.game.31025.description": never;
	"prod.text.dynamic.game.30948.description": never;
	"prod.text.dynamic.game.30947.description": never;
	"prod.text.dynamic.game.31037.description": never;
	"prod.text.dynamic.game.31038.description": never;
	"prod.text.dynamic.game.31039.description": never;
	"prod.text.dynamic.game.31040.description": never;
	"prod.text.dynamic.game.31041.description": never;
	"game.stats.table.totalGoals": never;
	"game.stats.table.totalAssists": never;
	"prod.text.dynamic.player.39694.notes": never;
	"prod.text.dynamic.player.41566.notes": never;
	"prod.text.dynamic.player.38980.notes": never;
	"prod.text.dynamic.club.5515.biography": never;
	"prod.text.dynamic.player.57122.notes": never;
	"prod.text.dynamic.player.56828.notes": never;
	"prod.text.dynamic.player.56833.notes": never;
	"label.uploaded_games": never;
	"label.analysis_games": never;
	"label.most_improved": never;
	"label.weekly_summary": never;
	"label.send_periodic_notification": never;
	"resource.email_notifications": never;
	"notification_frequency.daily": never;
	"notification_frequency.weekly": never;
	"label.players": never;
	"prod.text.dynamic.player.41201.notes": never;
	"prod.text.dynamic.player.18436.notes": never;
	"prod.text.dynamic.player.37944.notes": never;
	"prod.text.dynamic.player.14864.notes": never;
	"label.notification.frequency": never;
	"label.notification.type": never;
	"label.selection": never;
	"prod.text.dynamic.player.41064.notes": never;
	"prod.text.dynamic.game.31217.description": never;
	"prod.text.dynamic.game.31231.description": never;
	"prod.text.dynamic.game.31243.description": never;
	"prod.text.dynamic.game.31241.description": never;
	"prod.text.dynamic.game.31242.description": never;
	"prod.text.dynamic.game.31218.description": never;
	"prod.text.dynamic.game.31219.description": never;
	"prod.text.dynamic.game.31229.description": never;
	"prod.text.dynamic.club.5518.biography": never;
	"prod.text.dynamic.game.31245.description": never;
	"prod.text.dynamic.player.37949.notes": never;
	"prod.text.dynamic.game.31255.description": never;
	"prod.text.dynamic.game.31258.description": never;
	"prod.text.dynamic.game.31256.description": never;
	"table.header.statApproved": never;
	"prod.text.dynamic.game.31270.description": never;
	"prod.text.dynamic.game.31267.description": never;
	"prod.text.dynamic.game.31286.description": never;
	"prod.text.dynamic.game.31288.description": never;
	"prod.text.dynamic.game.31287.description": never;
	"staging.text.dynamic.player.47053.notes": never;
	"label.active": never;
	"label.expand": never;
	"prod.text.dynamic.game.31332.description": never;
	"prod.text.dynamic.game.31356.description": never;
	"prod.text.dynamic.game.31360.description": never;
	"prod.text.dynamic.player.17882.notes": never;
	"enum.notificationtypes.new_games.key": never;
	"enum.notificationtypes.analyzed_games.key": never;
	"msg.email_notification.success": never;
	"label.switch.on": never;
	"label.switch.off": never;
	"label.notification.category": never;
	"label.notification.selected": never;
	"msg.email_notification.failed": never;
	"prod.text.dynamic.player.17469.notes": never;
	"prod.text.dynamic.player.17467.notes": never;
	"prod.text.dynamic.player.17478.notes": never;
	"prod.text.dynamic.player.10713.notes": never;
	"label.teams": never;
	"label.clubs": never;
	"prod.text.dynamic.player.3217.notes": never;
	"prod.text.dynamic.player.2510.notes": never;
	"testing.text.dynamic.game.28127.description": never;
	"prod.text.dynamic.player.17900.notes": never;
	"testing.text.dynamic.clubNonPartner.6528.description": never;
	"new.games.available": never;
	"recent.games.uploaded": never;
	"game.analysed": never;
	"watch.newest.games.favorites": never;
	"recent.games.uploaded.msg": never;
	"game.analysed.msg": never;
	"contact.email": never;
	"prod.text.dynamic.game.31397.description": never;
	"message.metadata_approval.success": never;
	"label.videos.needApproval": never;
	"label.videos.approved": never;
	"label.sportlogiq": never;
	"prod.text.dynamic.player.58164.notes": never;
	"prod.text.dynamic.player.18548.notes": never;
	"prod.text.dynamic.player.22641.notes": never;
	"prod.text.dynamic.player.22644.notes": never;
	"prod.text.dynamic.player.22646.notes": never;
	"prod.text.dynamic.player.22910.notes": never;
	"prod.text.dynamic.club.5527.biography": never;
	"prod.text.dynamic.player.29462.notes": never;
	"prod.text.dynamic.club.5529.biography": never;
	"staging.text.dynamic.clubNonPartner.16050.description": never;
	"staging.text.dynamic.clubNonPartner.16052.description": never;
	"prod.text.dynamic.player.34784.notes": never;
	"global.locale.it": never;
	"app.eyeball.lang.it": never;
	"prod.text.dynamic.game.31976.description": never;
	"prod.text.dynamic.player.55477.notes": never;
	"prod.text.dynamic.player.29835.notes": never;
	"prod.text.dynamic.player.20629.notes": never;
	"prod.text.dynamic.player.19848.notes": never;
	"prod.text.dynamic.player.27499.notes": never;
	"prod.text.dynamic.player.34273.notes": never;
	"prod.text.dynamic.player.42416.notes": never;
	"prod.text.dynamic.player.20152.notes": never;
	"prod.text.dynamic.player.37953.notes": never;
	"prod.text.dynamic.player.37948.notes": never;
	"prod.text.dynamic.player.10624.notes": never;
	"prod.text.dynamic.player.1848.notes": never;
	"prod.text.dynamic.game.31731.description": never;
	"prod.text.dynamic.player.27173.notes": never;
	"prod.text.dynamic.player.55217.notes": never;
	"prod.text.dynamic.player.21779.notes": never;
	"prod.text.dynamic.club.22634.biography": never;
	"app.mail.guardian.content": {
		Vorname: string;
		Nachname: string;
	};
	"app.mail.guardian.btn": never;
	"prod.text.dynamic.club.22640.biography": never;
	"prod.text.dynamic.club.22641.biography": never;
	"prod.text.dynamic.club.22642.biography": never;
	"prod.text.dynamic.club.22643.biography": never;
	"prod.text.dynamic.club.22644.biography": never;
	"prod.text.dynamic.club.22645.biography": never;
	"prod.text.dynamic.club.22646.biography": never;
	"prod.text.dynamic.club.22647.biography": never;
	"prod.text.dynamic.club.22648.biography": never;
	"prod.text.dynamic.club.22649.biography": never;
	"notification.email.subject.guardian.approval.requested": never;
	"notification.push.title.guardian.approval.approved": never;
	"notification.push.body.guardian.approval.approved": never;
	"prod.text.dynamic.player.60952.notes": never;
	"app.player.home.na": never;
	"app.player.home.performance.header": never;
	"app.player.home.performance.openLastGame": never;
	"app.player.home.performance.top.performance": never;
	"app.player.home.performance.top.topCountry": never;
	"app.player.home.performance.top.topAge": never;
	"app.player.home.performance.top.topPosition": never;
	"app.player.home.performance.soon.header": never;
	"app.player.home.performance.soon.title": never;
	"app.player.home.performance.soon.body": never;
	"app.player.home.performance.last.title": never;
	"app.player.home.stats.header": never;
	"app.player.home.stats.header.info": never;
	"app.player.home.stats.improved.title": never;
	"app.player.home.stats.decreased.title": never;
	"app.player.home.stats.na.title": never;
	"app.player.home.stats.replay.title": never;
	"app.user.player_already_mapped": never;
	"app.player.find_player.select_club.title": never;
	"app.player.demo_content.title": never;
	"app.player.guardian_pending.small.title": never;
	"app.player.guardian_pending.small.action": never;
	"app.player.guardian_pending.big.title": never;
	"app.player.guardian_pending.big.message": never;
	"app.player.guardian_pending.big.action": never;
	"app.player.map_player.small.title": never;
	"app.player.map_player.small.action": never;
	"app.player.map_player.big.title": never;
	"app.player.map_player.big.message": never;
	"app.player.map_player.big.action": never;
	"app.player.register.info.subtitle": never;
	"app.player.guardian_request.title": never;
	"app.player.guardian_request.message": never;
	"app.player.home.mandatory_info.title": never;
	"app.player.home.mandatory_info.subtitle": never;
	"app.player.home.mandatory_info.image_info": never;
	"prod.text.dynamic.game.32318.description": never;
	"game.stats.table.passesSuccessfull": never;
	"app.favoriteGame.added": never;
	"app.favoriteGame.notFound": never;
	"app.favoriteGame.removed": never;
	"app.favoriteGameClip.added": never;
	"app.favoriteGameClip.notFound": never;
	"app.favoriteGameClip.removed": never;
	"app.favoriteGameEvent.added": never;
	"app.favoriteGameEvent.notFound": never;
	"app.favoriteGameEvent.removed": never;
	"app.guardian_approval.approved": never;
	"app.guardian_approval.success": never;
	"app.guardian_approval.validate.approval_not_found": never;
	"app.guardian_approval.validate.missing_env_var": never;
	"app.guardian_approval.validate.player_not_found": never;
	"app.guardian_approval.validate.user_not_found": never;
	"app.player.my_clips": never;
	"app.player.registered": never;
	"app.player.validate.birthdate_unix": never;
	"app.player.validate.clubName_string": never;
	"app.player.validate.countryOfResidence": never;
	"app.player.validate.countryOfResidence_string": never;
	"app.player.validate.email_required": never;
	"app.player.validate.guardian_approval.approval_id": never;
	"app.player.validate.guardian_approval.approval_id_strings": never;
	"app.player.validate.guardian_approval.email": never;
	"app.player.validate.guardian_approval.email_string": never;
	"app.player.validate.guardian_approval.first_name": never;
	"app.player.validate.guardian_approval.first_name_string": never;
	"app.player.validate.guardian_approval.last_name": never;
	"app.player.validate.guardian_approval.last_name_string": never;
	"app.player.validate.guardian_approval.player_id_int": never;
	"app.player.validate.guardian_approval.user": never;
	"app.player.validate.guardian_approval.user_int": never;
	"app.player.validate.isFavorite": never;
	"app.player.validate.middleName": never;
	"app.player.validate.middleName_required": never;
	"app.player.validate.middleName_string": never;
	"app.player.validate.player.sign_up_request.created": never;
	"app.player.validate.player.sign_up_request.otp_not_verified": never;
	"app.player.validate.player.sign_up_request.terms_not_accepted": never;
	"app.player.validate.termsAndConditions_bool": never;
	"app.validate.favorite_game_clip.blank": never;
	"app.validate.favorite_game_clip.type": never;
	"app.validate.favorite_game_event.blank": never;
	"app.validate.favorite_game_event.type": never;
	"app.validate.favorite_game.blank": never;
	"app.validate.favorite_game.type": never;
	"notification.email.subject.game.added": never;
	"notification.email.subject.game.analyzed": never;
	"notification.push.body.game.added": never;
	"notification.push.body.game.analyzed": never;
	"homepage.section.playersOfTheWeek": never;
	"game.stats.table.takeonsSuccess": never;
	"prod.text.dynamic.player.246984.notes": never;
	"prod.text.dynamic.player.236329.notes": never;
	"prod.text.dynamic.player.238852.notes": never;
	"prod.text.dynamic.player.319333.notes": never;
	"prod.text.dynamic.player.319334.notes": never;
	"prod.text.dynamic.player.26460.notes": never;
	"prod.text.dynamic.player.31405.notes": never;
	"prod.text.dynamic.player.31425.notes": never;
	"prod.text.dynamic.player.248283.notes": never;
	"prod.text.dynamic.player.319335.notes": never;
	"prod.text.dynamic.player.249286.notes": never;
	"prod.text.dynamic.player.236071.notes": never;
	"prod.text.dynamic.player.319336.notes": never;
	"prod.text.dynamic.player.239638.notes": never;
	"prod.text.dynamic.player.246986.notes": never;
	"prod.text.dynamic.player.319350.notes": never;
	"prod.text.dynamic.player.240278.notes": never;
	"prod.text.dynamic.player.306567.notes": never;
	"prod.text.dynamic.player.260002.notes": never;
	"prod.text.dynamic.player.261512.notes": never;
	"prod.text.dynamic.player.319351.notes": never;
	"prod.text.dynamic.player.319352.notes": never;
	"prod.text.dynamic.player.319353.notes": never;
	"prod.text.dynamic.club.22686.biography": never;
	"prod.text.dynamic.player.41969.notes": never;
	"prod.text.dynamic.game.32349.description": never;
	"prod.text.dynamic.game.32543.description": never;
	"prod.text.dynamic.club.22705.biography": never;
	"prod.text.dynamic.player.217046.notes": never;
	"prod.text.dynamic.player.27755.notes": never;
	"label.rank": never;
	"label.stat": never;
	"label.week": never;
	"label.button.reset": never;
	"game.stats.table.take-ons": never;
	"game.stats.table.aerial-duels": never;
	"game.stats.table.passes-forward": never;
	"prod.text.dynamic.player.36256.notes": never;
	"prod.text.dynamic.player.24768.notes": never;
	"prod.text.dynamic.player.9461.notes": never;
	"prod.text.dynamic.club.22768.biography": never;
	"prod.text.dynamic.player.49754.notes": never;
	"prod.text.dynamic.player.46924.notes": never;
	"prod.text.dynamic.club.22775.biography": never;
	"prod.text.dynamic.club.22778.biography": never;
	"prod.text.dynamic.player.9143.notes": never;
	"gameAnalysis.rejected.failed": never;
	"playersOfTheWeek.country.message": never;
	"staging.text.dynamic.player.319141.notes": never;
	"prod.text.dynamic.game.33322.description": never;
	"button.view_all_players": never;
	"game.leftTeam.label": never;
	"game.leftTeam.success": never;
	"prod.text.dynamic.club.22831.biography": never;
	"game.leftTeam.notSet": never;
	"prod.text.dynamic.player.16298.notes": never;
	"prod.text.dynamic.player.16779.notes": never;
	"staging.text.dynamic.game.33105.description": never;
	"game.noVideo.headline": never;
	"game.noVideo.message": never;
	"staging.text.dynamic.clubNonPartner.20077.description": never;
	"prod.text.dynamic.club.22858.biography": never;
	"prod.text.dynamic.player.34513.notes": never;
	"prod.text.dynamic.player.47093.notes": never;
	"prod.text.dynamic.player.47099.notes": never;
	"prod.text.dynamic.player.249985.notes": never;
	"prod.text.dynamic.player.272960.notes": never;
	"prod.text.dynamic.player.274214.notes": never;
	"prod.text.dynamic.player.322909.notes": never;
	"prod.text.dynamic.player.245234.notes": never;
	"prod.text.dynamic.player.322911.notes": never;
	"prod.text.dynamic.player.304515.notes": never;
	"staging.text.dynamic.club.22839.biography": never;
	"prod.text.dynamic.player.15621.notes": never;
	"playersOfTheWeek.empty_data.message": never;
	"prod.text.dynamic.player.14586.notes": never;
	"prod.text.dynamic.club.5451.biography": never;
	"product.management.game.uploads": never;
	"prod.text.dynamic.game.34040.description": never;
	"tag.Ivory Coast": never;
	"tag.Sultan Adeleke": never;
	"tag.Tim Reichelt": never;
	"tag.Ghana": never;
	"tag.Mali": never;
	"tag.Luxembourg": never;
	"tag.Poland": never;
	"tag.Scandinavia": never;
	"form_add_club_amateur.label.links": never;
	"form_add_club_amateur.label.link.add": never;
	"label.user.tran_link": never;
	"label.user.federation_link": never;
	"tag.Tournament": never;
	"mail.user.notification": never;
	"prod.text.dynamic.club.22770.biography": never;
	"prod.text.dynamic.club.22945.biography": never;
	"prod.text.dynamic.player.330404.notes": never;
	"prod.text.dynamic.club.22970.biography": never;
	"prod.text.dynamic.player.50719.notes": never;
	"prod.text.dynamic.player.50720.notes": never;
	"prod.text.dynamic.player.50721.notes": never;
	"prod.text.dynamic.player.50722.notes": never;
	"prod.text.dynamic.player.50723.notes": never;
	"prod.text.dynamic.player.50724.notes": never;
	"prod.text.dynamic.player.50726.notes": never;
	"prod.text.dynamic.player.50727.notes": never;
	"prod.text.dynamic.player.50728.notes": never;
	"prod.text.dynamic.player.50729.notes": never;
	"prod.text.dynamic.player.62140.notes": never;
	"prod.text.dynamic.player.62143.notes": never;
	"prod.text.dynamic.player.326112.notes": never;
	"prod.text.dynamic.player.330740.notes": never;
	"label.signup_requests": never;
	"prod.text.dynamic.club.22979.biography": never;
	"prod.text.dynamic.player.60920.notes": never;
	"prod.text.dynamic.player.236321.notes": never;
	"prod.text.dynamic.player.61008.notes": never;
	"prod.text.dynamic.player.26751.notes": never;
	"prod.text.dynamic.player.61089.notes": never;
	"prod.text.dynamic.player.61088.notes": never;
	"prod.text.dynamic.player.40013.notes": never;
	"prod.text.dynamic.player.325337.notes": never;
	"prod.text.dynamic.player.61011.notes": never;
	"prod.text.dynamic.player.236335.notes": never;
	"prod.text.dynamic.player.61097.notes": never;
	"prod.text.dynamic.player.236831.notes": never;
	"prod.text.dynamic.player.288310.notes": never;
	"prod.text.dynamic.player.26754.notes": never;
	"prod.text.dynamic.player.3767.notes": never;
	"label.signup_request_detail": never;
	"prod.text.dynamic.club.23020.biography": never;
	"label.signup_request": never;
	"label.player_details": never;
	"label.guardian_approval_requests": never;
	"staging.text.dynamic.clubNonPartner.20099.description": never;
	"prod.text.dynamic.club.23046.biography": never;
	"prod.text.dynamic.game.34017.description": never;
}

export type Key = keyof Keys;

export enum Locale {
	IT = "it",
	FR = "fr",
	ES = "es",
	EN = "en",
	DE = "de",
}
