import { useLocation } from "react-router-dom";
import { useStore } from "app/store";
import type { QueryClient } from "@tanstack/react-query";
import { useToast } from "ui-kit";
import Sidebar from "./Sidebar";
import { useEffect, useState } from "react";
import Section from "./Section";
import { setScrollBehavior } from "features/scrollPageBehaviorProvider/scrollPageBehaviorProvider";
import { useUserData } from "features/userData/context";
import type { ProductManagementOptions } from "./types";
import { MenuLink } from "types";

const query = () => ({
	queryKey: ["productManagement"],
	queryFn: async () => {},
});

export function loader(queryClient: QueryClient) {
	return async () => {
		const state = useStore.getState();

		const q = query();
		// ⬇️ return data or fetch it
		return queryClient.getQueryData(q.queryKey) ?? (await queryClient.fetchQuery(q));
	};
}

export default function ProductManagement() {
	const [activeMenu, setActiveMenu] = useState<string | null>(null);
	const { state} = useLocation();
	const { productManagementOptions, isSuperAdmin }: { productManagementOptions: ProductManagementOptions | null | undefined, isSuperAdmin:boolean } = useUserData();

	useEffect(() => {
		setScrollBehavior();
	}, []);

	useEffect(() => {
		if (state?.activeMenu) {
			setActiveMenu(state.activeMenu.toLowerCase());
		}else{
			if (productManagementOptions && Object.values(productManagementOptions).length) {
				if(isSuperAdmin){
					setActiveMenu(MenuLink.NON_PARTNER_CLUB);
					return;
				}
				const menu: ProductManagementOptions = Object.values(productManagementOptions)[0];
                const active = menu.hasSubMenu && Object.values(menu?.subMenuItems).length ? Object.values(menu?.subMenuItems)[0]?.id : menu?.id;
				setActiveMenu(active);
			}
		}
	}, [state])

	const onMenuClick = (id: string) => {
		setActiveMenu(id);
	};

	const { addSuccessToast } = useToast();

	return (
		<>
			<div className="product-management-container">
				<Sidebar onMenuClick={onMenuClick} activeMenu={activeMenu} />
				<Section activeMenu={activeMenu} onMenuClick={onMenuClick} />
			</div>
		</>
	);
}
